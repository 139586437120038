.list-section {
  -webkit-animation: unset;
          animation: unset;
}
.list-section > .wrapper {
  padding: 90px 0 180px;
  width: 1024px;
}
.list-section > .wrapper > .title {
  font-weight: 700;
  font-size: 26px;
}
.list-section > .wrapper > .filter-wrapper {
  height: 40px;
  z-index: 50;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  margin: 25px 0 53px;
}
.list-section > .wrapper > .list-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 320px);
  gap: 73px 32px;
}

@media screen and (max-width: 1365px) {
  .list-section > .wrapper {
    width: 727px;
  }
  .list-section > .wrapper > .list-wrapper {
    grid-template-columns: repeat(2, 348px);
  }
}
@media screen and (max-width: 767px) {
  .list-section > .wrapper {
    width: 100%;
    padding: 90px 20px 180px 20px;
  }
  .list-section > .wrapper > .filter-wrapper {
    position: unset;
    -webkit-column-gap: 14px;
       -moz-column-gap: 14px;
            column-gap: 14px;
  }
  .list-section > .wrapper > .list-wrapper {
    grid-template-columns: 100%;
    gap: 59px 0;
  }
}