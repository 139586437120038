#root footer {
  width: 100%;
  background-color: #535353;
  color: white;
}
#root footer > .wrapper {
  width: 1024px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  padding-top: 55px;
  padding-bottom: 55px;
}
#root footer > .wrapper > .top {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root footer > .wrapper > .top > .logo {
  width: 140px;
}
#root footer > .wrapper > .top > .nav-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 38px;
     -moz-column-gap: 38px;
          column-gap: 38px;
}
#root footer > .wrapper > .top > .nav-wrapper > a {
  white-space: nowrap;
  font-weight: 700;
  font-size: 13px;
  color: white;
}
#root footer > .wrapper > .content {
  color: white;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin: 20px 0;
}
#root footer > .wrapper > .content > .mb {
  display: none;
}
#root footer > .wrapper > .info-wrapper > .info-line {
  height: 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root footer > .wrapper > .info-wrapper > .info-line > img {
  width: 16px;
}
#root footer > .wrapper > .info-wrapper > .info-line > .content {
  color: white;
  font-weight: 400;
  font-size: 13px;
}
#root footer > .wrapper > .bottom {
  border-bottom: solid 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 25px;
  padding-bottom: 10px;
}
#root footer > .wrapper > .bottom > .sign {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
}
#root footer > .wrapper > .bottom > .sns {
  display: grid;
  grid-template-columns: repeat(4, 20px);
  -webkit-column-gap: 22px;
     -moz-column-gap: 22px;
          column-gap: 22px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root footer > .wrapper > .copy {
  margin-top: 15px;
  font-weight: 400;
  font-size: 13px;
  text-align: right;
}

@media screen and (max-width: 1365px) {
  #root footer > .wrapper {
    width: 727px;
  }
  #root footer > .wrapper > .top {
    row-gap: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
  }
  #root footer > .wrapper > .bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    row-gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  #root footer > .wrapper {
    width: 100%;
  }
  #root footer > .wrapper > .top > .nav-wrapper {
    -webkit-column-gap: 25px;
       -moz-column-gap: 25px;
            column-gap: 25px;
  }
  #root footer > .wrapper > .content > span {
    display: none;
  }
  #root footer > .wrapper > .content > .mb {
    display: block;
  }
  #root footer > .wrapper > .bottom > .sign > br {
    display: none;
  }
}