.policy {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 10px;
  cursor: pointer;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
.policy > .left {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
}
.policy > .left > .circle {
  width: 20px;
  height: 20px;
  border: solid 1px #DBDBDB;
  border-radius: 20px;
}
.policy > .left > .circle > img {
  display: none;
}
.policy > .left > .title {
  font-weight: 400;
  font-size: 12px;
  color: #545454;
}
.policy > a {
  font-weight: 400;
  font-size: 10px;
  color: #545454;
  text-decoration: underline;
}

.policy-active > .left > .circle {
  border: unset;
}
.policy-active > .left > .circle > img {
  display: block;
}
.policy-active > .left > .title > span {
  color: #0062FF;
}