.first-top {
  -webkit-transition: height 0.2s ease-in;
  transition: height 0.2s ease-in;
  width: 100vw;
  height: 200px;
  background-color: #F5F6F8;
}
.first-top > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  padding-top: 22px;
}
.first-top > .wrapper > .circle-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 7.8px;
     -moz-column-gap: 7.8px;
          column-gap: 7.8px;
}
.first-top > .wrapper > .circle-wrapper > .circle {
  background-color: #DBDBDB;
  width: 7px;
  height: 7px;
  border-radius: 7px;
}
.first-top > .wrapper > .circle-wrapper > .circle-on {
  background-color: #0062FF;
}
.first-top > .wrapper > .step {
  font-weight: bold;
  font-size: 15px;
  color: #0062FF;
  margin: 28px 0 8px 0;
}
.first-top > .wrapper > .title {
  font-weight: 700;
  font-size: 26px;
}
.first-top > .wrapper > .step2-drop-down {
  -webkit-transition: 0.2 ease-in;
  transition: 0.2 ease-in;
  margin-top: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  height: 15px;
}
.first-top > .wrapper > .step2-drop-down > .open-btn {
  cursor: pointer;
  background-color: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  font-size: 13px;
  color: #949494;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
.first-top > .wrapper > .step2-drop-down > .open-btn > img {
  width: 15px;
}
.first-top > .wrapper > .step2-drop-down > .box {
  width: 320px;
  height: 130px;
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 14px;
  text-align: left;
}
.first-top > .wrapper > .step2-drop-down > .box > .title {
  font-weight: 700;
  font-size: 13px;
}
.first-top > .wrapper > .step2-drop-down > .box > .title > span {
  color: #0062FF;
}
.first-top > .wrapper > .step2-drop-down > .box > .content {
  margin-top: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.first-top > .wrapper > .open {
  height: 175px;
  margin-top: 14px;
}
.first-top > .wrapper > .open > .open-btn {
  display: none;
}
.first-top > .wrapper > .open > .close-btn {
  background-color: unset;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  font-size: 13px;
  color: #949494;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
  margin-top: 21px;
}
.first-top > .wrapper > .open > .close-btn > img {
  width: 15px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.first-top > .wrapper > .content {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.top-long {
  height: 314px;
}