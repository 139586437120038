.fix-password {
  width: 100%;
  height: 100%;
  position: relative;
}
.fix-password > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.fix-password > .wrapper > .id-wrapper {
  width: 100%;
  height: 50px;
  background-color: #F5F6F8;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 15px;
  font-size: 16px;
  font-weight: bold;
}
.fix-password > .wrapper > .id-wrapper > .title {
  color: #949494;
}
.fix-password > .wrapper > .id-wrapper > .id {
  color: #0062FF;
}
.fix-password > .wrapper > .title {
  margin: 26px 0 37px 0;
  font-size: 26px;
  font-weight: bold;
}
.fix-password > .wrapper > .input-wrapper {
  display: grid;
  row-gap: 21px;
}
.fix-password > .wrapper > .btn-wrapper {
  margin-top: 36px;
  display: grid;
  row-gap: 11px;
}
.fix-password > .finish-fix {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 100%;
  top: 0;
  background-color: white;
  z-index: 10;
  -webkit-transition: left 0.2s ease-in;
  transition: left 0.2s ease-in;
}
.fix-password > .finish-fix > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 24px;
}
.fix-password > .finish-fix > .wrapper > .title {
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  text-align: center;
}
.fix-password > .finish-fix > .wrapper > img {
  width: 139px;
  height: 124px;
}
.fix-password > .on {
  left: 0;
}