#root .about-section2 {
  width: 100%;
  height: 1455px;
  background-color: #0062ff;
  padding-top: 132px;
}
#root .about-section2 > .wrapper {
  width: 696px;
}
#root .about-section2 > .wrapper > .title {
  text-align: center;
  color: white;
  font-size: 40px;
  font-weight: 700;
  line-height: 59px;
}
#root .about-section2 > .wrapper > .title > span {
  color: black;
  background-color: #b8ff6c;
}
#root .about-section2 > .wrapper > .list {
  color: white;
  margin-top: 48px;
}
#root .about-section2 > .wrapper > .list > .box {
  margin-bottom: 28px;
  padding: 0 29px;
  width: 696px;
  height: 168px;
  background-color: black;
  border-radius: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .about-section2 > .wrapper > .list > .box > .left {
  margin-right: 28px;
}
#root .about-section2 > .wrapper > .list > .box > .left > .img {
  background-color: rgba(83, 83, 83, 0.54);
  width: 103px;
  height: 103px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .about-section2 > .wrapper > .list > .box > .left > .img > img {
  width: 66px;
}
#root .about-section2 > .wrapper > .list > .box > .right > .title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}
#root .about-section2 > .wrapper > .list > .box > .right > .sub {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
}
#root .about-section2 > .wrapper > .list > .box > .right > .sub > .mb-br {
  display: none;
}

@media screen and (max-width: 1365px) {
  #root .about-section2 {
    height: 1356px;
    padding-top: 88px;
  }
  #root .about-section2 > .wrapper {
    width: 729px;
  }
  #root .about-section2 > .wrapper > .title {
    font-size: 30px;
    line-height: 39px;
  }
  #root .about-section2 > .wrapper > .list {
    margin-top: 28px;
  }
  #root .about-section2 > .wrapper > .list > .box {
    padding: 0 49px;
    width: 728px;
    height: 168px;
  }
}
@media screen and (max-width: 767px) {
  #root .about-section2 {
    height: 1856px;
    padding-top: 62px;
  }
  #root .about-section2 > .wrapper {
    width: 100%;
  }
  #root .about-section2 > .wrapper > .title {
    line-height: 34px;
    font-size: 23px;
  }
  #root .about-section2 > .wrapper > .list {
    margin-top: 28px;
    display: grid;
    grid-template-rows: 257px 301px 279px 279px 301px;
    row-gap: 19px;
  }
  #root .about-section2 > .wrapper > .list > .box {
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 30px 17px;
  }
  #root .about-section2 > .wrapper > .list > .box > .left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 0 auto;
  }
  #root .about-section2 > .wrapper > .list > .box > .left > .img {
    width: 90px;
    height: 90px;
    text-align: center;
    margin-bottom: 17px;
  }
  #root .about-section2 > .wrapper > .list > .box > .left > .img > img {
    width: 50px;
  }
  #root .about-section2 > .wrapper > .list > .box > .right > .title {
    font-size: 18px;
    margin-bottom: 8px;
    white-space: nowrap;
  }
  #root .about-section2 > .wrapper > .list > .box > .right > .sub {
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
  }
  #root .about-section2 > .wrapper > .list > .box > .right > .sub > .mb-br {
    display: block;
  }
  #root .about-section2 > .wrapper > .list > .box > .right > .sub > .pc-br {
    display: none;
  }
}