#root .profile-comp {
  padding-top: 58px;
  height: 100%;
}
#root .profile-comp > .fix-wrapper {
  height: 67px;
  width: 100%;
}
#root .profile-comp > .fix-wrapper > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .profile-comp > .fix-wrapper > .wrapper > .title {
  font-weight: 700;
  font-size: 26px;
}
#root .profile-comp > .profile-wrapper {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: black;
  padding-bottom: 40px;
}
#root .profile-comp > .profile-wrapper > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding-top: 23px;
}
#root .profile-comp > .profile-wrapper > .wrapper .my-teampl-btn {
  width: 100%;
  height: 58px;
  background-color: #0062FF;
  font-weight: 700;
  font-size: 20px;
  color: white;
  margin-top: 23px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
#root .profile-comp > .profile-wrapper > .wrapper .my-teampl-btn:hover {
  background-color: white;
  color: #0062FF;
}
#root .profile-comp > .profile-wrapper > .wrapper > .grade-name {
  font-weight: 700;
  font-size: 17px;
  color: #7BB1FF;
}
#root .profile-comp > .profile-wrapper > .wrapper > label {
  cursor: pointer;
  margin: 14px 0 8px 0;
  display: inline-block;
  width: 107px;
  height: 107px;
  position: relative;
}
#root .profile-comp > .profile-wrapper > .wrapper > label > input {
  position: absolute;
  left: 0;
}
#root .profile-comp > .profile-wrapper > .wrapper > label > .main-img {
  width: 107px;
  height: 107px;
  border-radius: 107px;
  overflow: hidden;
}
#root .profile-comp > .profile-wrapper > .wrapper > label > .camera {
  width: 37px;
  position: absolute;
  right: 0;
  bottom: 0;
}
#root .profile-comp > .profile-wrapper > .wrapper > .nickname {
  font-weight: 700;
  font-size: 25px;
  white-space: nowrap;
  color: white;
}
#root .profile-comp > .profile-wrapper > .wrapper > .email {
  font-weight: 500;
  font-size: 16px;
  color: #949494;
}
#root .profile-comp > .profile-wrapper > .wrapper > .grade-wrapper {
  margin-top: 17px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .profile-comp > .profile-wrapper > .wrapper > .grade-wrapper > .box {
  width: 156px;
  height: 78px;
  background-color: #FFFFFF;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 11px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 11px;
}
#root .profile-comp > .profile-wrapper > .wrapper > .grade-wrapper > .box > .title {
  font-weight: 700;
  font-size: 13px;
}
#root .profile-comp > .profile-wrapper > .wrapper > .grade-wrapper > .box > .number {
  font-weight: 700;
  font-size: 20px;
  color: #0062FF;
}
#root .profile-comp .leader-profile {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 26px;
  background-color: #0062FF;
}
#root .profile-comp .leader-profile .wrapper > .my-teampl-btn {
  background-color: black;
}
#root .profile-comp .leader-profile .wrapper > .grade-name {
  color: white;
}
#root .profile-comp .leader-profile .wrapper > label > .main-img {
  border: 3px solid #FFFFFF;
}
#root .profile-comp .leader-profile .wrapper > .email {
  color: #FFFFFF;
}
#root .profile-comp .profile-tab-section {
  background-color: white;
  width: 100%;
  height: 66px;
  padding-top: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: solid 1px #D9D9D9;
}
#root .profile-comp .profile-tab-section .profile-tab-wrapper {
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-column-gap: 25px;
     -moz-column-gap: 25px;
          column-gap: 25px;
}
#root .profile-comp .profile-tab-section .profile-tab-wrapper > .profile-tab {
  white-space: nowrap;
  width: 49px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  background-color: white;
}
#root .profile-comp .profile-tab-section .profile-tab-wrapper > .tab-active {
  font-weight: 700;
  color: #0062FF;
}
#root .profile-comp .profile-tab-section .profile-tab-wrapper .profile-tab-bar {
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
  left: 0;
  position: absolute;
  bottom: -2px;
  width: 49px;
  height: 3px;
  background-color: #0062FF;
}
#root .profile-comp > .info-wrapper > .basic-info {
  padding: 36px 0 84px;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper > .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 13px;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper > .title > .my-info-btn {
  width: 69px;
  height: 32px;
  background: #E9EFFF;
  border-radius: 5px;
  font-weight: 700;
  font-size: 13px;
  color: #0062FF;
  cursor: pointer;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper > .title > .my-info-btn:hover {
  opacity: 0.7;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper > .login-input .title {
  color: black;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper .email-input-wrapper > .tag-wrapper > .tag {
  color: black;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper .my-email-info {
  margin-bottom: 20px;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper > .area-wrapper {
  margin: 20px 0;
  width: 100%;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper > .area-wrapper > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper > .area-wrapper > .title-wrapper > .title {
  font-weight: 700;
  font-size: 13px;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper > .area-wrapper > .title-wrapper > .length {
  font-weight: 400;
  font-size: 13px;
  color: #0062FF;
}
#root .profile-comp > .info-wrapper > .basic-info > .wrapper > .area-wrapper > textarea {
  margin-top: 7px;
  width: 100%;
  height: 145px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 15px;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
}
#root .profile-comp > .info-wrapper > .other-info {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 28px 0 40px;
}
#root .profile-comp > .info-wrapper > .other-info > .wrapper > .area-wrapper {
  margin: unset;
}
#root .profile-comp > .info-wrapper > .other-info > .wrapper > .area-wrapper > .intro-text {
  margin-top: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
}
#root .profile-comp > .info-wrapper > .portfolio-wrapper {
  padding: 17px 0 63px;
}
#root .profile-comp > .info-wrapper > .portfolio-wrapper > .wrapper > .box {
  margin-top: 11px;
  width: 100%;
  height: 204px;
  background-color: #0062FF;
  border-radius: 5px;
  padding: 21px 14px 18px 14px;
  text-align: center;
  color: white;
}
#root .profile-comp > .info-wrapper > .portfolio-wrapper > .wrapper > .box > img {
  width: 49px;
}
#root .profile-comp > .info-wrapper > .portfolio-wrapper > .wrapper > .box > .title {
  font-weight: 700;
  font-size: 18px;
  margin: 8px 0;
}
#root .profile-comp > .info-wrapper > .portfolio-wrapper > .wrapper > .box > .link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;
}
#root .profile-comp > .info-wrapper > .portfolio-wrapper > .wrapper > .box > .firstinfo-btn {
  height: 44px;
  width: 100%;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 15px;
}
#root .profile-comp > .info-wrapper > .portfolio-wrapper > .wrapper > .box > .firstinfo-btn > img {
  width: 26.55px;
}
#root .profile-comp > .info-wrapper > .portfolio-wrapper > .wrapper > .box > .firstinfo-btn:active {
  background-color: rgb(49, 49, 49);
}
#root .profile-comp > .info-wrapper > .keyword-wrapper {
  padding: 17px 0 79px;
}
#root .profile-comp > .info-wrapper > .keyword-wrapper > .wrapper > .keyword-array {
  margin-top: 13px;
  margin-bottom: 66px;
  height: 275px;
  display: grid;
  row-gap: 10px;
  grid-template-rows: repeat(5, 47px);
  overflow: hidden;
}
#root .profile-comp > .info-wrapper > .keyword-wrapper > .wrapper > .keyword-array > .box {
  width: 100%;
  height: 47px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 15px 17px;
  border-radius: 23.5px;
  -webkit-animation: inside 0.2s ease-in;
          animation: inside 0.2s ease-in;
}
#root .profile-comp > .info-wrapper > .keyword-wrapper > .wrapper > .keyword-array > .box > img {
  cursor: pointer;
  width: 15px;
}
#root .profile-comp > .info-wrapper > .keyword-wrapper > .wrapper > .keyword-array > .box > .title {
  font-weight: 700;
  font-size: 14px;
  color: white;
}
#root .profile-comp > .info-wrapper > .keyword-wrapper > .wrapper > .keyword-array > .box:nth-child(1) {
  background-color: #0062FF;
}
#root .profile-comp > .info-wrapper > .keyword-wrapper > .wrapper > .keyword-array > .box:nth-child(2) {
  background-color: #7BB1FF;
}
#root .profile-comp > .info-wrapper > .keyword-wrapper > .wrapper > .keyword-array > .box:nth-child(3) {
  background-color: #8741EA;
}
#root .profile-comp > .info-wrapper > .keyword-wrapper > .wrapper > .keyword-array > .box:nth-child(4) {
  background-color: #5DC287;
}
#root .profile-comp > .info-wrapper > .keyword-wrapper > .wrapper > .keyword-array > .box:nth-child(5) {
  background-color: #BBB0E4;
}
#root .profile-comp > .info-wrapper > div > .wrapper > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .profile-comp > .info-wrapper > div > .wrapper > .title-wrapper > .title {
  font-weight: 700;
  font-size: 20px;
}
#root .profile-comp > .info-wrapper > div > .wrapper > .title-wrapper > button {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  font-size: 14px;
  color: #949494;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
  background-color: transparent;
}
#root .profile-comp > .info-wrapper > div > .wrapper > .title-wrapper > button:active {
  color: black;
}
#root .profile-comp > .info-wrapper > div > .wrapper > .title-wrapper > button:active > svg > path {
  stroke: black;
}
#root .profile-comp > .info-wrapper > .block {
  background-color: #F5F6F8;
  width: 100%;
  height: 10px;
}
#root .profile-comp .profile-project-section {
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
  padding: 28px 0;
}
#root .profile-comp .profile-project-section > .wrapper {
  display: grid;
  row-gap: 16px;
}
#root .profile-comp .profile-recommend-section {
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
  padding: 36px 0;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section {
  padding-bottom: 24px;
  border-bottom: solid 1px #D9D9D9;
  margin-bottom: 24px;
  width: 100%;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .title-wrapper > .title {
  font-weight: 700;
  font-size: 20px;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .title-wrapper > .length {
  font-weight: 400;
  font-size: 11px;
  color: #949494;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .recommend-input {
  width: 100%;
  height: 135px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 16px 11px;
  margin: 10px 0 12px;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .recommend-write-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .recommend-write-bottom .recommend-star-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 30px);
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .recommend-write-bottom .recommend-star-wrapper > .star-btn {
  background-color: unset;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .recommend-write-bottom .recommend-star-wrapper > .star-btn > svg > path {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .recommend-write-bottom .recommend-star-wrapper > .active-star > svg > path {
  fill: #7BB1FF;
  stroke: unset;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .recommend-write-bottom > .insert-btn {
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  width: 69px;
  height: 32px;
  background: #E9EFFF;
  border-radius: 5px;
  font-weight: 700;
  font-size: 13px;
  color: #0062FF;
}
#root .profile-comp .profile-recommend-section > .wrapper .write-recommend-section > .recommend-write-bottom > .insert-btn:hover {
  opacity: 0.7;
}
#root .profile-comp .profile-recommend-section > .wrapper .recommend-me-btn-wrapper {
  margin: 0 auto;
  margin-bottom: 27px;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 104px);
  -webkit-column-gap: 1px;
     -moz-column-gap: 1px;
          column-gap: 1px;
  padding: 1px;
  background-color: #DBDBDB;
}
#root .profile-comp .profile-recommend-section > .wrapper .recommend-me-btn-wrapper .me-change-btn {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
  background-color: white;
  font-weight: 700;
  font-size: 14px;
  color: #949494;
}
#root .profile-comp .profile-recommend-section > .wrapper .recommend-me-btn-wrapper > .color-btn {
  color: #0062FF;
  border: 1px solid #0062FF;
  background-color: #E9EFFF;
}
#root .profile-comp .profile-recommend-section > .wrapper .recommend-me-btn-wrapper .me-change-btn:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
#root .profile-comp .profile-recommend-section > .wrapper .recommend-me-btn-wrapper .me-change-btn:nth-child(2) {
  border-radius: 0 5px 5px 0;
}
#root .profile-comp .profile-recommend-section > .wrapper .recommend-title {
  font-weight: 700;
  font-size: 20px;
}
#root .profile-comp .profile-recommend-section > .wrapper .recommend-score-section {
  margin: 17px 0 23px;
  height: 49px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: solid 1px #D9D9D9;
  border-bottom: solid 1px #D9D9D9;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
}
#root .profile-comp .profile-recommend-section > .wrapper .recommend-score-section > .score-title {
  font-weight: 700;
  font-size: 16px;
  color: #696969;
}
#root .profile-comp .profile-recommend-section > .wrapper .recommend-score-section > .score-title > span {
  margin-left: 7px;
  color: #0062FF;
}
#root .profile-comp .profile-recommend-section > .wrapper .recommend-score-section > .score-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
}
#root .profile-comp .profile-recommend-section > .wrapper .profile-recommend-list {
  display: grid;
  row-gap: 14px;
}
#root .profile-comp .profile-support-section {
  padding: 37px 0;
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
}
#root .profile-comp .profile-support-section > .wrapper .support-section-title {
  font-weight: 700;
  font-size: 26px;
}
#root .profile-comp .profile-support-section > .wrapper .support-section-length {
  font-weight: 700;
  font-size: 15px;
  color: #0062FF;
  margin: 20px 0 10px;
}
#root .profile-comp .profile-support-section > .wrapper .support-section-list {
  display: grid;
  row-gap: 16px;
}
#root .profile-comp .profile-certification-section {
  padding: 28px 0;
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
}
#root .profile-comp .profile-certification-section > .wrapper .certification-list {
  display: grid;
  row-gap: 16px;
}
#root .profile-comp .profile-card-comp {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 113px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  overflow: hidden;
}
#root .profile-comp .profile-card-comp .profile-card-img-wrapper {
  width: 113px;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 14px;
}
#root .profile-comp .profile-card-comp .card-content-section .profile-card-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: 128px;
  height: 44px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .profile-comp .profile-card-comp .card-content-section .profile-card-time {
  font-weight: 700;
  font-size: 12px;
  color: #949494;
  margin-top: 25px;
}
#root .profile-comp .profile-card-comp .card-content-section > .download-link {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 72px;
  height: 28px;
  background: #F5F6F8;
  border-radius: 5px;
  font-weight: 700;
  font-size: 11px;
  color: #202020;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .profile-comp .profile-card-comp .card-content-section > .download-link:hover {
  opacity: 0.7;
}
#root .profile-comp .profile-card-comp > .grey-arrow {
  position: absolute;
  right: 16px;
  width: 15px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
#root .profile-comp .profile-card-project,
#root .profile-comp .profile-card-support {
  cursor: pointer;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
#root .profile-comp .profile-card-project:hover,
#root .profile-comp .profile-card-support:hover {
  opacity: 0.7;
}