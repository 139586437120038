.teamroom-section {
  padding: 77px 0;
  min-height: 100%;
}
.teamroom-section .teampl-nav-section {
  position: sticky;
  z-index: 10;
  background-color: white;
  top: 58px;
  border-bottom: 1px solid #D9D9D9;
}
.teamroom-section .teampl-nav-section > .nav-wrapper {
  padding-bottom: 13px;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  position: relative;
  grid-template-columns: repeat(4, 49px);
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.teamroom-section .teampl-nav-section > .nav-wrapper > a {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}
.teamroom-section .teampl-nav-section > .nav-wrapper > .active-nav {
  color: #0062FF;
  font-weight: 700;
}
.teamroom-section .teampl-nav-section > .nav-wrapper > .nav-line {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  position: absolute;
  width: 49px;
  height: 3px;
  background-color: #0062FF;
  bottom: 0;
}
.teamroom-section > .wrapper {
  width: 728px;
  height: 100%;
}
.teamroom-section > .wrapper .my-teampl-project-list {
  padding-bottom: 33px;
  width: 100%;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.teamroom-section > .wrapper .my-teampl-project-list .teampl-project-card {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  width: 141px;
  height: 110px;
  border-radius: 5px;
  border: 1px solid #DBDBDB;
  display: grid;
}
.teamroom-section > .wrapper .my-teampl-project-list .teampl-project-card > .back-img {
  width: 100%;
  height: 54px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.teamroom-section > .wrapper .my-teampl-project-list .teampl-project-card > .title-wrapper {
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  padding: 9px;
  height: 56px;
  width: 100%;
  text-align: left;
  background-color: white;
}
.teamroom-section > .wrapper .my-teampl-project-list > .active-card > .title-wrapper {
  background-color: #E9EFFF;
}
.teamroom-section > .wrapper .my-teampl-project-list::-webkit-scrollbar {
  display: none;
}
.teamroom-section > .wrapper .write-nav {
  -webkit-animation: opa 0.5s ease-in-out forwards;
          animation: opa 0.5s ease-in-out forwards;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  position: fixed;
  z-index: 50;
  right: 20px;
  width: 107px;
  height: 107px;
  background-color: #0062FF;
  border-radius: 77px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 6px;
}
.teamroom-section > .wrapper .write-nav > img {
  width: 18px;
}
.teamroom-section > .wrapper .write-nav > .title {
  font-weight: 700;
  font-size: 15px;
  color: white;
}
.teamroom-section > .wrapper .write-nav:hover {
  opacity: 0.7;
}
.teamroom-section > .wrapper .teampl-notice {
  min-height: 500px;
  position: relative;
}
.teamroom-section > .wrapper .teampl-notice .teampl-notice-wrapper {
  -webkit-animation: right-side 0.3s ease-in-out;
          animation: right-side 0.3s ease-in-out;
}
.teamroom-section > .wrapper .room-calendar {
  position: relative;
}
.teamroom-section > .wrapper > .member-section,
.teamroom-section > .wrapper .room-calendar {
  -webkit-animation: right-side 0.3s ease-in-out;
          animation: right-side 0.3s ease-in-out;
}
.teamroom-section > .wrapper > .room-list {
  -webkit-animation: right-side 0.3s ease-in-out;
          animation: right-side 0.3s ease-in-out;
}
.teamroom-section > .wrapper > .room-list > .search-wrapper {
  margin: 39px 0 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.teamroom-section > .wrapper > .room-list > .search-wrapper > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-column-gap: 6px;
     -moz-column-gap: 6px;
          column-gap: 6px;
}
.teamroom-section > .wrapper > .room-list > .search-wrapper > .left > .title {
  font-weight: 700;
  font-size: 24px;
}
.teamroom-section > .wrapper > .room-list > .search-wrapper > .left > .sub {
  font-weight: 700;
  font-size: 13px;
  color: #949494;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
.teamroom-section > .wrapper > .room-list > .search-wrapper > .right {
  width: 320px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 18px;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
.teamroom-section > .wrapper > .room-list > .search-wrapper > .right > input {
  width: 100%;
}
.teamroom-section > .wrapper > .room-list > .search-wrapper > .right > img {
  width: 20px;
}
.teamroom-section > .wrapper > .room-list > .list-wrapper {
  width: 100%;
  height: 100%;
  min-height: 500px;
  position: relative;
}
.teamroom-section > .wrapper > .room-list > .list-wrapper > .list {
  display: grid;
  row-gap: 10px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.teamroom-section > .wrapper > .room-list > .list-wrapper > .list::-webkit-scrollbar {
  display: none;
}
.teamroom-section > .wrapper .room-calendar {
  padding-top: 29px;
  padding-bottom: 200px;
}
.teamroom-section > .wrapper > .room-write {
  width: 100%;
  margin-top: 39px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.teamroom-section > .wrapper > .room-write .open-calendar {
  padding: 32px 0;
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
  position: fixed;
  bottom: 0;
  width: 728px;
  background-color: white;
}
.teamroom-section > .wrapper > .room-write .open-calendar .change-date-btn {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  width: 320px;
  height: 50px;
  background-color: #0062FF;
  color: white;
  font-weight: 700;
  font-size: 15px;
  margin-top: 6px;
}
.teamroom-section > .wrapper > .room-write .open-calendar .change-date-btn:hover {
  opacity: 0.7;
}
.teamroom-section > .wrapper > .room-write > .title {
  width: 100%;
  font-weight: 700;
  font-size: 24px;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper {
  width: 100%;
  margin-top: 30px;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper .select-calendar-btn {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  width: 100%;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 14px 0 17px;
  border-radius: 5px;
  background-color: #F5F6F8;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper .select-calendar-btn > .title {
  font-weight: 400;
  font-size: 15px;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper .select-calendar-btn > img {
  width: 17px;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper .select-calendar-btn:hover {
  opacity: 0.7;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 7px;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .link-wrapper {
  width: 100%;
  height: 50px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .link-wrapper > img {
  width: 26px;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .link-wrapper > input {
  margin-left: 6px;
  width: 100%;
  font-size: 15px;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .label-wrapper {
  width: 100%;
  height: 50px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .label-wrapper > .cancel {
  cursor: pointer;
  position: absolute;
  right: 12px;
  width: 28px;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .label-wrapper > label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 14px;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .label-wrapper > label > span {
  font-weight: 400;
  font-size: 15px;
  color: #949494;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .label-wrapper > label > img {
  width: 26px;
  z-index: 100;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .label-wrapper > label > input {
  display: none;
  width: 0;
  height: 0;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .full {
  background-color: #F5F6F8;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .full > label {
  width: 90%;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > .full > label > span {
  color: black;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > input {
  width: 100%;
  height: 50px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 16px;
  font-size: 15px;
}
.teamroom-section > .wrapper > .room-write > .input-wrapper > textarea {
  width: 100%;
  height: 271px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}
.teamroom-section > .wrapper > .room-write > .write-btn {
  cursor: pointer;
  width: 100%;
  height: 50px;
  background-color: #DBDBDB;
  color: white;
  font-weight: 700;
  font-size: 15px;
  margin-top: 46px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.teamroom-section > .wrapper > .room-write > .active-write {
  background-color: #0062FF;
}
.teamroom-section > .wrapper > .room-detail {
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
  padding: 35px 0;
}
.teamroom-section > .wrapper > .room-detail > .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 18px;
}
.teamroom-section > .wrapper > .room-detail > .title > .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.teamroom-section > .wrapper > .room-detail > .title > .btn-wrapper > button {
  width: 50px;
  height: 20px;
  background-color: unset;
  color: black;
  cursor: pointer;
}
.teamroom-section > .wrapper > .room-detail > .profile-wrapper {
  margin-bottom: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.teamroom-section > .wrapper > .room-detail > .profile-wrapper > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
.teamroom-section > .wrapper > .room-detail > .profile-wrapper > .left > .img-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #949494;
}
.teamroom-section > .wrapper > .room-detail > .profile-wrapper > .left > .nickname {
  font-weight: 500;
  font-size: 12px;
}
.teamroom-section > .wrapper > .room-detail > .profile-wrapper > .left > .nickname > span {
  color: #0062FF;
}
.teamroom-section > .wrapper > .room-detail > .profile-wrapper > .right {
  font-weight: 500;
  font-size: 12px;
  color: #949494;
}
.teamroom-section > .wrapper > .room-detail > .line {
  width: 100%;
  height: 1px;
  background-color: #D9D9D9;
}
.teamroom-section > .wrapper > .room-detail > .content {
  margin-top: 19px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  padding-bottom: 90px;
  white-space: pre-line;
}
.teamroom-section > .wrapper > .room-detail > .a-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 11px;
  margin-bottom: 70px;
}
.teamroom-section > .wrapper > .room-detail > .a-wrapper > a, .teamroom-section > .wrapper > .room-detail > .a-wrapper > button {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  width: 100%;
  height: 53px;
  background-color: #F5F6F8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 11px;
}
.teamroom-section > .wrapper > .room-detail > .a-wrapper > a > .left, .teamroom-section > .wrapper > .room-detail > .a-wrapper > button > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
.teamroom-section > .wrapper > .room-detail > .a-wrapper > a > .left > .svg-wrapper, .teamroom-section > .wrapper > .room-detail > .a-wrapper > button > .left > .svg-wrapper {
  padding: 4px;
  width: 31px;
  height: 31px;
  border-radius: 31px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.teamroom-section > .wrapper > .room-detail > .a-wrapper > a > .left > .title, .teamroom-section > .wrapper > .room-detail > .a-wrapper > button > .left > .title {
  font-weight: 500;
  font-size: 15px;
}
.teamroom-section > .wrapper > .room-detail > .a-wrapper > a > img, .teamroom-section > .wrapper > .room-detail > .a-wrapper > button > img {
  width: 15px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.teamroom-section > .wrapper > .room-detail > .a-wrapper > a:hover, .teamroom-section > .wrapper > .room-detail > .a-wrapper > button:hover {
  opacity: 0.7;
}
.teamroom-section > .wrapper > .room-detail > .a-wrapper > a:nth-child(2) > .left > .svg-wrapper {
  padding: 7px;
}
.teamroom-section > .wrapper > .room-detail > .bold-line {
  width: 100%;
  height: 9px;
  background-color: #F5F6F8;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper {
  margin: 20px 0 18px;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .title > .title-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 3px;
     -moz-column-gap: 3px;
          column-gap: 3px;
  font-weight: 700;
  font-size: 18px;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .title > .title-text > span {
  color: #0062FF;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .title > button {
  background-color: unset;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper {
  margin: 14px 0 18px;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > textarea {
  padding: 12px;
  height: 90px;
  width: 100%;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .link-wrapper {
  -webkit-animation: inside 0.2s ease-in;
          animation: inside 0.2s ease-in;
  margin-top: 11px;
  padding: 0 15px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 9px;
     -moz-column-gap: 9px;
          column-gap: 9px;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .link-wrapper > img {
  width: 20px;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .link-wrapper > input {
  width: 100%;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .file {
  background-color: #F5F6F8;
  position: relative;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .file > .title {
  font-weight: 500;
  font-size: 15px;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .file > .cancel {
  position: absolute;
  right: 17.5px;
  width: 25px;
  cursor: pointer;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .write-btn-wrapper {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .write-btn-wrapper > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .write-btn-wrapper > .left > button,
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .write-btn-wrapper > .left label {
  padding: 3px;
  cursor: pointer;
  width: 26px;
  background-color: transparent;
  -webkit-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .write-btn-wrapper > .left > button > input,
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .write-btn-wrapper > .left label > input {
  display: none;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .write-btn-wrapper > .left > button,
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .write-btn-wrapper > .left label:hover {
  opacity: 0.8;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .write-btn-wrapper > .right {
  cursor: pointer;
  width: 68px;
  height: 34px;
  background-color: #0062FF;
  border-radius: 5px;
  font-weight: 700;
  font-size: 13px;
  color: white;
  -webkit-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in;
}
.teamroom-section > .wrapper > .room-detail > .write-wrapper > .comment-write-wrapper > .write-btn-wrapper > .right:hover {
  opacity: 0.8;
}
.teamroom-section > .wrapper > .room-detail > .reply-write-wrapper {
  width: 728px;
  margin-top: 0;
  top: 57px;
  position: fixed;
  height: 100%;
  z-index: 500;
  background-color: white;
}
.teamroom-section > .wrapper > .room-detail > .reply-write-wrapper .comment-write-wrapper > textarea {
  height: 200px;
}
.teamroom-section > .wrapper > .room-detail > .comment-wrapper {
  margin-top: 27px;
  display: grid;
  row-gap: 11px;
}
.teamroom-section > .wrapper > .member-section > .wrapper > .title {
  margin: 40px 0 24px;
  font-weight: 700;
  font-size: 24px;
}
.teamroom-section > .wrapper > .member-section > .wrapper > .member-list {
  display: grid;
  row-gap: 10px;
}

@media screen and (max-width: 1365px) {
  .teamroom-section {
    position: relative;
  }
  .teamroom-section > .wrapper .write-nav {
    width: 77px;
    height: 77px;
  }
}
@media screen and (max-width: 767px) {
  .teamroom-section > .wrapper {
    width: 100%;
    padding: 0 20px;
  }
  .teamroom-section > .wrapper > .room-list > .search-wrapper {
    position: sticky;
    top: 50px;
    margin: unset;
    padding: 23px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    row-gap: 23px;
    z-index: 200;
    background-color: white;
  }
  .teamroom-section > .wrapper > .room-list > .search-wrapper > .right {
    width: 100%;
  }
  .teamroom-section > .wrapper > .room-list > .list-wrapper > .list {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .teamroom-section > .wrapper > .room-write > .write-btn {
    width: 100%;
  }
  .teamroom-section > .wrapper > .room-write .open-calendar {
    width: 100%;
    padding: 20px 20px;
  }
  .teamroom-section > .wrapper > .room-detail > .reply-write-wrapper {
    width: 100%;
  }
  .teamroom-section > .wrapper > .room-detail > .title, .teamroom-section > .wrapper > .room-detail > .profile-wrapper, .teamroom-section > .wrapper > .room-detail > .a-wrapper, .teamroom-section > .wrapper > .room-detail > .write-wrapper, .teamroom-section > .wrapper > .room-detail > .comment-wrapper {
    padding: 0 20px;
  }
  .teamroom-section > .wrapper > .room-detail > .content {
    padding: 0 20px 90px 20px;
  }
  .teamroom-section > .detail-wrapper {
    padding: unset;
  }
}