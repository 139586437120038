#root .about-main-section {
  background-color: #b8ff6c;
  width: 100%;
  height: unset;
}
#root .about-main-section > .wrapper-title {
  height: 605px;
  position: relative;
  width: 1024px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 133px;
}
#root .about-main-section > .wrapper-title > .left > .title {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 10px;
}
#root .about-main-section > .wrapper-title > .left > .sub {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 11px;
  line-height: 42px;
}
#root .about-main-section > .wrapper-title > .left > .text {
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 65px;
}
#root .about-main-section > .wrapper-title > .left > .button {
  padding-left: 12px;
  padding-right: 12px;
  background-color: black;
  width: 230px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: white;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}
#root .about-main-section > .wrapper-title > .left > .button > .title {
  margin-right: 48px;
}
#root .about-main-section > .wrapper-title > .right {
  position: absolute;
  bottom: -4px;
  right: 0;
}
#root .about-main-section > .wrapper-title > .right > img {
  width: 630px;
}

@media screen and (max-width: 1365px) {
  #root .about-main-section > .wrapper-title {
    width: 729px;
    height: 457px;
    padding-top: 81px;
  }
  #root .about-main-section > .wrapper-title > .left > .title {
    font-size: 15px;
    margin-bottom: 5px;
  }
  #root .about-main-section > .wrapper-title > .left > .sub {
    font-size: 30px;
    margin-bottom: 5px;
    line-height: 37px;
  }
  #root .about-main-section > .wrapper-title > .left > .button {
    padding-right: 13px;
    width: 156px;
    color: white;
  }
  #root .about-main-section > .wrapper-title > .left > .button > .title {
    margin-right: 22px;
  }
  #root .about-main-section > .wrapper-title > .left > .text {
    font-weight: 500;
    margin-bottom: 46px;
    line-height: 20px;
  }
  #root .about-main-section > .wrapper-title > .right > img {
    width: 471px;
  }
}
@media screen and (max-width: 767px) {
  #root .about-main-section {
    width: 100%;
    margin: 0 auto;
  }
  #root .about-main-section > .wrapper-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    text-align: center;
    padding-top: 108px;
    height: 727px;
  }
  #root .about-main-section > .wrapper-title > .left {
    padding: unset;
  }
  #root .about-main-section > .wrapper-title > .left > .title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 7px;
  }
  #root .about-main-section > .wrapper-title > .left > .sub {
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 7px;
  }
  #root .about-main-section > .wrapper-title > .left > .text {
    line-height: 22px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 36px;
  }
  #root .about-main-section > .wrapper-title > .left > .button {
    width: 230px;
    margin: 0 auto;
  }
  #root .about-main-section > .wrapper-title > .left > .button > .title {
    margin-right: 48px;
  }
  #root .about-main-section > .wrapper-title > .right {
    right: unset;
  }
  #root .about-main-section > .wrapper-title > .right > img {
    margin-top: 49px;
    width: 320px;
  }
}