#root .payment-fix-finish-page > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100vh;
}
#root .payment-fix-finish-page > .wrapper > .title {
  font-weight: 700;
  font-size: 26px;
}
#root .payment-fix-finish-page > .wrapper > .sub {
  font-weight: 400;
  font-size: 13px;
  color: #545454;
  text-align: center;
  white-space: pre-line;
  line-height: 17px;
  margin: 6px 0 30px;
}
#root .payment-fix-finish-page > .wrapper > .img-wrapper {
  width: 160px;
}
#root .payment-fix-finish-page > .wrapper > .btn-wrapper {
  margin-top: 55px;
  display: grid;
  row-gap: 11px;
  width: 100%;
}
#root .payment-fix-finish-page > .wrapper > .btn-wrapper > button {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  height: 50px;
}
#root .payment-fix-finish-page > .wrapper > .btn-wrapper > button:hover {
  opacity: 0.7;
}
#root .payment-fix-finish-page > .wrapper > .btn-wrapper > .nav-btn {
  background-color: black;
  color: white;
}
#root .payment-fix-finish-page > .wrapper > .btn-wrapper > .question {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #696969;
}
#root .payment-fix-finish-page > .wrapper > .btn-wrapper > .question > .sub {
  font-weight: 400;
  font-size: 12px;
}
#root .payment-fix-finish-page > .wrapper > .btn-wrapper > .question > .title {
  font-size: 15px;
  font-weight: bold;
}