.noticeCard {
  padding: 15px 20px 18px 20px;
  border-bottom: 1px solid #dbdbdb;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.noticeCard > .card {
  cursor: pointer;
}
.noticeCard > .card > .title {
  font-weight: bold;
}
.noticeCard > .card > .sub {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.noticeCard > .card > .sub > .subLeft {
  font-size: 13px;
  font-weight: bold;
  color: #949494;
}
.noticeCard > .card > .sub > .subright {
  font-size: 13px;
  font-weight: 400;
  color: #949494;
}
.noticeCard > .card > .line {
  width: 1024px;
  border-top: 1px solid #dbdbdb;
  margin-top: 18px;
}

.noticeCard:hover {
  opacity: 0.7;
}