#root .list-card {
  background-color: transparent;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
#root .list-card > .img-wrapper {
  display: block;
  cursor: pointer;
  width: 100%;
  height: 270px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 7px;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
}
#root .list-card > .img-wrapper .finish-blur {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 6px;
}
#root .list-card > .img-wrapper .finish-blur > img {
  width: 21px;
}
#root .list-card > .img-wrapper .finish-blur > .blur-title {
  font-size: 14px;
  font-weight: bold;
  color: white;
}
#root .list-card > .img-wrapper .card-tag-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root .list-card > .img-wrapper .card-tag-wrapper > .tag {
  width: 47px;
  height: 20px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 10px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .list-card > .img-wrapper .card-tag-wrapper .new-tag {
  background-color: #0062FF;
}
#root .list-card > .img-wrapper .card-tag-wrapper .finish-tag {
  background-color: #B8FF6C;
  color: black;
}
#root .list-card > .img-wrapper .card-tag-wrapper .hot-tag {
  background-color: #8741EA;
}
#root .list-card > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .list-card > .title-wrapper > .title {
  color: black;
  text-align: left;
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: nowrap;
}
#root .list-card > .title-wrapper > .grey-title {
  color: #696969;
}
#root .list-card > .title-wrapper > svg {
  cursor: pointer;
}
#root .list-card > .title-wrapper > svg > path {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .list-card > .title-wrapper > .scrap-on > path {
  fill: #0047FF;
  fill-opacity: 1;
  stroke: unset;
}
#root .list-card > .line {
  width: 100%;
  height: 1px;
  background-color: #DBDBDB;
  margin: 9.5px 0;
}
#root .list-card > .period-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .list-card > .period-wrapper > .period {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}
#root .list-card > .period-wrapper > .period > span {
  font-weight: 700;
  color: #0062FF;
}
#root .list-card > .period-wrapper > .category {
  width: 88px;
  height: 22px;
  background-color: #E9EFFF;
  border-radius: 11px;
  font-weight: 700;
  font-size: 10px;
  color: #0062FF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .list-card > .period-wrapper .meetup-category {
  width: 51px;
}
#root .list-card:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1365px) {
  #root .list-card > .img-wrapper {
    height: 293px;
  }
}
@media screen and (max-width: 767px) {
  #root .list-card > .img-wrapper {
    height: 75vw;
    min-height: 270px;
  }
}