.banner-section {
  height: 526px;
  -webkit-transition: background-color 0.2s ease-in;
  transition: background-color 0.2s ease-in;
}
.banner-section > .wrapper {
  width: 1024px;
  overflow: hidden;
  padding-top: 109px;
  position: relative;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper {
  width: 454px;
  height: 371px;
  border-radius: 10px;
  overflow: hidden;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .img-slider {
  display: grid;
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .img-slider > .back-img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content {
  width: 500px;
  padding-top: 30px;
  overflow: hidden;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider {
  display: grid;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content {
  color: white;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .title {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  white-space: pre-wrap;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .sub {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  white-space: pre-wrap;
  margin: 14px 0 20px;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a {
  background-color: black;
  width: 230px;
  height: 44px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
  padding: 0 12px;
  font-weight: 700;
  font-size: 15px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a > svg {
  width: 27px;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a:hover {
  background-color: white;
  color: black;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a:hover > svg > .left {
  stroke: black;
}
.banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a:hover > svg > .right {
  fill: black;
}
.banner-section > .wrapper > .slide-wrapper {
  position: absolute;
  left: 0;
  bottom: 84px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 11px;
     -moz-column-gap: 11px;
          column-gap: 11px;
}
.banner-section > .wrapper > .slide-wrapper > .index-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  color: black;
  font-weight: 700;
  font-size: 12px;
  width: 50px;
  height: 26px;
  border-radius: 30px;
}
.banner-section > .wrapper > .slide-wrapper > .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
}
.banner-section > .wrapper > .slide-wrapper > .btn-wrapper > svg {
  width: 23px;
  cursor: pointer;
}
.banner-section > .wrapper > .slide-wrapper > .btn-wrapper > .right {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Black {
  background-color: black;
  color: white;
}
.Black > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a {
  background-color: #0062FF;
}
.Black > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a:hover {
  background-color: white;
  color: #0062FF;
}
.Black > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a:hover > svg > .left {
  stroke: #0062FF;
}
.Black > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a:hover > svg > .right {
  fill: #0062FF;
}

.Blue {
  background-color: #0062FF;
  color: white;
}

.Skyblue {
  background-color: #7BB1FF;
}

.Green {
  background-color: #5DC287;
}

.Purple {
  background-color: #8741EA;
  color: white;
}

@media screen and (max-width: 1365px) {
  .banner-section {
    height: 507px;
  }
  .banner-section > .wrapper {
    width: 767px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper {
    width: 422px;
    height: 345px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content {
    width: 310px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .title {
    font-size: 26px;
    line-height: 35px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .sub {
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 18px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > a {
    width: 156px;
  }
  .banner-section > .wrapper > .slide-wrapper {
    bottom: 53px;
  }
}
@media screen and (max-width: 767px) {
  .banner-section {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .banner-section > .wrapper {
    width: 100%;
    -webkit-box-sizing: unset;
            box-sizing: unset;
    padding: 113px 0 34px 0 !important;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    row-gap: 44px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper {
    width: 100%;
    height: 70vw;
    min-height: 245px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .img-wrapper > .img-slider {
    width: 90vw;
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    padding: 0 3px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content {
    width: 99vw;
    padding: unset;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content {
    width: 100%;
    padding: 0 30px;
  }
  .banner-section > .wrapper > .content-wrapper > .banner-wrapper > .content > .content-slider > .slide-content > .sub {
    font-size: 14px;
    margin: 8px 0 18px;
  }
  .banner-section > .wrapper > .slide-wrapper {
    bottom: unset;
    left: unset;
    top: 273px;
    right: 30px;
  }
  .banner-section > .wrapper > .slide-wrapper > .btn-wrapper {
    display: none;
  }
}