.resend-mail {
  width: 100%;
  height: 100%;
}
.resend-mail > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.resend-mail > .wrapper > .tag {
  color: #949494;
  font-weight: bold;
  font-size: 15px;
}
.resend-mail > .wrapper > .title {
  font-size: 26px;
  font-weight: bold;
  margin: 9px 0 26px 0;
  line-height: 35px;
}
.resend-mail > .wrapper > .box {
  width: 100%;
  height: 181px;
  background-color: #F5F6F8;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 5px;
}
.resend-mail > .wrapper > .box > img {
  width: 92px;
}
.resend-mail > .wrapper > .box > .email {
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
}
.resend-mail > .wrapper > .box > .email > span {
  font-weight: bold;
  color: #0062FF;
}
.resend-mail > .wrapper > .re-send {
  cursor: pointer;
  margin: 40px 0 26px 0;
  width: 100%;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 14px;
  background-color: #0062FF;
  color: white;
}
.resend-mail > .wrapper > .re-send > .sub {
  font-size: 12px;
}
.resend-mail > .wrapper > .re-send > .title {
  font-size: 15px;
  font-weight: bold;
}
.resend-mail > .wrapper > .re-send:disabled {
  background-color: #949494;
}
.resend-mail > .wrapper > .nav-main {
  font-size: 13px;
  text-decoration: underline;
  -ms-flex-item-align: center;
      align-self: center;
}