#root .my-teampl-card {
  width: 100%;
  min-height: 207px;
  max-height: 277px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 15px 17px;
}
#root .my-teampl-card > .top-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 38px;
     -moz-column-gap: 38px;
          column-gap: 38px;
}
#root .my-teampl-card > .top-section > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 13px;
     -moz-column-gap: 13px;
          column-gap: 13px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .my-teampl-card > .top-section > .left > .img-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 54px;
  height: 54px;
  border-radius: 5px;
}
#root .my-teampl-card > .top-section > .left > .title {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}
#root .my-teampl-card > .top-section > .state-box {
  width: 47px;
  height: 20px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .my-teampl-card > .top-section > .finish,
#root .my-teampl-card > .top-section .finish-review {
  background-color: #949494;
  color: white;
}
#root .my-teampl-card > .top-section > .ready {
  background: #0062FF;
  color: white;
}
#root .my-teampl-card > .line {
  width: 100%;
  height: 1px;
  background-color: #DBDBDB;
  margin: 15px 0 8px;
}
#root .my-teampl-card > .bottom-section > .info-section {
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  -webkit-column-gap: 11px;
     -moz-column-gap: 11px;
          column-gap: 11px;
}
#root .my-teampl-card > .bottom-section > .info-section > .tag {
  width: 50px;
  font-weight: 500;
  white-space: nowrap;
  color: #696969;
}
#root .my-teampl-card > .bottom-section > .info-section > .info-data {
  font-weight: 700;
}
#root .my-teampl-card > .bottom-section > .button-section {
  margin-top: 8px;
  width: 100%;
  display: grid;
  row-gap: 10px;
}
#root .my-teampl-card > .bottom-section > .button-section > button {
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 12px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 50px;
  font-weight: 700;
  font-size: 15px;
}
#root .my-teampl-card > .bottom-section > .button-section > button:hover {
  opacity: 0.7;
}
#root .my-teampl-card > .bottom-section > .button-section > .payment-btn {
  background-color: #0062FF;
  color: white;
}
#root .my-teampl-card > .bottom-section > .button-section > .room-btn {
  color: #696969;
}
#root .my-teampl-card > .bottom-section > .button-section > .room-btn > svg > .left {
  stroke: #696969;
}
#root .my-teampl-card > .bottom-section > .button-section > .room-btn > svg > .right {
  fill: #696969;
}
#root .my-teampl-card > .bottom-section > .button-section > .review-btn {
  background-color: black;
  color: white;
}