#root .mtup-place-section {
  margin: 20px 0;
}
#root .mtup-place-section > .mtup-title {
  font-weight: 700;
  font-size: 24px;
}
#root .mtup-place-section > .mtup-place-wrapper {
  margin: 13px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-column-gap: 2px;
     -moz-column-gap: 2px;
          column-gap: 2px;
}
#root .mtup-place-section > .mtup-place-wrapper > img {
  width: 21px;
}
#root .mtup-place-section > .mtup-place-wrapper .mtup-place-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}