@-webkit-keyframes firstside {
  from {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes firstside {
  from {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.first-info {
  -webkit-animation: unset;
          animation: unset;
  padding-bottom: 58px;
  min-height: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.first-info > div {
  -webkit-animation: firstside 0.2s ease-in;
          animation: firstside 0.2s ease-in;
}
.first-info > .step1 > .bottom {
  padding-top: 25px;
  width: 320px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.first-info > .step1 > .bottom > label {
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  width: 171px;
  height: 171px;
  position: relative;
}
.first-info > .step1 > .bottom > label > img {
  width: 171px;
}
.first-info > .step1 > .bottom > label > input {
  position: absolute;
  opacity: 0;
  left: 0;
}
.first-info > .step1 > .bottom > .login-input {
  margin: 20px 0;
}
.first-info > .step1 > .bottom > .login-input > .title {
  color: black;
}
.first-info > .step1 > .bottom > .area-wrapper {
  margin-bottom: 55px;
  width: 100%;
}
.first-info > .step1 > .bottom > .area-wrapper > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.first-info > .step1 > .bottom > .area-wrapper > .title-wrapper > .title {
  font-weight: 700;
  font-size: 13px;
}
.first-info > .step1 > .bottom > .area-wrapper > .title-wrapper > .length {
  font-weight: 400;
  font-size: 13px;
  color: #0062FF;
}
.first-info > .step1 > .bottom > .area-wrapper > textarea {
  margin-top: 7px;
  width: 320px;
  height: 145px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 15px;
}
.first-info > .step2 > .bottom {
  padding-top: 30px;
  width: 320px;
  margin: 0 auto;
}
.first-info > .step2 > .bottom > .title {
  font-weight: 700;
  font-size: 15px;
}
.first-info > .step2 > .bottom > .sub {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #696969;
  margin: 6px 0 12px 0;
}
.first-info > .step2 > .bottom > input {
  width: 100%;
  height: 50px;
  padding: 15px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
}
.first-info > .step2 > .bottom > img {
  margin: 13px 0 100.7px;
}
.first-info > .step3 > .bottom {
  padding-top: 26px;
  width: 320px;
  margin: 0 auto;
}