@-webkit-keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: none;
}
.popup > .back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: unset;
          animation: unset;
}
.popup > div {
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
}
.popup > div > .cancel {
  position: absolute;
  cursor: pointer;
  width: 28px;
  right: 10px;
  top: 10px;
}
.popup > .marketing {
  padding: 33px 25px;
  width: 371px;
  height: 357px;
  background-color: white;
  z-index: 5;
  border-radius: 5px;
  text-align: center;
}
.popup > .marketing > .main {
  width: 90px;
}
.popup > .marketing > .title {
  font-size: 18px;
  line-height: 25px;
  font-weight: bold;
}
.popup > .marketing > .btn-wrapper {
  margin-top: 23px;
  display: grid;
  row-gap: 10px;
}
.popup > .marketing > .btn-wrapper > button {
  cursor: pointer;
  width: 100%;
  height: 45px;
  font-weight: bold;
  font-size: 15px;
}
.popup > .marketing > .btn-wrapper > .yes {
  background-color: #0062FF;
  color: white;
}
.popup > .marketing > .btn-wrapper > .no {
  background-color: #F5F6F8;
  color: #0062FF;
}
.popup .error-popup {
  width: 350px;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  border-radius: 5px;
}
.popup .error-popup > .sub {
  text-align: center;
  line-height: 1.3;
  font-size: 14px;
}
.popup .error-popup > .btn-wrapper {
  margin-top: 10px;
  display: grid;
  row-gap: 10px;
}
.popup .error-popup > .btn-wrapper > button {
  width: 282px;
  height: 50px;
  cursor: pointer;
  font-weight: 700;
  font-size: 15px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.popup .error-popup > .btn-wrapper > button:hover {
  opacity: 0.7;
}
.popup .error-popup > .btn-wrapper > .cancel-btn {
  background-color: #F5F6F8;
  color: black;
}
.popup > .report-popup {
  background-color: white;
  width: 320px;
  height: 348px;
  border-radius: 5px;
  padding-top: 39px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: unset;
}
.popup > .report-popup > .title {
  font-weight: 700;
  font-size: 24px;
}
.popup > .report-popup > .sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 6px 0 89px;
  text-align: center;
}
.popup > .report-popup .report-category-section {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  position: absolute;
  top: 141px;
  width: 282px;
  height: 40px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  background-color: white;
  display: grid;
  grid-template-rows: repeat(8, 40px);
  overflow: hidden;
}
.popup > .report-popup .report-category-section .report-category-btn {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 11px;
  background-color: white;
  font-weight: 500;
  color: #696969;
  font-size: 14px;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.popup > .report-popup .report-category-section .report-category-btn > img {
  width: 13.5px;
}
.popup > .report-popup .open-report-category {
  height: 320px;
}
.popup > .report-popup .open-report-category .report-category-btn > img {
  -webkit-transform: scale(-1);
          transform: scale(-1);
}
.popup > .report-popup > .btn-wrapper {
  display: grid;
  row-gap: 10px;
}
.popup > .report-popup > .btn-wrapper > button {
  width: 282px;
  height: 50px;
  cursor: pointer;
  font-weight: 700;
  font-size: 15px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.popup > .report-popup > .btn-wrapper > button:hover {
  opacity: 0.7;
}
.popup > .report-popup > .btn-wrapper > .report-btn {
  color: white;
  background-color: #0062FF;
}
.popup > .report-popup > .btn-wrapper > .cancel-btn {
  background-color: #F5F6F8;
  color: black;
}
.popup .with-draw-section {
  background-color: white;
  width: 320px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 5px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 10px;
}
.popup .with-draw-section > .title {
  font-size: 16px;
  font-weight: bold;
  padding: 50px 0;
}
.popup .with-draw-section > .btn-wrapper {
  display: grid;
  row-gap: 10px;
  width: 100%;
}
.popup .with-draw-section > .btn-wrapper > .no {
  color: white;
  background-color: #0062FF;
}
.popup .with-draw-section > .btn-wrapper > .yes {
  color: #949494;
  background-color: white;
}
.popup .with-draw-section > .btn-wrapper > button {
  cursor: pointer;
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
}
.popup .remove-comment-popup {
  background-color: white;
  width: 320px;
  border-radius: 5px;
  height: 307px;
  padding-top: 58px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: unset;
}
.popup .remove-comment-popup > .title {
  font-weight: 700;
  font-size: 24px;
}
.popup .remove-comment-popup > .sub {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  margin: 6px 0 21px;
}
.popup .remove-comment-popup > .btn-wrapper {
  display: grid;
  row-gap: 10px;
}
.popup .remove-comment-popup > .btn-wrapper > button {
  width: 282px;
  height: 50px;
  cursor: pointer;
  font-weight: 700;
  font-size: 15px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.popup .remove-comment-popup > .btn-wrapper > button:hover {
  opacity: 0.7;
}
.popup .remove-comment-popup > .btn-wrapper > .report-btn {
  color: white;
  background-color: #0062FF;
}
.popup .remove-comment-popup > .btn-wrapper > .cancel-btn {
  background-color: #F5F6F8;
  color: black;
}

.popup-on {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 767px) {
  .popup > .marketing {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 338px;
    -webkit-box-shadow: 0px -3px 15px rgba(0, 0, 0, 0.16);
            box-shadow: 0px -3px 15px rgba(0, 0, 0, 0.16);
    border-radius: 20px 20px 0px 0px;
  }
  .popup > .marketing > .btn-wrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .popup > .marketing > .btn-wrapper > button {
    width: 320px;
  }
}