.login {
  width: 100%;
  height: 100%;
  background-color: white;
}
.login > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login > .wrapper > .title {
  width: 237px;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
}
.login > .wrapper > .input-section {
  display: grid;
  row-gap: 21px;
  margin: 35px 0 47px 0;
}
.login > .wrapper > .button-section {
  display: grid;
  row-gap: 11px;
}
.login > .wrapper > .password-find {
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 34px;
  font-weight: 400;
  font-size: 13px;
  background-color: unset;
  text-decoration: underline;
}