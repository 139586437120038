#root .credit-card {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  width: 100%;
  height: 90px;
  background-color: #E9EFFF;
  border-radius: 10px;
  position: relative;
  padding: 16px 17px 20px 17px;
  overflow: hidden;
  cursor: pointer;
}
#root .credit-card > .check {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  top: 35px;
  right: 12px;
  background-color: white;
}
#root .credit-card > .check > img {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  opacity: 0;
}
#root .credit-card .credit-name {
  font-weight: 700;
  font-size: 15px;
  color: black;
}
#root .credit-card .credit-number {
  font-weight: 700;
  font-size: 15px;
  color: #696969;
  margin-top: 16px;
}
#root .credit-card .credit-notice {
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: white;
  margin-top: 29px;
}
#root .credit-card:hover {
  opacity: 0.8;
}
#root .select-card {
  height: 188px;
  background-color: #0062FF;
}
#root .select-card > .check > img {
  opacity: 1;
}
#root .select-card .credit-name {
  color: white;
}
#root .select-card .credit-number {
  color: white;
}