.signup-finish {
  background-color: black;
  width: 100%;
  height: 100%;
}
.signup-finish > .wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: white;
}
.signup-finish > .wrapper > .main-img {
  width: 232px;
}
.signup-finish > .wrapper > .title {
  font-weight: bold;
  font-size: 26px;
  margin: 28px 0 9px 0;
}
.signup-finish > .wrapper > .sub {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 148px;
}

@media screen and (max-width: 767px) {
  .signup-finish > .wrapper {
    width: 320px;
    padding: unset;
  }
}