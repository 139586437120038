.alliance {
  padding-top: 66px;
}
.alliance > .wrapper {
  width: 728px;
}
.alliance > .wrapper > .title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 16px;
}
.alliance > .wrapper > .box {
  margin-bottom: 32px;
  padding: 12px 0px 12px 9px;
  background-color: #f5f6f8;
  border-radius: 5px;
  width: 728px;
  height: 67px;
}
.alliance > .wrapper > .box > .sub {
  margin-bottom: 12px;
  font-size: 12px;
  color: #545454;
}
.alliance > .wrapper .alliance-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 36px;
}
.alliance > .wrapper .alliance-list .alliance-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-column-gap: 14px;
     -moz-column-gap: 14px;
          column-gap: 14px;
}
.alliance > .wrapper .alliance-list .alliance-box > .left {
  width: 112px;
  height: 112px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.alliance > .wrapper .alliance-list .alliance-box > .right {
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 26px;
}
.alliance > .wrapper .alliance-list .alliance-box > .right > .top {
  font-weight: bold;
  font-size: 15px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.alliance > .wrapper .alliance-list .alliance-box > .right > .top > span {
  max-width: 500px;
  display: block;
  font-size: 13px;
  color: #0062ff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.alliance > .wrapper .alliance-list .alliance-box > .right > .bottom {
  background-color: unset;
}
.alliance > .wrapper .alliance-list .alliance-box > .right > .bottom > .bottom {
  cursor: pointer;
  padding-left: 13px;
  padding-right: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: black;
  font-weight: bold;
  width: 121px;
  height: 34px;
}
.alliance > .wrapper .alliance-list .alliance-box > .right > .bottom > .bottom > .text {
  color: white;
}
.alliance > .wrapper .alliance-list .alliance-box > .right > .bottom > .bottom > .img > img {
  width: 6px;
  height: 11px;
}

@media screen and (max-width: 1365px) {
  .alliance > .wrapper {
    width: 728px;
  }
  .alliance > .wrapper > .box {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .alliance > .wrapper {
    width: 100%;
  }
  .alliance > .wrapper .alliance-list .alliance-box > .right {
    width: 50%;
  }
  .alliance > .wrapper .alliance-list .alliance-box > .right > .top > span {
    max-width: 100%;
  }
}