#root .magazine {
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
}
#root .magazine > div > .wrapper {
  width: 1024px;
}
#root .magazine > .title {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 26px;
}
#root .magazine > .grey-block {
  width: 100%;
  height: 9px;
  background-color: #F5F6F8;
}

@media screen and (max-width: 1365px) {
  #root .magazine > div > .wrapper {
    width: 727px;
  }
}
@media screen and (max-width: 767px) {
  #root .magazine > div > .wrapper {
    width: 100%;
  }
}