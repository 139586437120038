.noticelist {
  padding-top: 81px;
}
.noticelist > .wrapper {
  width: 728px;
  padding-bottom: 20px;
}
.noticelist > .wrapper > .title {
  font-size: 24px;
  font-weight: bold;
}
.noticelist > .wrapper > .sub {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 24px;
  font-size: 12px;
  margin-top: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
.noticelist > .wrapper > .sub > .subleft {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
.noticelist > .wrapper > .sub > .subleft > .blue {
  background-color: #0062ff;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.noticelist > .wrapper > .sub > .subleft > .blue > img {
  width: 8px;
}
.noticelist > .wrapper > .sub > .subright {
  color: #949494;
}
.noticelist > .wrapper > .content {
  padding: 50px 0;
}

@media screen and (max-width: 1365px) {
  .noticelist > .wrapper {
    width: 728px;
  }
}
@media screen and (max-width: 767px) {
  .noticelist > .wrapper {
    width: 100%;
  }
}