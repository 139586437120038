#root .about-section3 {
  height: 2325px;
  background-color: black;
  padding-top: 158px;
  text-align: center;
}
#root .about-section3 > .wrapper {
  width: 767px;
}
#root .about-section3 > .wrapper > .title-img {
  width: 31px;
  display: inline-block;
  margin-bottom: 12px;
}
#root .about-section3 > .wrapper > .title {
  font-size: 50px;
  color: white;
  font-weight: 800;
}
#root .about-section3 > .wrapper > .sub {
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-top: 24px;
  line-height: 25px;
}
#root .about-section3 > .wrapper > .sub-img {
  width: 31px;
  margin-top: 89px;
  display: inline-block;
  margin-bottom: 9px;
}
#root .about-section3 > .wrapper > .text {
  color: white;
  font-size: 30px;
  font-weight: bold;
}
#root .about-section3 > .wrapper > .list {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(4, 170.36px);
  row-gap: 20.64px;
  -webkit-column-gap: 28.64px;
     -moz-column-gap: 28.64px;
          column-gap: 28.64px;
}
#root .about-section3 > .wrapper > .list > .box > .img {
  width: 170.36px;
  height: 170.36px;
  background-color: #323232;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .about-section3 > .wrapper > .list > .box > .img > img {
  width: 118.84px;
  height: 118.84px;
}
#root .about-section3 > .wrapper > .list > .box > .title {
  color: white;
  margin-top: 9px;
  font-size: 19px;
  font-weight: 600;
}
#root .about-section3 > .wrapper > .text-img {
  width: 31px;
  display: inline-block;
  margin-top: 194px;
}
#root .about-section3 > .wrapper > .title-green {
  margin-top: 8px;
  font-size: 30px;
  font-weight: bold;
  color: white;
}
#root .about-section3 > .wrapper > .span {
  display: inline-block;
  font-size: 30px;
  color: black;
  font-weight: bold;
  margin-top: 10px;
  width: 303px;
  background-color: #b8ff6c;
}
#root .about-section3 > .wrapper > .box-wrapper {
  margin-top: 39px;
  display: grid;
  grid-template-columns: 452px 255px;
  -webkit-column-gap: 26px;
     -moz-column-gap: 26px;
          column-gap: 26px;
}
#root .about-section3 > .wrapper > .box-wrapper > .week-box > .greenbox {
  width: 100%;
  height: 46px;
  background-color: #b8ff6c;
  color: black;
  font-size: 25px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 10px 10px 0px 0px;
}
#root .about-section3 > .wrapper > .box-wrapper > .week-box > .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 237px;
  background-color: white;
  padding-top: 30px;
  -webkit-column-gap: 45px;
     -moz-column-gap: 45px;
          column-gap: 45px;
}
#root .about-section3 > .wrapper > .box-wrapper > .week-box > .box > .box-content > img {
  width: 140px;
}
#root .about-section3 > .wrapper > .box-wrapper > .week-box > .box .week-box-title {
  margin-top: 16px;
  font-size: 22px;
  font-weight: bold;
}
#root .about-section3 > .wrapper > .box-wrapper > .week-box:nth-child(2) > .box {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: unset;
      -ms-flex-pack: unset;
          justify-content: unset;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .about-section3 > .wrapper > .bottom-arrow > img {
  margin-top: 20px;
  margin-bottom: 25px;
  width: 29px;
  height: 39px;
}
#root .about-section3 > .wrapper > .box-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#root .about-section3 > .wrapper > .box-bottom > .left {
  margin-right: 48px;
}
#root .about-section3 > .wrapper > .box-bottom > .left > img {
  width: 210px;
}
#root .about-section3 > .wrapper > .box-bottom > .right {
  position: relative;
  padding: 0px 39px 0px 39px;
  width: 475px;
  height: 206px;
  background-color: #f5f6f8;
  border-radius: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .about-section3 > .wrapper > .box-bottom > .right > .left-wrapper {
  top: 720px;
  left: 866px;
}
#root .about-section3 > .wrapper > .box-bottom > .right > .left-wrapper > .text {
  text-align: left;
  font-size: 30px;
  font-weight: 500;
  color: black;
}
#root .about-section3 > .wrapper > .box-bottom > .right > .left-wrapper > .text > span {
  background-color: #b8ff6c;
  color: black;
  font-weight: bold;
  line-height: 46px;
}
#root .about-section3 > .wrapper > .box-bottom > .right:after {
  content: "";
  position: absolute;
  right: 470px;
  top: 85px;
  border-right: 30px solid #f5f6f8;
  border-left: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
}

@media screen and (max-width: 1365px) {
  #root .about-section3 {
    height: 1951px;
  }
  #root .about-section3 > .wrapper {
    width: 634px;
  }
  #root .about-section3 > .wrapper > .title {
    font-size: 40px;
  }
  #root .about-section3 > .wrapper > .list {
    grid-template-columns: repeat(4, 141px);
    row-gap: 26px;
    -webkit-column-gap: 22px;
       -moz-column-gap: 22px;
            column-gap: 22px;
    margin-top: 30px;
  }
  #root .about-section3 > .wrapper > .list > .box > .img {
    width: 141px;
    height: 141px;
    background-color: #323232;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .about-section3 > .wrapper > .list > .box > .img > img {
    width: 98.84px;
    height: 98.84px;
  }
  #root .about-section3 > .wrapper > .text-img {
    margin-top: 98px;
  }
  #root .about-section3 > .wrapper > .box-wrapper {
    grid-template-columns: 391px 220px;
    -webkit-column-gap: 23px;
       -moz-column-gap: 23px;
            column-gap: 23px;
  }
  #root .about-section3 > .wrapper > .box-wrapper > .week-box > .box > .box-content > img {
    width: 120px;
  }
  #root .about-section3 > .wrapper > .bottom-arrow > img {
    width: 23px;
    height: 34px;
  }
  #root .about-section3 > .wrapper > .box-bottom > .left > img {
    width: 181px;
  }
  #root .about-section3 > .wrapper > .box-bottom > .right > .left-wrapper {
    top: 720px;
    left: 866px;
  }
  #root .about-section3 > .wrapper > .box-bottom > .right > .right-wrapper > img {
    width: 110px;
  }
  #root .about-section3 > .wrapper > .box-bottom > .right:after {
    right: 400px;
    top: 70px;
  }
}
@media screen and (max-width: 767px) {
  #root .about-section3 {
    height: 1763px;
    padding-top: 104px;
  }
  #root .about-section3 > .wrapper {
    width: 100%;
    margin: 0 auto;
  }
  #root .about-section3 > .wrapper > .title {
    font-size: 35px;
    font-weight: 800;
  }
  #root .about-section3 > .wrapper > .text {
    margin-bottom: 25px;
    font-size: 23px;
  }
  #root .about-section3 > .wrapper > .sub {
    font-size: 13px;
    line-height: 23px;
  }
  #root .about-section3 > .wrapper > .sub-img {
    margin-top: 57px;
  }
  #root .about-section3 > .wrapper > .list {
    width: 208px;
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 95px);
    row-gap: 15.64px;
    -webkit-column-gap: 18.64px;
       -moz-column-gap: 18.64px;
            column-gap: 18.64px;
    margin: 0 auto;
  }
  #root .about-section3 > .wrapper > .list > .box > .img {
    width: 95px;
    height: 95px;
    background-color: #323232;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .about-section3 > .wrapper > .list > .box > .img > img {
    width: 66.84px;
    height: 66.84px;
  }
  #root .about-section3 > .wrapper > .list > .box > .title {
    color: white;
    margin-top: 9px;
    font-size: 14px;
    font-weight: 600;
  }
  #root .about-section3 > .wrapper > .text-img {
    margin-top: 84px;
  }
  #root .about-section3 > .wrapper > .title-green {
    margin-top: 6px;
    font-size: 23px;
  }
  #root .about-section3 > .wrapper > .span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 230px;
    height: 40px;
    font-size: 23px;
    margin-top: 10px;
  }
  #root .about-section3 > .wrapper > .box-wrapper {
    grid-template-columns: 197px 111px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 12px;
       -moz-column-gap: 12px;
            column-gap: 12px;
  }
  #root .about-section3 > .wrapper > .box-wrapper > .week-box > .greenbox {
    height: 24px;
    font-size: 14px;
  }
  #root .about-section3 > .wrapper > .box-wrapper > .week-box > .box {
    height: 149px;
    padding-top: 30px;
    -webkit-column-gap: 22px;
       -moz-column-gap: 22px;
            column-gap: 22px;
  }
  #root .about-section3 > .wrapper > .box-wrapper > .week-box > .box > .box-content > img {
    width: 63px;
  }
  #root .about-section3 > .wrapper > .box-wrapper > .week-box > .box > .box-content .week-box-title {
    margin-top: 7px;
    font-size: 13px;
  }
  #root .about-section3 > .wrapper > .bottom-arrow > img {
    margin-top: 16px;
    margin-bottom: 14px;
    width: 18px;
    height: 24px;
  }
  #root .about-section3 > .wrapper > .box-bottom {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .about-section3 > .wrapper > .box-bottom > .left {
    margin-right: 20px;
  }
  #root .about-section3 > .wrapper > .box-bottom > .left > img {
    width: 110px;
    height: 110px;
  }
  #root .about-section3 > .wrapper > .box-bottom > .right {
    padding: 12px 16px 11px 16px;
    width: 200px;
    height: 89px;
  }
  #root .about-section3 > .wrapper > .box-bottom > .right > .left-wrapper > .text {
    font-size: 15px;
  }
  #root .about-section3 > .wrapper > .box-bottom > .right > .left-wrapper > .text > span {
    line-height: 0px;
  }
  #root .about-section3 > .wrapper > .box-bottom > .right > .right-wrapper > img {
    width: 59px;
  }
  #root .about-section3 > .wrapper > .box-bottom > .right:after {
    right: 195px;
    top: 35px;
    border-right: 15px solid #f5f6f8;
    border-left: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
  }
}