.detail-section > div > .wrapper {
  width: 1024px;
}
.detail-section > .back {
  display: none;
}
.detail-section > .mb-field {
  display: none;
}
.detail-section > .top {
  padding-top: 114px;
}
.detail-section > .top > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 64px;
     -moz-column-gap: 64px;
          column-gap: 64px;
}
.detail-section > .top > .wrapper > .img-wrapper {
  width: 564px;
  height: 475px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.detail-section > .top > .wrapper > .right {
  width: 396px;
}
.detail-section > .top > .wrapper > .right > .img-wrapper {
  display: none;
}
.detail-section > .top > .wrapper > .right > .category-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.detail-section > .top > .wrapper > .right > .category-wrapper > .category {
  width: 107px;
  height: 31px;
  background-color: #E9EFFF;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  color: #0062FF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.detail-section > .top > .wrapper > .right > .category-wrapper > .meetup-category {
  width: 70px;
}
.detail-section > .top > .wrapper > .right > .category-wrapper > svg {
  cursor: pointer;
}
.detail-section > .top > .wrapper > .right > .title {
  margin: 16px 0 25px;
  height: 72px;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
}
.detail-section > .top > .wrapper > .right > .top-info {
  display: grid;
  row-gap: 17px;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 14px;
     -moz-column-gap: 14px;
          column-gap: 14px;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line > .tag {
  width: 56px;
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line > .per-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line > .per-wrapper > .period {
  font-weight: 700;
  font-size: 15px;
  color: #0062FF;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line > .per-wrapper > .d-day {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 700;
  font-size: 13px;
  color: #B8FF6C;
  padding: 5px 10px;
  -webkit-box-sizing: unset;
          box-sizing: unset;
  background-color: black;
  border-radius: 20px;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line > .finish-day > .period {
  color: #949494;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line > .finish-day > .d-day {
  background-color: #DBDBDB;
  color: white;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line > .field {
  font-weight: 700;
  font-size: 15px;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line > .field > span {
  color: #0062FF;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line > .leader {
  font-weight: 700;
}
.detail-section > .top > .wrapper > .right > .top-info > .info-line > .leader > .name {
  font-size: 15px;
}
.detail-section > .top > .wrapper > .right > .position {
  margin-top: 4px;
  font-size: 14px;
  color: #949494;
  font-weight: 700;
  margin-left: 70px;
}
.detail-section > .top > .wrapper > .right > .line {
  background-color: #DBDBDB;
  width: 100%;
  height: 1px;
  margin: 18px 0;
}
.detail-section > .top > .wrapper > .right > .price-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.detail-section > .top > .wrapper > .right > .price-wrapper > .title {
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}
.detail-section > .top > .wrapper > .right > .price-wrapper > .price {
  font-weight: 700;
  font-size: 24px;
}
.detail-section > .top > .wrapper > .right > .field-wrapper {
  margin: 29px 0 20px;
  width: 100%;
  height: 50px;
  position: relative;
}
.detail-section > .top > .wrapper > .right > .field-wrapper > .btn-wrapper {
  z-index: 2;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  overflow: hidden;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.detail-section > .top > .wrapper > .right > .field-wrapper > .btn-wrapper > .now {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 12px;
  width: 100%;
  height: 50px;
  background-color: white;
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}
.detail-section > .top > .wrapper > .right > .field-wrapper > .btn-wrapper > .now > img {
  width: 18px;
}
.detail-section > .top > .wrapper > .right > .field-wrapper > .btn-wrapper > .field-list {
  display: grid;
}
.detail-section > .top > .wrapper > .right > .field-wrapper > .btn-wrapper > .field-list > button {
  cursor: pointer;
  height: 50px;
  font-weight: 500;
  font-size: 15px;
  color: #696969;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 12px;
  border-top: 1px solid #DBDBDB;
  background-color: white;
}
.detail-section > .top > .wrapper > .right .mtup-field-wrapper {
  height: 13px;
}
.detail-section > .top > .wrapper > .right > .insert {
  width: 100%;
  height: 50px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.detail-section > .top > .wrapper > .right > .insert:disabled, .detail-section > .top > .wrapper > .right > .insert:disabled:hover {
  background-color: #DBDBDB;
  color: white;
  cursor: not-allowed;
  border: unset;
}
.detail-section > .top > .wrapper > .right > .insert:disabled > svg > g > path, .detail-section > .top > .wrapper > .right > .insert:disabled:hover > svg > g > path {
  fill: white;
}
.detail-section > .top > .wrapper > .right > .insert:hover {
  background-color: white;
  color: black;
  border: solid 1px black;
}
.detail-section > .top > .wrapper > .right > .insert:hover > svg > g > path {
  fill: black;
}
.detail-section > .nav-wrapper {
  margin-top: 81px;
  height: 50px;
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
}
.detail-section > .nav-wrapper > .wrapper {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
.detail-section > .nav-wrapper > .wrapper > .nav {
  cursor: pointer;
  width: 63px;
  background-color: white;
  font-weight: 400;
  font-size: 14px;
  color: black;
}
.detail-section > .nav-wrapper > .wrapper > .nav-active {
  font-weight: 700;
  color: #0062FF;
}
.detail-section > .nav-wrapper > .wrapper > .line {
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
  position: absolute;
  left: 0;
  bottom: -1.5px;
  width: 63px;
  background-color: #0062FF;
  height: 3px;
}
.detail-section > .bottom > div {
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
}
.detail-section > .bottom > div > div > .wrapper {
  width: 728px !important;
}
.detail-section > .bottom > .info-section {
  width: 100%;
}
.detail-section > .bottom > .info-section > .leader-section {
  background-color: #B8FF6C;
  width: 100%;
  -webkit-box-sizing: unset;
          box-sizing: unset;
}
.detail-section > .bottom > .info-section > .leader-section > .wrapper {
  padding: 56px 0 50px;
}
.detail-section > .bottom > .info-section > .leader-section > .wrapper > .title {
  font-weight: 700;
  font-size: 18px;
  color: #0062FF;
}
.detail-section > .bottom > .info-section > .leader-section > .wrapper > img {
  width: 100%;
  border-radius: 10px;
  margin: 13px 0 17px;
}
.detail-section > .bottom > .info-section > .leader-section > .wrapper > .leader-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  white-space: pre-line;
}
.detail-section > .bottom > .info-section > .leader-section > .wrapper > .sub {
  font-weight: 400;
  font-size: 15px;
  white-space: pre-line;
  line-height: 24px;
  margin: 13px 0 30px;
}
.detail-section > .bottom > .info-section > .leader-section > .wrapper > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 14px 0 11px;
  width: 100%;
  height: 53px;
  background-color: white;
  border-radius: 5px;
}
.detail-section > .bottom > .info-section > .leader-section > .wrapper > a > .left-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
.detail-section > .bottom > .info-section > .leader-section > .wrapper > a > .left-wrapper > .icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #F5F6F8;
  padding: 3px;
}
.detail-section > .bottom > .info-section > .leader-section > .wrapper > a > .left-wrapper > .title {
  font-weight: 700;
  font-size: 15px;
}
.detail-section > .bottom > .info-section > .leader-section > .wrapper > a > .arrow {
  width: 15px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.detail-section > .bottom > .info-section > .intro-section {
  padding: 59px 0 96px;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .section-title {
  margin-top: 105px;
  font-weight: 700;
  font-size: 18px;
  color: #0062FF;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > img {
  margin: 13px 0 16px;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .title {
  font-weight: 700;
  font-size: 24px;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list {
  margin-top: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 19px;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list > .field-card {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #F5F6F8;
  border-radius: 5px;
  padding: 25px 17px 70px 24px;
  position: relative;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list > .field-card > .left > .title {
  width: 80%;
  font-weight: 700;
  font-size: 18px;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list > .field-card > .left > .people {
  margin: 9px 0 12px;
  font-weight: 700;
  font-size: 14px;
  color: #545454;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list > .field-card > .left > .people > span {
  color: #5DC287;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list > .field-card > .left > .sub {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #545454;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list > .field-card > .category-icon {
  position: absolute;
  top: 23px;
  right: 14px;
  width: 50px;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list > .field-card > .insert {
  width: 103px;
  height: 39px;
  background-color: #000000;
  border-radius: 50px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  position: absolute;
  right: 17px;
  bottom: 18px;
  cursor: pointer;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list > .field-card > .insert:hover {
  background-color: white;
  color: black;
}
.detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list > .field-card > .insert:disabled, .detail-section > .bottom > .info-section > .intro-section > .wrapper > .field-wrapper > .field-list > .field-card > .insert:disabled:hover {
  cursor: not-allowed;
  background-color: #949494;
  color: white;
}
.detail-section > .bottom > .info-section > .reward-section {
  width: 100%;
  padding: 34px 0 49px;
  -webkit-box-sizing: unset;
          box-sizing: unset;
  background-color: #0062FF;
}
.detail-section > .bottom > .info-section > .reward-section > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.detail-section > .bottom > .info-section > .reward-section > .wrapper > img {
  width: 83px;
}
.detail-section > .bottom > .info-section > .reward-section > .wrapper > .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: white;
  margin-bottom: 18px;
  text-align: center;
}
.detail-section > .bottom > .info-section > .reward-section > .wrapper > .reward-list {
  width: 100%;
  display: grid;
  row-gap: 12px;
}
.detail-section > .bottom > .info-section > .reward-section > .wrapper > .reward-list > .reward-card {
  width: 100%;
  height: 53px;
  background-color: white;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 14px;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  font-weight: 700;
  font-size: 16px;
}
.detail-section > .bottom > .info-section > .reward-section > .wrapper > .reward-list > .reward-card > img {
  width: 20px;
}
.detail-section > .bottom > .info-section > .penalty-section {
  padding: 60px 0;
}
.detail-section > .bottom > .info-section > .penalty-section > .wrapper > .title {
  font-weight: 700;
  font-size: 24px;
}
.detail-section > .bottom > .info-section > .penalty-section > .wrapper > .penalty-list {
  margin-top: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 14px 8px;
}
.detail-section > .bottom > .info-section > .penalty-section > .wrapper > .penalty-list > .box-line {
  color: #0062FF;
  border: solid 1px #0062FF;
  border-radius: 30px;
  font-weight: 700;
  font-size: 16px;
  padding: 4px 16px;
}
.detail-section > .bottom > .info-section > .grey-line {
  width: 100%;
  height: 11px;
  background-color: #F5F6F8;
}
.detail-section > .bottom > .ask-section {
  padding: 36px 0 134px;
}
.detail-section > .bottom > .ask-section > .top > .wrapper > .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 22px;
}
.detail-section > .bottom > .ask-section > .top > .wrapper > .basic-card {
  width: 100%;
  height: 113px;
  background: #E9EFFF;
  border-radius: 5px;
  padding: 17px;
}
.detail-section > .bottom > .ask-section > .top > .wrapper > .basic-card > .profile-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
}
.detail-section > .bottom > .ask-section > .top > .wrapper > .basic-card > .profile-wrapper > .img-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.detail-section > .bottom > .ask-section > .top > .wrapper > .basic-card > .profile-wrapper > .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 3px;
}
.detail-section > .bottom > .ask-section > .top > .wrapper > .basic-card > .profile-wrapper > .right > .name {
  font-weight: 700;
  font-size: 17px;
}
.detail-section > .bottom > .ask-section > .top > .wrapper > .basic-card > .profile-wrapper > .right > .grade {
  font-weight: 700;
  font-size: 12px;
  color: #0062FF;
}
.detail-section > .bottom > .ask-section > .top > .wrapper > .basic-card > .profile-wrapper {
  opacity: 0.7;
}
.detail-section > .bottom > .ask-section > .top > .wrapper > .basic-card > .content {
  font-weight: 400;
  font-size: 14px;
  margin-top: 19px;
}
.detail-section > .bottom > .ask-section > .top > .wrapper > .comment-wrapper {
  margin-top: 10px;
  display: grid;
  row-gap: 11px;
}
.detail-section > .bottom > .ask-section > .insert-bottom {
  background-color: white;
  z-index: 10;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 75px;
  -webkit-box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.11);
          box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.11);
}
.detail-section > .bottom > .ask-section > .insert-bottom > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
.detail-section > .bottom > .ask-section > .insert-bottom > .wrapper > .img-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.detail-section > .bottom > .ask-section > .insert-bottom > .wrapper > button {
  cursor: text;
  width: 100%;
  height: 50px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  padding-left: 11px;
  font-weight: 400;
  font-size: 15px;
  color: #949494;
}
.detail-section > .bottom > .member-section {
  padding: 31px 0 64px;
}
.detail-section > .bottom > .member-section > .wrapper > .title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 16px;
}
.detail-section > .bottom > .member-section > .wrapper > .member-list {
  display: grid;
  row-gap: 10px;
}
.detail-section > .bottom > .review-section {
  padding: 32px 0 150px;
}
.detail-section > .bottom > .review-section > .insert-bottom {
  background-color: white;
  z-index: 10;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 75px;
  -webkit-box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.11);
          box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.11);
}
.detail-section > .bottom > .review-section > .insert-bottom > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
.detail-section > .bottom > .review-section > .insert-bottom > .wrapper > .img-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.detail-section > .bottom > .review-section > .insert-bottom > .wrapper > button {
  cursor: text;
  width: 100%;
  height: 50px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  padding-left: 11px;
  font-weight: 400;
  font-size: 15px;
  color: #949494;
}
.detail-section > .bottom > .review-section > .review-wrapper > .wrapper > .review-length {
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
  font-weight: 700;
  font-size: 20px;
}
.detail-section > .bottom > .review-section > .review-wrapper > .wrapper > .review-length > span {
  color: #0062FF;
}
.detail-section > .bottom > .review-section > .review-wrapper > .wrapper > .review-list {
  display: grid;
  row-gap: 14px;
}

@media screen and (max-width: 1365px) {
  .detail-section > div > .wrapper {
    width: 728px;
  }
  .detail-section > .top {
    padding-top: 72px;
  }
  .detail-section > .top > .wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 17px 0;
  }
  .detail-section > .top > .wrapper > .img-wrapper {
    display: none;
  }
  .detail-section > .top > .wrapper > .right {
    width: 100%;
  }
  .detail-section > .top > .wrapper > .right > .title {
    margin: 17px 0;
  }
  .detail-section > .top > .wrapper > .right > .img-wrapper {
    display: block;
    width: 100%;
    height: 614.25px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    margin-bottom: 23.75px;
  }
}
@media screen and (max-width: 767px) {
  .detail-section > div > .wrapper {
    width: 100%;
    padding: 0 20px;
  }
  .detail-section > .back-on {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
  }
  .detail-section > .mb-field {
    z-index: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    position: fixed;
    bottom: -100vh;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    -webkit-box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.12);
            box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.12);
    padding: 30px 20px 88px 20px;
  }
  .detail-section > .mb-field > .close-btn {
    cursor: pointer;
    z-index: 500;
    -ms-flex-item-align: center;
        align-self: center;
    width: 49px;
    height: 36px;
    background-color: white;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    top: -26px;
  }
  .detail-section > .mb-field > .close-btn > img {
    width: 17px;
  }
  .detail-section > .mb-field > .title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 17px;
  }
  .detail-section > .mb-field > .field-list {
    display: grid;
    row-gap: 11px;
    max-height: 300px;
    overflow-y: scroll;
  }
  .detail-section > .mb-field > .field-list > .select {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 10px;
    height: 44px;
    background: #F5F6F8;
    border-radius: 5px;
    -webkit-column-gap: 7px;
       -moz-column-gap: 7px;
            column-gap: 7px;
    font-weight: 700;
    font-size: 15px;
    color: #545454;
  }
  .detail-section > .mb-field > .field-list > .select > .circle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: solid 1px #DBDBDB;
    background-color: white;
  }
  .detail-section > .mb-field > .field-list > .select > .circle > img {
    display: none;
  }
  .detail-section > .mb-field > .field-list > .select-active {
    background-color: #E9EFFF;
    color: #0062FF;
  }
  .detail-section > .mb-field > .field-list > .select-active > .circle {
    border: unset;
  }
  .detail-section > .mb-field > .field-list > .select-active > .circle > img {
    display: block;
  }
  .detail-section > .mb-field > .select-btn {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: black;
    color: white;
    font-weight: 700;
    font-size: 15px;
  }
  .detail-section > .mb-active {
    bottom: 0;
  }
  .detail-section > .top > .wrapper > .right > .img-wrapper {
    height: 60vw;
    min-height: 270px;
  }
  .detail-section > .top > .wrapper > .right > .price-wrapper {
    margin-bottom: 20px;
  }
  .detail-section > .top > .wrapper > .right > .field-wrapper {
    display: none;
  }
  .detail-section > .top > .wrapper > .right > .field-wrapper > .btn-wrapper {
    height: 50px !important;
  }
  .detail-section > .top > .wrapper > .right > .field-wrapper > .btn-wrapper > .field-list {
    display: none;
  }
  .detail-section > .top > .wrapper > .right > .insert {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 55px;
    z-index: 1000;
  }
  .detail-section > .nav-wrapper {
    margin-top: unset;
  }
  .detail-section > .nav-wrapper > .wrapper {
    padding: 0;
  }
  .detail-section > .bottom > div > div > .wrapper {
    width: 100% !important;
    padding: 0 20px !important;
  }
  .detail-section > .bottom > .info-section > .leader-section {
    padding: 30px 0;
  }
  .detail-section > .bottom > .info-section > .leader-section > .wrapper {
    padding: 56px 20px 50px 20px;
  }
}