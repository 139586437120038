.firstinfo-btn {
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  width: 100%;
  height: 50px;
  background: #DBDBDB;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 15px;
  cursor: pointer;
}
.firstinfo-btn > .title {
  font-weight: 700;
  font-size: 15px;
}
.firstinfo-btn > img {
  width: 27px;
}

.insert {
  background-color: black;
}