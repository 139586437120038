.notice {
  padding: 91px 0;
}
.notice > .wrapper {
  width: 1024px;
}
.notice > .wrapper > .title {
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.notice > .wrapper > .title > .titleLeft {
  font-size: 26px;
  font-weight: bold;
}
.notice > .wrapper > .title > .titleright {
  height: 40px;
  position: relative;
  width: 153px;
}
.notice > .wrapper > .title > .titleright > .type_box {
  position: absolute;
  -webkit-transition: height 0.2s ease-in;
  transition: height 0.2s ease-in;
  display: grid;
  cursor: pointer;
  border: 1px solid #dbdbdb;
  background-color: white;
  color: #696969;
  font-weight: 500;
  font-size: 15px;
  width: 153px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
}
.notice > .wrapper > .title > .titleright > .type_box > .now {
  border: unset;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: black;
}
.notice > .wrapper > .title > .titleright > .type_box > .now > .type {
  cursor: pointer;
  background-color: white;
}
.notice > .wrapper > .title > .titleright > .type_box > .now > img {
  margin-right: 15px;
  width: 21px;
  height: 21px;
}
.notice > .wrapper > .title > .titleright > .type_box > button {
  cursor: pointer;
  width: 100%;
  height: 40px;
  border-top: 1px solid #dbdbdb;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 12px;
  color: black;
}
.notice > .wrapper > .title > .titleright > .open {
  height: 120px;
}
.notice > .wrapper > .title > .titleright > .open > .now > img {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}
.notice > .wrapper > .notice-wrapper {
  margin-top: 25px;
}

@media screen and (max-width: 1365px) {
  .notice > .wrapper {
    width: 768px;
  }
}
@media screen and (max-width: 767px) {
  .notice > .wrapper {
    width: 100%;
    padding: unset;
  }
}