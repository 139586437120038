#root .my-teampl-page {
  padding-top: 66px;
}
#root .my-teampl-page > .wrapper {
  width: 728px;
}
#root .my-teampl-page > .wrapper > .top-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
#root .my-teampl-page > .wrapper > .top-section > .title {
  font-weight: 700;
  font-size: 25px;
}
#root .my-teampl-page > .wrapper > .top-section > .open-filter {
  height: 205px;
}
#root .my-teampl-page > .wrapper .my-teampl-list-section {
  margin-top: 30px;
  display: grid;
  row-gap: 20px;
}

@media screen and (max-width: 767px) {
  #root .my-teampl-page > .wrapper {
    width: 100%;
  }
  #root .my-teampl-page > .wrapper > .top-section {
    position: unset;
  }
}