#root .calendar .calendar-title-section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .calendar .calendar-title-section > .title {
  font-weight: 700;
  font-size: 20px;
}
#root .calendar .calendar-title-section .change-month-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .calendar .calendar-title-section .change-month-wrapper > button {
  cursor: pointer;
  height: 17px;
  background-color: unset;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .calendar .calendar-title-section .change-month-wrapper > button:hover {
  opacity: 0.7;
}
#root .calendar .calendar-title-section .change-month-wrapper > .now-btn {
  height: 25px;
  font-weight: 400;
  font-size: 10px;
}
#root .calendar .calendar-title-section .change-month-wrapper > .next-btn {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
#root .calendar .calendar-week-section {
  padding-bottom: 8px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(7, auto);
}
#root .calendar .calendar-week-section .week-text {
  font-weight: 400;
  font-size: 15px;
  color: #949494;
  text-align: center;
}
#root .calendar .calendar-week-section .week-text:nth-child(1) {
  color: #FF2B44;
}
#root .calendar .calendar-week-section .week-text:nth-child(7) {
  color: #0062FF;
}
#root .calendar .calendar-content-section {
  display: grid;
  grid-template-columns: repeat(7, auto);
  text-align: center;
  grid-template-rows: repeat(6, 100px);
}
#root .calendar .calendar-content-section .calendar-date-card {
  height: 100%;
  cursor: pointer;
  text-align: center;
  padding-top: 12px;
  border-top: 1px solid #E4E4E4;
  font-weight: 700;
  font-size: 14px;
  color: #696969;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 5px;
}
#root .calendar .calendar-content-section .calendar-date-card > span {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .calendar .calendar-content-section .calendar-date-card > .calendar-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #0062FF;
}
#root .calendar .calendar-content-section > .select-card > span {
  background: #D1DFFF;
}
#root .calendar .calendar-content-section .calendar-date-card:hover {
  opacity: 0.7;
}
#root .calendar .calendar-content-section .calendar-date-card:nth-child(7n) {
  color: #0062FF;
}
#root .calendar .calendar-content-section .calendar-date-card:nth-child(7n+1) {
  color: #FF2B44;
}
#root .calendar-view-section {
  position: fixed;
  bottom: 0;
  padding: 20px;
  width: 728px;
  background-color: white;
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
  border-top: solid 8px #F5F6F8;
}
#root .calendar-view-section .view-section-title {
  font-weight: 700;
  font-size: 18px;
}
#root .calendar-view-section .calendar-view-list {
  margin-top: 15px;
  display: grid;
  row-gap: 10px;
}
#root .calendar-view-section .calendar-view-list .view-lst-btn {
  cursor: pointer;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 16px;
}
#root .calendar-view-section .calendar-view-list .view-lst-btn > .title {
  font-weight: 700;
  font-size: 15px;
}
#root .calendar-view-section .calendar-view-list .view-lst-btn > .right-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
}
#root .calendar-view-section .calendar-view-list .view-lst-btn > .right-section > .title {
  font-weight: 400;
  font-size: 10px;
}
#root .calendar-view-section .calendar-view-list .view-lst-btn > .right-section > img {
  width: 9px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
#root .calendar-view-section .calendar-view-list > .emty {
  cursor: unset;
}
#root .calendar-view-section .calendar-view-list .view-lst-btn:hover {
  opacity: 0.7;
}
#root .calendar-detail-view {
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 57px;
  left: 0;
  background-color: white;
  z-index: 100;
  padding: 9px 0;
}
#root .calendar-detail-view > .wrapper {
  width: 728px;
  position: relative;
}
#root .calendar-detail-view > .wrapper > button {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  position: absolute;
  right: 0px;
  background-color: unset;
}
#root .calendar-detail-view > .wrapper > button:hover {
  opacity: 0.7;
}
#root .calendar-detail-view > .wrapper > .detail-view-time {
  font-weight: 400;
  font-size: 14px;
}
#root .calendar-detail-view > .wrapper > .title {
  font-weight: 700;
  font-size: 24px;
  margin: 4px 0 21px;
}
#root .calendar-detail-view > .wrapper > .leader-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root .calendar-detail-view > .wrapper > .leader-section > .img-wrapper {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #696969;
}
#root .calendar-detail-view > .wrapper > .leader-section > .leader-name {
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .calendar-detail-view > .wrapper > .leader-section > .leader-name > span {
  color: #0062FF;
}
#root .calendar-detail-view > .wrapper > .leader-section > .leader-name > button {
  cursor: pointer;
  background-color: unset;
  font-size: 14px;
  color: #696969;
}
#root .calendar-detail-view > .wrapper > .detail-view-content {
  margin-top: 20px;
  border-top: solid 1px #D9D9D9;
  padding: 19px 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  white-space: pre-line;
}

@media screen and (max-width: 767px) {
  #root .calendar .calendar-week-section .week-text {
    font-size: 10px;
  }
  #root .calendar .calendar-content-section {
    grid-template-rows: repeat(6, 71px);
  }
  #root .calendar .calendar-content-section .calendar-date-card {
    font-size: 10px;
    row-gap: 7px;
  }
  #root .calendar .calendar-content-section .calendar-date-card > span {
    width: 18px;
    height: 18px;
  }
  #root .calendar-view-section {
    left: 0;
    width: 100%;
  }
  #root .calendar-detail-view > .wrapper {
    width: 100%;
  }
  #root .calendar-detail-view > .wrapper > button {
    right: 20px;
  }
}