#root .mycoupon-page {
  padding: 80px 0;
}
#root .mycoupon-page > .wrapper > .title {
  font-weight: 700;
  font-size: 25px;
}
#root .mycoupon-page > .nav-wrapper {
  width: 100%;
  height: 46px;
  border-top: solid 1px #D9D9D9;
  border-bottom: solid 1px #D9D9D9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 17px 0 25px;
}
#root .mycoupon-page > .nav-wrapper > .btn-wrapper {
  height: 100%;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 63px);
  -webkit-column-gap: 61px;
     -moz-column-gap: 61px;
          column-gap: 61px;
}
#root .mycoupon-page > .nav-wrapper > .btn-wrapper > button {
  cursor: pointer;
  background-color: transparent;
  color: black;
  font-weight: 400;
  font-size: 14px;
}
#root .mycoupon-page > .nav-wrapper > .btn-wrapper > .active-nav {
  font-weight: 700;
  color: #0062FF;
}
#root .mycoupon-page > .nav-wrapper > .btn-wrapper > .nav-bar {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 63px;
  background-color: #0062FF;
  height: 3px;
}