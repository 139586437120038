.room-card {
  width: 100%;
  height: 97px;
  padding: 17px 17px 13px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  background-color: #F5F6F8;
}
.room-card > .top {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.room-card > .top > .title {
  font-weight: 700;
  font-size: 15px;
  color: black;
}
.room-card > .top > .time {
  font-weight: 500;
  font-size: 12px;
  color: #949494;
}
.room-card > .bottom {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.room-card > .bottom > .user-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
.room-card > .bottom > .user-wrapper > .img-wrapper {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.room-card > .bottom > .user-wrapper > .nickname {
  font-weight: 500;
  font-size: 12px;
  color: black;
}
.room-card > .bottom > .length-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 3px;
     -moz-column-gap: 3px;
          column-gap: 3px;
}
.room-card > .bottom > .length-wrapper > img {
  width: 21px;
}
.room-card > .bottom > .length-wrapper > .text {
  font-weight: 700;
  font-size: 15px;
  color: #0062FF;
}

.room-card:hover {
  opacity: 0.7;
}