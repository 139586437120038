#root .select-card-section {
  display: grid;
  row-gap: 10px;
}
#root .select-card-section > .box {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  background-color: #e9efff;
  width: 320px;
  height: 90px;
  border-radius: 10px;
  padding: 0 17px;
  position: relative;
  overflow: hidden;
  display: grid;
}
#root .select-card-section > .box > .card-wrapper {
  height: 100%;
}
#root .select-card-section > .box > .card-wrapper > .card-flex {
  height: 92px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .select-card-section > .box > .card-wrapper > .card-flex > .img {
  cursor: pointer;
  width: 22px;
  height: 22px;
}
#root .select-card-section > .box > .card-wrapper > .card-flex > .img-delete > svg > path {
  stroke: white;
}
#root .select-card-section > .box > .card-wrapper > .card-flex > .left > .card-name {
  font-weight: bold;
  font-size: 15px;
}
#root .select-card-section > .box > .card-wrapper > .card-flex > .left > .number {
  margin-top: 8px;
  color: #696969;
  font-weight: bold;
}
#root .select-card-section > .box > .card-wrapper > .delete-wrapper {
  height: 72px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 10px;
  border-top: solid 1px white;
}
#root .select-card-section > .box > .card-wrapper > .delete-wrapper > .text-wrapper > .text {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 14px;
}
#root .select-card-section > .box > .card-wrapper > .delete-wrapper > .text-wrapper > .sub {
  font-size: 12px;
}
#root .select-card-section > .box > .card-wrapper > .delete-wrapper > .delete-box {
  cursor: pointer;
  width: 109px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #0062ff;
  font-weight: bold;
  font-size: 13px;
  margin-left: 25px;
  margin-top: 18px;
}
#root .select-card-section > .open {
  color: white;
  background-color: #0062ff;
  height: 185px;
}
#root .select-card-section > .open > .card-wrapper > .card-flex > .left > .number {
  color: white;
}
#root .select-card-section > .open > .card-wrapper > .card-flex > .delete-open {
  width: 100%;
  padding-top: 10px;
  border-top: 1px solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .select-card-section > .open > .card-wrapper > .card-flex > .delete-open > .text-wrapper > .text {
  font-weight: bold;
  font-size: 12px;
}
#root .select-card-section > .open > .card-wrapper > .card-flex > .delete-open > .text-wrapper > .sub {
  font-size: 12px;
}
#root .select-card-section > .open > .card-wrapper > .card-flex > .delete-open > .delete-box {
  width: 109px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #0062ff;
  font-weight: bold;
  font-size: 13px;
}
#root .select-card-section > .card-init-btn {
  width: 100%;
  height: 59px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #F5F6F8;
  padding: 0 17px;
  border-radius: 10px;
  margin-top: 12px;
  cursor: pointer;
  color: black;
}
#root .select-card-section > .card-init-btn > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 9px;
     -moz-column-gap: 9px;
          column-gap: 9px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .select-card-section > .card-init-btn > .left > img {
  width: 38px;
}
#root .select-card-section > .card-init-btn > .left > .title {
  font-weight: 700;
  font-size: 15px;
}
#root .select-card-section > .card-init-btn > img {
  width: 22px;
}
#root .emty-card-section {
  width: 100%;
  height: 294px;
  text-align: center;
  background-color: #f5f6f8;
  border-radius: 10px;
}
#root .emty-card-section > .img {
  display: inline-block;
  width: 120px;
  height: 91px;
  margin-top: 27px;
  margin-bottom: 16px;
}
#root .emty-card-section > .text {
  font-size: 18px;
  font-weight: bold;
  color: #949494;
  margin-bottom: 9px;
}
#root .emty-card-section > .sub {
  font-size: 13px;
  color: #696969;
  line-height: 19px;
  margin-bottom: 17px;
}
#root .emty-card-section > .button {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #0062ff;
  width: 108px;
  height: 33px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  #root .select-card-section {
    width: 100%;
  }
  #root .select-card-section > .box {
    width: 100%;
  }
  #root .select-card-section > .open {
    width: 100%;
  }
  #root .select-card-section > .open > .card-wrapper > .card-flex {
    width: 100%;
  }
  #root .select-card-section > .open > .card-wrapper > .card-flex > .delete-open {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}