.more {
  padding: 54px 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.more > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 1365px;
}
.more > .wrapper > .title {
  width: 727px;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 23px;
}
.more > .wrapper > .list-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 320px);
  -webkit-column-gap: 38px;
     -moz-column-gap: 38px;
          column-gap: 38px;
}
.more > .wrapper > .list-wrapper > .more-card {
  cursor: pointer;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.more > .wrapper > .list-wrapper > .more-card > .img-wrapper {
  width: 100%;
  height: 269.4px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 14px;
  -webkit-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in;
}
.more > .wrapper > .list-wrapper > .more-card > .title {
  color: black;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}
.more > .wrapper > .list-wrapper > .more-card > .line {
  width: 100%;
  height: 1px;
  background-color: #DBDBDB;
  margin: 10px 0;
}
.more > .wrapper > .list-wrapper > .more-card > .period-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  font-weight: 500;
  font-size: 15px;
}
.more > .wrapper > .list-wrapper > .more-card > .period-wrapper > .title {
  color: #696969;
}
.more > .wrapper > .list-wrapper > .more-card > .period-wrapper > .period {
  color: #0062FF;
  font-weight: 700;
}
.more > .wrapper > .list-wrapper > .more-card:hover > .img-wrapper {
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  .more {
    min-height: 507px;
    padding: 30px 0;
  }
  .more > .wrapper > .title {
    width: 100%;
  }
  .more > .wrapper > .list-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-column-gap: 10px;
       -moz-column-gap: 10px;
            column-gap: 10px;
    grid-template-columns: repeat(3, 280px);
  }
  .more > .wrapper > .list-wrapper > .more-card > .img-wrapper {
    height: 186px;
  }
}