.login-btn {
  cursor: pointer;
  width: 100%;
  height: 50px;
  color: white;
  font-size: 15px;
}
.login-btn > .title {
  font-weight: bold;
}

.login-style {
  background-color: #0062FF;
}

.signup-style {
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 14px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.signup-style > .sub {
  font-weight: 400;
  font-size: 12px;
}

.nav-style {
  background-color: black;
}