.mypayment {
  padding: 66px 0;
}
.mypayment > .wrapper {
  width: 728px;
}
.mypayment > .wrapper > .title {
  font-size: 25px;
  font-weight: bold;
}
.mypayment > .wrapper > .sub {
  font-size: 12px;
  color: #545454;
  line-height: 22px;
  margin-top: 12px;
}
.mypayment > .wrapper > .box {
  -webkit-animation: inside 0.4s ease-in-out;
          animation: inside 0.4s ease-in-out;
  margin-top: 24px;
}
.mypayment > .wrapper > .box > .boxlist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 14px;
  padding: 14px 10px;
  width: 728px;
  height: 140px;
  background-color: #f5f6f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .left > .type-img {
  width: 112px;
  height: 112px;
  color: white;
  border-radius: 5px;
  padding-top: 5px;
  padding-left: 5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .left > .type-img > .type {
  background-color: #0062ff;
  width: 47px;
  height: 20px;
  border-radius: 5px;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: bold;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .left > .type-img > .refund {
  background-color: #bbff6c;
  color: black;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .left > .type-img > .reject {
  background-color: black;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right {
  position: relative;
  width: 100%;
  padding-top: 5px;
  margin-left: 14px;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right > .title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right > .pay {
  color: #0062ff;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 12px;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right > .toggle {
  cursor: pointer;
  width: 82px;
  height: 30px;
  background-color: #dbdbdb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #696969;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right > .toggle:hover {
  opacity: 0.7;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right > .detail-btn-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right > .detail-btn-wrapper > .state-text {
  font-weight: 700;
  font-size: 12px;
  color: #949494;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right > .detail-btn-wrapper > .view {
  background-color: unset;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right > .detail-btn-wrapper > .view > .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  color: #696969;
  white-space: nowrap;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right > .detail-btn-wrapper > .view > .img {
  margin-left: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 5px;
  height: 8px;
}
.mypayment > .wrapper > .box > .boxlist > .left-wrapper > .right > .detail-btn-wrapper > .view:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1365px) {
  .mypayment > .wrapper {
    width: 728px;
  }
}
@media screen and (max-width: 767px) {
  .mypayment > .wrapper {
    width: 100%;
    padding: 20px;
  }
  .mypayment > .wrapper > .box > .boxlist {
    width: 100%;
  }
}