#root .magazine-detail {
  padding-top: 58px;
}
#root .magazine-detail > .leader-section {
  position: relative;
}
#root .magazine-detail > .leader-section > .black-back {
  left: 0;
  top: 0;
  width: 100%;
  height: 293px;
  background-color: black;
  position: absolute;
  z-index: 1;
}
#root .magazine-detail > .leader-section > .wrapper {
  padding-top: 51px;
  width: 727px;
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 41px;
     -moz-column-gap: 41px;
          column-gap: 41px;
}
#root .magazine-detail > .leader-section > .wrapper > svg {
  cursor: pointer;
  position: absolute;
  top: 51px;
  right: 20px;
}
#root .magazine-detail > .leader-section > .wrapper > svg > path {
  fill: black;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .magazine-detail > .leader-section > .wrapper > .scrap-on > path {
  fill: #0047FF;
  fill-opacity: 1;
  stroke: unset;
}
#root .magazine-detail > .leader-section > .wrapper > .img-wrapper {
  width: 353px;
  height: 296px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#root .magazine-detail > .leader-section > .wrapper > .title-section {
  padding-top: 44px;
  width: 226px;
}
#root .magazine-detail > .leader-section > .wrapper > .title-section > .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: white;
}
#root .magazine-detail > .leader-section > .wrapper > .title-section > .position {
  margin: 13px 0 9px;
  font-weight: 700;
  font-size: 15px;
  color: #0062FF;
}
#root .magazine-detail > .leader-section > .wrapper > .title-section > .sub {
  font-weight: 500;
  font-size: 12px;
  color: #DBDBDB;
}
#root .magazine-detail > .content-wrapper {
  width: 727px;
  margin: 0 auto;
  padding: 66px 0;
}
#root .magazine-detail > .content-wrapper > .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .magazine-detail > .content-wrapper > .content > img {
  -ms-flex-item-align: center;
      align-self: center;
}
#root .magazine-detail > .content-wrapper .leader-project-btn {
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  margin-top: 37px;
  cursor: pointer;
  width: 100%;
  height: 72px;
  border-radius: 5px;
  background-color: #B8FF6C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 18px 0 2px;
  color: black;
}
#root .magazine-detail > .content-wrapper .leader-project-btn > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .magazine-detail > .content-wrapper .leader-project-btn > .left > img {
  width: 68px;
}
#root .magazine-detail > .content-wrapper .leader-project-btn > .left .btn-title-section {
  text-align: left;
}
#root .magazine-detail > .content-wrapper .leader-project-btn > .left .btn-title-section > .sub {
  font-weight: 400;
  font-size: 12px;
}
#root .magazine-detail > .content-wrapper .leader-project-btn > .left .btn-title-section > .title {
  font-weight: 700;
  font-size: 17px;
  margin-top: 5px;
}
#root .magazine-detail > .content-wrapper .leader-project-btn > .right {
  width: 14px;
}
#root .magazine-detail > .content-wrapper .leader-project-btn:hover {
  opacity: 0.8;
}
#root .magazine-detail > .grey-bar {
  width: 1365px;
  margin: 0 auto;
  height: 11px;
  background-color: #F5F6F8;
}

@media screen and (max-width: 1365px) {
  #root .magazine-detail > .grey-bar {
    width: 100%;
  }
  #root .magazine-detail > .more > .wrapper {
    width: 100%;
  }
  #root .magazine-detail > .more > .wrapper > .list-wrapper {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  #root .magazine-detail > .more > .wrapper > .list-wrapper::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  #root .magazine-detail > .leader-section > .black-back {
    height: 168px;
    bottom: 0;
    top: unset;
  }
  #root .magazine-detail > .leader-section > .wrapper {
    padding: 0;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
  #root .magazine-detail > .leader-section > .wrapper > svg {
    top: unset;
    right: 24px;
    bottom: 28px;
  }
  #root .magazine-detail > .leader-section > .wrapper > .img-wrapper {
    width: 100%;
    height: 84vw;
    max-height: 643px;
    min-height: 302px;
  }
  #root .magazine-detail > .leader-section > .wrapper > .title-section {
    padding: 32px 0 28px;
  }
  #root .magazine-detail > .content-wrapper {
    width: 100%;
    padding: 66px 20px;
  }
  #root .magazine-detail > .more > .wrapper {
    width: 100%;
    padding: 0 20px;
  }
  #root .magazine-detail > .more > .wrapper > .list-wrapper {
    display: grid;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
  }
  #root .magazine-detail > .more > .wrapper > .list-wrapper > .more-card {
    width: 100%;
  }
}