.inquiry {
  padding-top: 91px;
}
.inquiry > .wrapper {
  width: 728px;
}
.inquiry > .wrapper > .title {
  font-size: 26px;
  font-weight: bold;
}
.inquiry > .wrapper > .search {
  position: relative;
  margin-top: 24px;
}
.inquiry > .wrapper > .search > .input {
  width: 727px;
  height: 50px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 0px 13px;
}
.inquiry > .wrapper > .search > img {
  position: absolute;
  width: 23px;
  height: 23px;
  right: 20px;
  top: 13px;
}
.inquiry > .wrapper > .card {
  cursor: pointer;
  overflow: hidden;
  height: 59px;
  -webkit-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
  border-bottom: 1px solid #d9d9d9;
}
.inquiry > .wrapper > .card > .title-wrapper {
  height: 59px;
  border-bottom: 1px solid #d9d9d9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.inquiry > .wrapper > .card > .title-wrapper > .left {
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.inquiry > .wrapper > .card > .title-wrapper > .left > img {
  width: 21px;
  margin-right: 8px;
}
.inquiry > .wrapper > .card > .title-wrapper > .right > img {
  width: 21px;
  margin-left: 10px;
  cursor: pointer;
}
.inquiry > .wrapper > .card > .sub {
  display: block;
  font-size: 15px;
  padding: 15px 14px;
  line-height: 24px;
  background-color: #f5f6f8;
  width: 100%;
  height: 162px;
}
.inquiry > .wrapper > .open {
  height: 220px;
}
.inquiry > .wrapper > .open > .title-wrapper > .right {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}
.inquiry .inquiry-list {
  margin-top: 24px;
}
.inquiry > .wrapper-menu > .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 34px;
  border-bottom: solid 1px #d9d9d9;
  width: 100%;
}
.inquiry > .wrapper-menu > .menu > .menu-bar {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  position: relative;
}
.inquiry > .wrapper-menu > .menu > .menu-bar > li {
  width: 63px;
  cursor: pointer;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.inquiry > .wrapper-menu > .menu > .menu-bar .inquiry-nav-active {
  color: #0062ff;
  font-weight: 700;
}
.inquiry > .wrapper-menu > .menu > .menu-bar > .menu-active-bar {
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 63px;
  height: 3px;
  background-color: #0062ff;
}
.inquiry > .wrapper-menu > .section {
  margin-top: 97px;
  background-color: #f5f6f8;
  height: 561px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.inquiry > .wrapper-menu > .section > .icon {
  margin-top: 55px;
  width: 190px;
}
.inquiry > .wrapper-menu > .section > .title {
  font-size: 20px;
  font-weight: bold;
}
.inquiry > .wrapper-menu > .section > .box {
  padding: 10px 15px;
  margin-top: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: white;
  width: 196px;
  border-radius: 5px;
  line-height: 17px;
}
.inquiry > .wrapper-menu > .section > .box > .sub {
  font-size: 13px;
  color: #696969;
}
.inquiry > .wrapper-menu > .section > .box > .sub > span {
  font-weight: bold;
}
.inquiry > .wrapper-menu > .section > .kakao {
  cursor: pointer;
  margin-top: 29px;
  padding-left: 21px;
  padding-right: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: black;
  width: 320px;
  height: 50px;
}
.inquiry > .wrapper-menu > .section > .kakao .img {
  width: 29px;
}
.inquiry > .wrapper-menu > .section > .kakao > .title {
  font-size: 15px;
  font-weight: bold;
  color: white;
}
.inquiry > .wrapper-menu > .section > .mail {
  cursor: pointer;
  margin-top: 11px;
  padding-left: 21px;
  padding-right: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #0062ff;
  width: 320px;
  height: 50px;
}
.inquiry > .wrapper-menu > .section > .mail .img {
  width: 29px;
}
.inquiry > .wrapper-menu > .section > .mail > .title {
  font-size: 15px;
  font-weight: bold;
  color: white;
}
.inquiry > .wrapper-menu > .section > .mail > .title > span {
  font-size: 13px;
  font-weight: normal;
  margin-left: 8px;
}

@media screen and (max-width: 1365px) {
  .inquiry > .wrapper {
    width: 728px;
  }
}
@media screen and (max-width: 767px) {
  .inquiry > .wrapper {
    width: 100%;
    padding: unset;
  }
  .inquiry > .wrapper > .search {
    margin-left: 20px;
    margin-right: 20px;
  }
  .inquiry > .wrapper > .search > .input {
    width: 100%;
  }
  .inquiry > .wrapper > .title {
    margin-left: 20px;
  }
  .inquiry > .wrapper > .card > .title-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}