#root .member-card {
  cursor: pointer;
  width: 100%;
  height: 72px;
  border-radius: 5px;
  background-color: #F5F6F8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 16px;
  -webkit-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in;
}
#root .member-card .member-card-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
}
#root .member-card .member-card-left > .img-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#root .member-card .member-card-left > .nickname-wrapper > .nickname {
  width: 150px;
  font-weight: 700;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 6px;
     -moz-column-gap: 6px;
          column-gap: 6px;
}
#root .member-card .member-card-left > .nickname-wrapper > .nickname > span {
  font-weight: 400;
  font-size: 12px;
  color: #0062FF;
}
#root .member-card .member-card-left > .nickname-wrapper > .keywords {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  color: #696969;
  width: 169px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#root .member-card .member-card-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .member-card .member-card-right > .profile-text {
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  color: #696969;
}
#root .member-card .member-card-right > img {
  width: 15px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
#root .member-card:hover {
  opacity: 0.7;
}