#root .screen-section {
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .screen-section div,
#root .screen-section font,
#root .screen-section span {
  width: 100% !important;
  line-height: 1.5;
  word-break: break-all !important;
}
#root .screen-section p {
  width: 100% !important;
  word-break: break-all !important;
}
#root .screen-section .link-template,
#root .screen-section .file-template {
  cursor: pointer;
  background-color: white;
  margin: 20px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  border: solid 1px #3597ec;
  height: 53px;
  padding-left: 15.4px;
  padding-right: 21.3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
#root .screen-section .link-template > .file-asset,
#root .screen-section .file-template > .file-asset {
  width: 21px;
  margin-right: 15.5px;
}
#root .screen-section .link-template > .link-asset,
#root .screen-section .file-template > .link-asset {
  margin-right: 8.4px;
  width: 34.2px;
}
#root .screen-section .link-template > .link-title,
#root .screen-section .file-template > .link-title {
  width: 80% !important;
  font-size: 17px;
  font-weight: bold;
  color: #3597ec;
  text-align: left;
}
#root .screen-section .link-template > .link-play,
#root .screen-section .file-template > .link-play {
  position: absolute;
  right: 21.3px;
  width: 9.6px;
}
#root .screen-section .link-template:hover,
#root .screen-section .file-template:hover {
  opacity: 0.7;
}
#root .screen-section .template-youtube {
  position: relative;
  width: 100%;
}
#root .screen-section .template-youtube > iframe {
  height: 485px;
}

@media screen and (max-width: 767px) {
  #root .screen-section .template-youtube > iframe {
    height: 60vw;
  }
}