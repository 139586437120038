#root .email-input-wrapper > .tag-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 7px;
}
#root .email-input-wrapper > .tag-wrapper > .tag {
  font-size: 12px;
  font-weight: bold;
  color: #949494;
}
#root .email-input-wrapper > .tag-wrapper > .text {
  opacity: 0;
  font-weight: 400;
  font-size: 12px;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
#root .email-input-wrapper > .tag-wrapper > .finish {
  opacity: 1;
  color: #0062FF;
}
#root .email-input-wrapper > .tag-wrapper > .break, #root .email-input-wrapper > .tag-wrapper > .reg, #root .email-input-wrapper > .tag-wrapper > .gap {
  opacity: 1;
  color: #FF2B44;
}
#root .email-input-wrapper > .input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  height: 50px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 0 8px 0 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .email-input-wrapper > .input-wrapper > input {
  width: 200px;
}
#root .email-input-wrapper > .input-wrapper > button {
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  cursor: pointer;
  width: 60px;
  height: 34px;
  background-color: #0062FF;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
#root .email-input-wrapper > .input-wrapper > .send {
  color: #0062FF;
  background-color: #E9EFFF;
}
#root .email-input-wrapper > .focus {
  border-color: #0062FF;
}
#root .email-input-wrapper > .break {
  border-color: #FF2B44;
}