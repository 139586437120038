#root .about-section1 {
  width: 100%;
  height: 969px;
  background-color: white;
  padding-top: 132px;
}
#root .about-section1 > .wrapper {
  width: 1024px;
}
#root .about-section1 > .wrapper > .title {
  margin-bottom: 47px;
  font-size: 40px;
  font-weight: 700;
}
#root .about-section1 > .wrapper > .title > span {
  background-color: #b8ff6c;
}
#root .about-section1 > .wrapper > .img-box {
  position: relative;
  padding: 33px 88px 24px 84px;
  width: 1027px;
  height: 560px;
  background-color: #f4f4f4;
  border-radius: 17px;
}
#root .about-section1 > .wrapper > .img-box > .top {
  width: 549px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .about-section1 > .wrapper > .img-box > .top > .top-right > img {
  width: 228px;
}
#root .about-section1 > .wrapper > .img-box > .top > .top-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 15px;
}
#root .about-section1 > .wrapper > .img-box > .top > .top-left > .quote {
  width: 26px;
}
#root .about-section1 > .wrapper > .img-box > .top > .top-left > .text {
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
}
#root .about-section1 > .wrapper > .img-box > .top > .top-left > .text > span {
  background-color: #b8ff6c;
  font-weight: bold;
}
#root .about-section1 > .wrapper > .img-box > .bottom {
  margin-top: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 39px;
}
#root .about-section1 > .wrapper > .img-box > .bottom .graph-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 55px;
}
#root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 24px;
     -moz-column-gap: 24px;
          column-gap: 24px;
}
#root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top > .left {
  width: 157px;
}
#root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top > .right {
  position: relative;
  width: 239px;
  height: 121px;
  background-color: black;
  color: white;
  font-size: 20px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 27px;
  text-align: center;
  border-radius: 12px;
}
#root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top > .right > span {
  font-weight: bold;
  color: #b8ff6c;
}
#root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top > .right:after {
  content: "";
  position: absolute;
  right: 230px;
  top: 45px;
  border-right: 20px solid black;
  border-left: 10px solid transparent;
  border-bottom: 15px solid transparent;
  border-top: 15px solid transparent;
}
#root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-bottom {
  font-weight: 400;
  font-size: 15px;
  white-space: nowrap;
}
#root .about-section1 > .wrapper > .img-box > .bottom .graph-box:nth-child(2) .graph-bottom {
  margin-left: 40px;
}
#root .about-section1 > .wrapper > .img-box > .line {
  position: absolute;
  top: 255px;
  width: 863px;
  height: 2px;
  background-color: black;
}

@media screen and (max-width: 1365px) {
  #root .about-section1 {
    height: 773px;
    padding-top: 60px;
  }
  #root .about-section1 > .wrapper {
    width: 729px;
  }
  #root .about-section1 > .wrapper > .title {
    font-size: 30px;
    line-height: 38px;
  }
  #root .about-section1 > .wrapper > .img-box {
    padding: 18px 22px;
    width: 728px;
    height: 507px;
  }
  #root .about-section1 > .wrapper > .img-box > .top {
    width: 532px;
  }
  #root .about-section1 > .wrapper > .img-box > .top > .top-right > img {
    width: 228px;
  }
  #root .about-section1 > .wrapper > .img-box > .top > .top-left > .text {
    font-size: 25px;
    line-height: 30px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom {
    gap: 17px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box {
    row-gap: 45px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top {
    -webkit-column-gap: 18px;
       -moz-column-gap: 18px;
            column-gap: 18px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top > .left {
    width: 125px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top > .right {
    width: 195px;
    height: 91px;
    font-size: 16px;
    line-height: 21px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top > .right:after {
    right: 190px;
    top: 35px;
    border-right: 15px solid black;
    border-left: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-bottom {
    font-size: 13px;
  }
  #root .about-section1 > .wrapper > .img-box > .line {
    top: 245px;
    width: 683px;
  }
}
@media screen and (max-width: 767px) {
  #root .about-section1 {
    padding-top: 70px;
    height: 1155px;
  }
  #root .about-section1 > .wrapper {
    width: 100%;
  }
  #root .about-section1 > .wrapper > .title {
    text-align: center;
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 32px;
  }
  #root .about-section1 > .wrapper > .img-box {
    border-radius: 12px;
    padding: 40px 15px;
    width: 100%;
    height: 888px;
  }
  #root .about-section1 > .wrapper > .img-box > .top {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .about-section1 > .wrapper > .img-box > .top > .top-right > img {
    width: 138px;
  }
  #root .about-section1 > .wrapper > .img-box > .top > .top-left {
    row-gap: 5px;
  }
  #root .about-section1 > .wrapper > .img-box > .top > .top-left > .quote {
    width: 11px;
  }
  #root .about-section1 > .wrapper > .img-box > .top > .top-left > .text {
    font-size: 18px;
    line-height: 24px;
  }
  #root .about-section1 > .wrapper > .img-box > .top > .top-left > .text > span {
    font-weight: bold;
    background-color: #b8ff6c;
  }
  #root .about-section1 > .wrapper > .img-box > .line {
    margin-top: 15px;
    position: unset;
    width: 100%;
    height: 1px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom {
    margin-top: 29px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 27px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    row-gap: 18px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    row-gap: 22px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top > .left {
    width: 157px;
    height: 157px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top > .right {
    position: relative;
    width: 229px;
    height: 70px;
    font-size: 15px;
    line-height: 20px;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-top > .right:after {
    content: "";
    position: absolute;
    right: 96px;
    top: -25px;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 25px solid black;
    border-top: 15px solid transparent;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box .graph-bottom {
    line-height: 18px;
    text-align: center;
  }
  #root .about-section1 > .wrapper > .img-box > .bottom .graph-box:nth-child(2) .graph-bottom {
    margin-left: unset;
  }
}