#root .refund-info-section {
  margin-top: 36px;
}
#root .refund-info-section > .line {
  height: 9px;
  width: 100%;
  background-color: #F5F6F8;
  margin-bottom: 19px;
}
#root .refund-info-section > .refund-tag-wrapper {
  display: grid;
  row-gap: 10px;
}
#root .refund-info-section > .refund-tag-wrapper > .title {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 8px;
}
#root .refund-info-section > .refund-tag-wrapper > .tag-wrapper > .tag > span {
  color: #949494;
  font-size: 11px;
  margin-left: 8px;
}
#root .refund-info-section > .refund-tag-wrapper > .tag-wrapper > .charge {
  font-weight: 700;
  font-size: 15px;
  color: #0062FF;
}
#root .refund-info-section > .refund-tag-wrapper > .tag-wrapper > .refund-time {
  font-weight: 700;
  font-size: 15px;
}
#root .refund-info-section > .refund-price-box {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 15px;
  width: 100%;
  height: 54px;
  background-color: #E9EFFF;
  border-radius: 5px;
}
#root .refund-info-section > .refund-price-box > .title {
  font-weight: 700;
  font-size: 13px;
}
#root .refund-info-section > .refund-price-box > .content {
  font-weight: 700;
  font-size: 20px;
  color: #0062FF;
}