#root .drop-down {
  position: absolute;
  width: 153px;
  height: 40px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  background: white;
  overflow: hidden;
  -webkit-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}
#root .drop-down > .now {
  cursor: pointer;
  width: 100%;
  height: 40px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 9px 0 12px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}
#root .drop-down > .now > img {
  width: 21px;
}
#root .drop-down > .select-list {
  border-top: solid 1px #DBDBDB;
  display: grid;
  height: 273px;
}
#root .drop-down > .select-list > .select {
  cursor: pointer;
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 15px;
  color: #696969;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 12px;
  border-top: solid 1px #DBDBDB;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
#root .drop-down > .select-list > .active-select {
  color: #0062FF;
  font-weight: 700;
}
#root .drop-down > .select-list > .select:hover {
  background-color: #E9EFFF;
}
#root .drop-down > .select-list > .select:nth-child(1) {
  border-top: unset;
}
#root .drop-down > .back {
  display: none;
}
#root .drop-down > .mobile {
  display: none;
}
#root .down-active > .now > img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
#root .sort-down > .select-list {
  height: 120px;
}
#root .drop-down:nth-child(2) {
  left: 167px;
}
#root .drop-down:nth-child(3) {
  left: 337px;
}
#root .teampl-drop {
  right: 0;
  left: unset;
  top: -5px;
}

@media screen and (max-width: 767px) {
  #root .drop-down {
    position: unset;
  }
  #root .drop-down > .now {
    font-size: 10px;
    white-space: nowrap;
    padding: 0 5px;
  }
  #root .drop-down > .now > img {
    width: 15px;
  }
  #root .drop-down > .select-list {
    display: none;
  }
  #root .drop-down > .mobile {
    z-index: 600;
    display: block;
    overflow: hidden;
    height: 0;
    width: 100%;
    -webkit-transition: height 0.2s ease-in-out;
    transition: height 0.2s ease-in-out;
    -webkit-box-shadow: 0px -3px 15px rgba(0, 0, 0, 0.16);
            box-shadow: 0px -3px 15px rgba(0, 0, 0, 0.16);
    border-radius: 20px 20px 0px 0px;
    position: fixed;
    bottom: -50px;
    left: 0;
    background-color: white;
    padding: 18px 20px;
  }
  #root .drop-down > .mobile > .cancel {
    width: 28px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  #root .drop-down > .mobile > .title {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 700;
    font-size: 18px;
    -webkit-column-gap: 6px;
       -moz-column-gap: 6px;
            column-gap: 6px;
  }
  #root .drop-down > .mobile > .title > span {
    font-size: 13px;
    color: #0062FF;
  }
  #root .drop-down > .mobile > .category-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 16px;
  }
  #root .drop-down > .mobile > .category-list > .select {
    cursor: pointer;
    height: 39px;
    background-color: #F5F6F8;
    border-radius: 100px;
    color: #545454;
  }
  #root .drop-down > .mobile > .category-list > .active-select {
    background-color: #E9EFFF;
    color: #0062FF;
    font-weight: 700;
  }
  #root .drop-down > .mobile > .insert {
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    position: absolute;
    bottom: -20px;
    cursor: pointer;
    margin-top: 23px;
    width: 90%;
    height: 45px;
    background-color: #0062FF;
    color: white;
    font-weight: 700;
    font-size: 15px;
  }
  #root .drop-down > .back {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 550;
  }
  #root .drop-down:nth-child(2),
  #root .drop-down:nth-child(3) {
    left: unset;
  }
  #root .drop-down:nth-child(2) > .select-list,
  #root .drop-down:nth-child(3) > .select-list {
    height: 0;
  }
  #root .down-active {
    height: unset !important;
  }
  #root .down-active > .mobile {
    width: 100%;
    height: 475px;
    bottom: 0;
  }
  #root .down-active > .mobile > .insert {
    bottom: 20px;
  }
  #root .magazine-down > .mobile {
    height: 230px;
  }
  #root .magazine-down > .mobile > .category-list {
    grid-template-columns: unset;
  }
  #root .down-active:nth-child(2),
  #root .down-active:nth-child(3) {
    height: unset;
  }
  #root .down-active:nth-child(2) > .mobile,
  #root .down-active:nth-child(3) > .mobile {
    height: 250px;
  }
}