#root .policy-page > .wrapper {
  padding: 71px 0 47px;
}
#root .policy-page > .wrapper .policy-drop-down-wrapper {
  -webkit-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
  width: 100%;
  height: 50px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, 50px);
  overflow: hidden;
}
#root .policy-page > .wrapper .policy-drop-down-wrapper .policy-drop-down {
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: white;
  padding: 0 15px;
}
#root .policy-page > .wrapper .policy-drop-down-wrapper .policy-drop-down > img {
  width: 15px;
}
#root .policy-page > .wrapper .policy-drop-down-wrapper .policy-drop-down:hover {
  opacity: 0.7;
}
#root .policy-page > .wrapper .policy-drop {
  height: 150px;
}
#root .policy-page > .wrapper .policy-drop .policy-drop-down > img {
  -webkit-transform: scale(-1);
          transform: scale(-1);
}
#root .policy-page > .wrapper .policy-content-section {
  margin-top: 34px;
  height: 100%;
}
#root .policy-page > .wrapper .policy-content-section .policy-title {
  font-weight: 700;
  font-size: 20px;
}
#root .policy-page > .wrapper .policy-content-section .policy-timestamp {
  font-weight: 700;
  font-size: 15px;
  color: #0062FF;
  margin: 7px 0 27px;
}
#root .policy-page > .wrapper .policy-content-section .policy-main-content {
  color: #545454;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  white-space: pre-wrap;
}

@media screen and (max-width: 767px) {
  #root .policy-page > .wrapper {
    padding: 71px 20px 47px 20px;
  }
}