#root .fix-apply-page {
  padding: 74px 0 90px;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
#root .fix-apply-page > .wrapper {
  height: auto;
}
#root .fix-apply-page > .wrapper > .title-section {
  margin-bottom: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .fix-apply-page > .wrapper > .title-section > .title {
  font-weight: 700;
  font-size: 26px;
}
#root .fix-apply-page > .wrapper > .title-section > .fix-btn {
  cursor: pointer;
  width: 93px;
  height: 39px;
  background: #E9EFFF;
  border-radius: 50px;
  font-weight: 700;
  font-size: 15px;
  color: #0062FF;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .fix-apply-page > .wrapper > .title-section > .fixing-btn {
  height: 50px;
  width: 320px;
  border-radius: 0;
  position: absolute;
  bottom: 0;
  background-color: black;
  color: white;
}
#root .fix-apply-page > .wrapper > .title-section > .fix-btn:hover {
  opacity: 0.7;
}
#root .fix-apply-page > .wrapper > .project-card > .title {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 4px;
}
#root .fix-apply-page > .wrapper > .project-card > .project-box {
  width: 100%;
  background: #F5F6F8;
  border-radius: 5px;
  height: 86px;
  padding: 16px 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 13px;
     -moz-column-gap: 13px;
          column-gap: 13px;
}
#root .fix-apply-page > .wrapper > .project-card > .project-box > .img-wrapper {
  width: 54px;
  height: 54px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#root .fix-apply-page > .wrapper > .project-card > .project-box > .project-title {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}
#root .fix-apply-page > .wrapper > .info-wrapper {
  margin-top: 20px;
}
#root .fix-apply-page > .wrapper > .info-wrapper > .number {
  font-weight: 700;
  font-size: 17px;
  color: #0062FF;
  margin-bottom: 3px;
}
#root .fix-apply-page > .wrapper > .info-wrapper > .info-title {
  font-weight: 700;
  font-size: 17px;
}
#root .fix-apply-page > .wrapper > .info-wrapper > .info-sub {
  font-size: 13px;
  margin-top: 3px;
}
#root .fix-apply-page > .wrapper > .info-wrapper > textarea {
  margin-top: 11px;
  width: 100%;
  height: 161px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 15px;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
}
#root .fix-apply-page > .grey-line {
  width: 100%;
  height: 9px;
  background-color: #F5F6F8;
  margin-top: 33px;
}

@media screen and (max-width: 767px) {
  #root .fix-apply-page > .wrapper > .title-section > .fixing-btn {
    left: 0;
    position: fixed;
    width: 100%;
  }
}