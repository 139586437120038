.send-mail {
  width: 100%;
  height: 100%;
}
.send-mail > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.send-mail > .wrapper > .tag {
  font-size: 15px;
  font-weight: bold;
  color: #949494;
}
.send-mail > .wrapper > .title {
  font-size: 26px;
  width: 215px;
  font-weight: bold;
  margin: 9px 0 25px 0;
  line-height: 35px;
}
.send-mail > .wrapper > .btn-wrapper {
  margin-top: 55px;
  display: grid;
  row-gap: 11px;
  width: 100%;
}
.send-mail > .wrapper > .btn-wrapper > button {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  height: 50px;
}
.send-mail > .wrapper > .btn-wrapper > .question {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #696969;
}
.send-mail > .wrapper > .btn-wrapper > .question > .sub {
  font-weight: 400;
  font-size: 12px;
}
.send-mail > .wrapper > .btn-wrapper > .question > .title {
  font-size: 15px;
  font-weight: bold;
}
.send-mail > .wrapper > .btn-wrapper > .send {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  background-color: #DBDBDB;
  color: white;
}
.send-mail > .wrapper > .btn-wrapper > .active {
  background-color: #0062FF;
}