#root .refund-section {
  padding: 59px 0;
}
#root .refund-section > .wrapper {
  width: 100%;
  padding: unset;
}
#root .refund-section > .wrapper > .title {
  font-weight: 700;
  font-size: 24px;
}
#root .refund-section > .wrapper > .sub {
  font-weight: 400;
  font-size: 15px;
  margin: 12px 0;
}
#root .refund-section > .wrapper > .sub > span {
  color: #0062FF;
  font-weight: 700;
}
#root .refund-section > .wrapper > .refund-box {
  display: grid;
  grid-template-rows: repeat(5, 38px);
}
#root .refund-section > .wrapper > .refund-box > .box-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: solid 1px #DBDBDB;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  padding-left: 21px;
  -webkit-column-gap: 35px;
     -moz-column-gap: 35px;
          column-gap: 35px;
}
#root .refund-section > .wrapper > .refund-box > .box-line > div {
  white-space: nowrap;
}
#root .refund-section > .wrapper > .refund-box > .box-line > .title {
  width: 150px;
  white-space: nowrap;
}
#root .refund-section > .wrapper > .refund-box > .box-line:nth-child(1) {
  background: #F5F6F8;
  border-bottom: unset;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  #root .refund-section > .wrapper > .refund-box > .box-line {
    font-size: 10px;
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
  }
}