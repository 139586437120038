#root .my-coupon-card {
  padding: 0 16px 0 19px;
  width: 100%;
  height: 128px;
  background-color: #F5F6F8;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #949494;
}
#root .my-coupon-card .coupon-content {
  width: 190px;
}
#root .my-coupon-card .coupon-content > .type {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 17px;
  font-weight: 700;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #DBDBDB;
  padding: 0 10px;
}
#root .my-coupon-card .coupon-content > .title {
  margin: 6px 0 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
#root .my-coupon-card .coupon-content > .period {
  font-weight: 700;
  font-size: 12px;
}
#root .my-coupon-card .coupon-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 7px;
}
#root .my-coupon-card .coupon-check > .title {
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}
#root .my-coupon-card .coupon-line {
  position: absolute;
  right: 69px;
  top: -6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .my-coupon-card .coupon-line > .circle {
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: white;
}
#root .my-coupon-card .coupon-line > .line {
  height: 111px;
  width: 1px;
  border: 1px dashed #DBDBDB;
}
#root .use-type {
  padding: 0 26px 0 19px;
}
#root .coupon-type .coupon-content > .type {
  background-color: #0062FF;
  color: white;
}
#root .coupon-type .coupon-content > .period {
  color: #0062FF;
}
#root .invitation-type .coupon-content > .type {
  background-color: #5DC287;
  color: black;
}
#root .invitation-type .coupon-content > .period {
  color: #5DC287;
}