#root .about-section6 {
  height: 826px;
  background-color: black;
  padding-top: 114px;
}
#root .about-section6 > .wrapper {
  width: 1024px;
}
#root .about-section6 > .wrapper > .title {
  font-size: 35px;
  font-weight: bold;
  color: white;
}
#root .about-section6 > .wrapper > .list {
  margin-top: 41px;
  display: grid;
  grid-template-columns: repeat(3, 330px);
  gap: 17px;
}
#root .about-section6 > .wrapper > .list > .box {
  padding: 64px 38px 49px 37px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 330px;
  height: 449px;
  background-color: #73acff;
  border-radius: 14px;
}
#root .about-section6 > .wrapper > .list > .box > .img {
  width: 85.76px;
  height: 85.76px;
  border-radius: 50%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 20px;
}
#root .about-section6 > .wrapper > .list > .box > .img > img {
  width: 55px;
}
#root .about-section6 > .wrapper > .list > .box > .title {
  font-size: 25px;
  color: white;
  font-weight: bold;
  margin-bottom: 17px;
}
#root .about-section6 > .wrapper > .list > .box > .sub {
  color: white;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  height: 105px;
  text-align: center;
}
#root .about-section6 > .wrapper > .list > .box > .sub > div > .br-mb {
  display: none;
}
#root .about-section6 > .wrapper > .list > .box > .button-wrapper {
  cursor: pointer;
  margin-top: 50px;
  width: 214px;
  height: 50px;
  background-color: black;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: bold;
  padding: 0px 12px 0px 13px;
}
#root .about-section6 > .wrapper > .list > .box > .button-wrapper > img {
  width: 25px;
}
#root .about-section6 > .wrapper > .list > .blue {
  background-color: #0062ff;
}
#root .about-section6 > .wrapper > .list > .pupple {
  background-color: #8741ea;
}

@media screen and (max-width: 1365px) {
  #root .about-section6 {
    height: 707px;
    padding-top: 97px;
  }
  #root .about-section6 > .wrapper {
    width: 727px;
    margin: 0 auto;
  }
  #root .about-section6 > .wrapper > .title {
    font-size: 30px;
  }
  #root .about-section6 > .wrapper > .list {
    margin-top: 31px;
    grid-template-columns: repeat(3, 235px);
    gap: 11px;
  }
  #root .about-section6 > .wrapper > .list > .box {
    padding: 37px 10px 22px 12px;
    width: 235px;
    height: 368px;
  }
  #root .about-section6 > .wrapper > .list > .box > .img {
    width: 57.76px;
    height: 57.76px;
  }
  #root .about-section6 > .wrapper > .list > .box > .img > img {
    width: 33px;
  }
  #root .about-section6 > .wrapper > .list > .box > .sub {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    height: 135px;
  }
  #root .about-section6 > .wrapper > .list > .box > .sub > div > .br-pc {
    display: none;
  }
  #root .about-section6 > .wrapper > .list > .box > .sub > div > .br-mb {
    display: block;
  }
  #root .about-section6 > .wrapper > .list > .box > .sub > div > .mb-text {
    display: none;
  }
  #root .about-section6 > .wrapper > .list > .box > .title {
    font-size: 20px;
    margin-bottom: 12px;
  }
  #root .about-section6 > .wrapper > .list > .box > .button-wrapper {
    margin-top: 23px;
    width: 199px;
    height: 46px;
    padding: 0px 12px 0px 13px;
    font-size: 15px;
  }
  #root .about-section6 > .wrapper > .list > .box > .button-wrapper > img {
    width: 23px;
  }
}
@media screen and (max-width: 767px) {
  #root .about-section6 {
    height: 1521px;
    padding-top: 104px;
  }
  #root .about-section6 > .wrapper {
    width: 100%;
  }
  #root .about-section6 > .wrapper > .title {
    text-align: center;
    font-size: 25px;
  }
  #root .about-section6 > .wrapper > .list {
    width: 100%;
    display: block;
  }
  #root .about-section6 > .wrapper > .list > .box {
    padding: 35px 36px 25px 36px;
    width: 100%;
    height: 379px;
    margin-bottom: 28px;
  }
  #root .about-section6 > .wrapper > .list > .box > .img {
    width: 90px;
    height: 90px;
  }
  #root .about-section6 > .wrapper > .list > .box > .img > img {
    width: 52px;
  }
  #root .about-section6 > .wrapper > .list > .box > .sub {
    width: 278px;
    font-weight: 500;
    line-height: 22px;
    height: 110px;
  }
  #root .about-section6 > .wrapper > .list > .box > .sub > div > .br-mb {
    display: none;
  }
  #root .about-section6 > .wrapper > .list > .box > .sub > div > .br-pc {
    display: block;
  }
  #root .about-section6 > .wrapper > .list > .box > .button-wrapper {
    margin-top: 23px;
    width: 100%;
    height: 50px;
    padding: 0px 12px 0px 13px;
    font-size: 15px;
  }
  #root .about-section6 > .wrapper > .list > .box > .button-wrapper > img {
    width: 23px;
  }
}