#root .coupon-section .coupon-notice {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #545454;
}
#root .coupon-section > .coupon-list {
  display: grid;
  row-gap: 12px;
  margin-top: 25px;
}