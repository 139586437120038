.comment,
.reply-card {
  width: 100%;
  padding: 17px 17px 15px 17px;
  position: relative;
  background-color: #F5F6F8;
  border-radius: 5px;
}
.comment > .btn-wrapper,
.reply-card > .btn-wrapper {
  position: absolute;
  top: 17px;
  right: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 16px;
     -moz-column-gap: 16px;
          column-gap: 16px;
}
.comment > .btn-wrapper > button,
.reply-card > .btn-wrapper > button {
  cursor: pointer;
  background-color: transparent;
  font-weight: 700;
  font-size: 10px;
  color: #949494;
}
.comment > .profile-wrapper,
.reply-card > .profile-wrapper {
  width: 85%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
}
.comment > .profile-wrapper > .img-wrapper,
.reply-card > .profile-wrapper > .img-wrapper {
  background-color: #949494;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.comment > .profile-wrapper > .right,
.reply-card > .profile-wrapper > .right {
  width: 60%;
}
.comment > .profile-wrapper > .right > .nickname,
.reply-card > .profile-wrapper > .right > .nickname {
  font-weight: 700;
  font-size: 17px;
}
.comment > .profile-wrapper > .right > .grade,
.reply-card > .profile-wrapper > .right > .grade {
  margin-top: 6px;
  font-weight: 700;
  font-size: 12px;
  color: #0062FF;
}
.comment > .profile-wrapper:hover,
.reply-card > .profile-wrapper:hover {
  opacity: 0.7;
}
.comment > .content,
.reply-card > .content {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #545454;
  margin: 19px 0 25px;
  white-space: pre-wrap;
}
.comment .rcmn-usr-box,
.reply-card .rcmn-usr-box {
  width: 100%;
  background-color: white;
  height: 36px;
  margin-bottom: 21px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 12px;
}
.comment .rcmn-usr-box > .title,
.reply-card .rcmn-usr-box > .title {
  font-weight: 700;
  font-size: 12px;
  color: #949494;
}
.comment .rcmn-usr-box .rcmn-profile-wrapper,
.reply-card .rcmn-usr-box .rcmn-profile-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
}
.comment .rcmn-usr-box .rcmn-profile-wrapper > .profile-img,
.reply-card .rcmn-usr-box .rcmn-profile-wrapper > .profile-img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.comment .rcmn-usr-box .rcmn-profile-wrapper > .name,
.reply-card .rcmn-usr-box .rcmn-profile-wrapper > .name {
  font-weight: 700;
  font-size: 12px;
}
.comment > .fix-comment-area,
.reply-card > .fix-comment-area {
  width: 100%;
  height: 97px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  margin: 12px 0 9px;
  padding: 9px 12px;
}
.comment > .recommend-star-wrapper,
.reply-card > .recommend-star-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
.comment > .recommend-star-wrapper > .star-btn,
.reply-card > .recommend-star-wrapper > .star-btn {
  background-color: unset;
}
.comment > .recommend-star-wrapper > .star-btn > svg > path,
.reply-card > .recommend-star-wrapper > .star-btn > svg > path {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.comment > .recommend-star-wrapper > .active-star > svg > path,
.reply-card > .recommend-star-wrapper > .active-star > svg > path {
  fill: #7BB1FF;
  stroke: unset;
}
.comment > .file-wrapper,
.reply-card > .file-wrapper {
  display: grid;
  row-gap: 11px;
  margin-bottom: 23px;
}
.comment > .file-wrapper > a, .comment > .file-wrapper > button,
.reply-card > .file-wrapper > a,
.reply-card > .file-wrapper > button {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  width: 100%;
  height: 53px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 16px 0 10px;
  border-radius: 5px;
}
.comment > .file-wrapper > a > .left, .comment > .file-wrapper > button > .left,
.reply-card > .file-wrapper > a > .left,
.reply-card > .file-wrapper > button > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 14px;
     -moz-column-gap: 14px;
          column-gap: 14px;
}
.comment > .file-wrapper > a > .left > .img-wrapper, .comment > .file-wrapper > button > .left > .img-wrapper,
.reply-card > .file-wrapper > a > .left > .img-wrapper,
.reply-card > .file-wrapper > button > .left > .img-wrapper {
  width: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.comment > .file-wrapper > a > .left > .img-wrapper > img, .comment > .file-wrapper > button > .left > .img-wrapper > img,
.reply-card > .file-wrapper > a > .left > .img-wrapper > img,
.reply-card > .file-wrapper > button > .left > .img-wrapper > img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.comment > .file-wrapper > a > .left > .title, .comment > .file-wrapper > button > .left > .title,
.reply-card > .file-wrapper > a > .left > .title,
.reply-card > .file-wrapper > button > .left > .title {
  font-weight: 500;
  font-size: 15px;
}
.comment > .file-wrapper > a > img, .comment > .file-wrapper > button > img,
.reply-card > .file-wrapper > a > img,
.reply-card > .file-wrapper > button > img {
  width: 15px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.comment > .file-wrapper > a, .comment > .file-wrapper > button,
.reply-card > .file-wrapper > a,
.reply-card > .file-wrapper > button {
  opacity: 0.7;
}
.comment > .timestamp,
.reply-card > .timestamp {
  font-weight: 500;
  font-size: 10px;
  color: #949494;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.comment > .timestamp .comment-star-wrapper,
.reply-card > .timestamp .comment-star-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 2.8px;
     -moz-column-gap: 2.8px;
          column-gap: 2.8px;
}
.comment > .timestamp .comment-star-wrapper > img,
.reply-card > .timestamp .comment-star-wrapper > img {
  width: 16.5px;
}

.block-comment > .profile-wrapper > .img-wrapper {
  opacity: 0.6;
}
.block-comment > .profile-wrapper > .right > .nickname {
  color: #949494;
}
.block-comment > .profile-wrapper > .right > .grade {
  color: #949494;
}
.block-comment > .content {
  color: #949494;
}

.fix-comment {
  background-color: #E9EFFF;
}
.fix-comment .btn-wrapper > button {
  color: #0062FF;
}

.lment {
  background-color: #E9EFFF;
}

.reply-list {
  display: grid;
  row-gap: 11px;
}
.reply-list > .reply-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.reply-list > .reply-wrapper > img {
  width: 27px;
}