#root .magazine-category-list {
  padding: 80px 0 120px;
}
#root .magazine-category-list > .wrapper {
  width: 1024px;
}
#root .magazine-category-list > .wrapper > .title {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 28px;
}
#root .magazine-category-list > .nav-wrapper {
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
  -ms-flex-item-align: center;
      align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .magazine-category-list > .nav-wrapper > .nav-section {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(4, 90px);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root .magazine-category-list > .nav-wrapper > .nav-section > .nav-btn {
  -webkit-box-sizing: unset;
          box-sizing: unset;
  cursor: pointer;
  height: 25px;
  padding-bottom: 9px;
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
}
#root .magazine-category-list > .nav-wrapper > .nav-section > .active-type {
  font-weight: 700;
  color: #0062FF;
}
#root .magazine-category-list > .nav-wrapper > .nav-section > .nav-bar {
  width: 90px;
  height: 3px;
  background-color: #0062FF;
  position: absolute;
  bottom: -2px;
  left: 0;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .magazine-category-list .magazine-filter-wrapper {
  height: 40px;
  margin: 28px 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  position: relative;
}
#root .magazine-category-list .magazine-filter-wrapper > .filter-wrapper {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  background-color: white;
  position: absolute;
  width: 153px;
  height: 40px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  grid-template-rows: repeat(3, 40px);
  cursor: pointer;
  display: grid;
  z-index: 50;
  overflow: hidden;
}
#root .magazine-category-list .magazine-filter-wrapper > .filter-wrapper > button {
  padding: 0 9px 0 12px;
}
#root .magazine-category-list .magazine-filter-wrapper > .filter-wrapper > .now-filter {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: unset;
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}
#root .magazine-category-list .magazine-filter-wrapper > .filter-wrapper > .now-filter > img {
  width: 21px;
}
#root .magazine-category-list .magazine-filter-wrapper > .filter-wrapper > .filter-sub {
  cursor: pointer;
  background-color: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  font-size: 15px;
  color: #696969;
  border-top: solid 1px #DBDBDB;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
}
#root .magazine-category-list .magazine-filter-wrapper > .filter-open {
  height: 125px;
}
#root .magazine-category-list .magazine-filter-wrapper > .filter-open > .now-filter > img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
#root .magazine-category-list .magazine-card-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 320px);
  gap: 62px 32px;
}

@media screen and (max-width: 1365px) {
  #root .magazine-category-list > .wrapper {
    width: 728px;
  }
  #root .magazine-category-list > .wrapper > .nav-wrapper {
    width: 100vw;
  }
  #root .magazine-category-list > .wrapper .magazine-card-wrapper {
    grid-template-columns: repeat(2, 348px);
  }
}
@media screen and (max-width: 767px) {
  #root .magazine-category-list > .wrapper {
    width: 100%;
    padding: 0 20px;
  }
  #root .magazine-category-list > .wrapper .magazine-card-wrapper {
    grid-template-columns: 100%;
  }
}