#root .sign-up {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: unset;
          box-sizing: unset;
  overflow-y: scroll;
}
#root .sign-up > .wrapper {
  padding: 70px 0;
  min-height: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .sign-up > .wrapper > .title {
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 25px;
}
#root .sign-up > .wrapper > .password-wrapper {
  margin: 41px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 14px;
}
#root .sign-up > .wrapper > .policy-wrapper {
  margin-top: 85px;
}
#root .sign-up > .wrapper > .policy-wrapper > .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
}
#root .sign-up > .wrapper > .policy-wrapper > .title > .error {
  font-weight: 400;
  font-size: 12px;
  color: #FF2B44;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in;
}
#root .sign-up > .wrapper > .policy-wrapper > .title > .policy-error {
  opacity: 1;
}
#root .sign-up > .wrapper > .policy-wrapper > .all-policy {
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  margin: 8px 0 17px 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 10px;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
  background-color: #F5F6F8;
  border-radius: 5px;
  width: 100%;
  height: 43px;
}
#root .sign-up > .wrapper > .policy-wrapper > .all-policy > .circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: solid 1px #DBDBDB;
  background-color: white;
}
#root .sign-up > .wrapper > .policy-wrapper > .all-policy > .circle > img {
  display: none;
}
#root .sign-up > .wrapper > .policy-wrapper > .all-policy > .title {
  font-weight: bold;
  font-size: 15px;
  color: #545454;
}
#root .sign-up > .wrapper > .policy-wrapper > .policy-active {
  background-color: #E9EFFF;
}
#root .sign-up > .wrapper > .policy-wrapper > .policy-active > .circle {
  border: unset;
}
#root .sign-up > .wrapper > .policy-wrapper > .policy-active > .circle > img {
  display: block;
}
#root .sign-up > .wrapper > .policy-wrapper > .policy-active > .title {
  color: #0062FF;
}
#root .sign-up > .wrapper > .policy-wrapper > .policy-detail {
  margin-bottom: 55px;
  display: grid;
  row-gap: 21px;
}

@media screen and (max-width: 767px) {
  #root .sign-up > .wrapper {
    padding: 70px 20px;
  }
}