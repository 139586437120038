#root .my-payment-detail {
  padding: 66px 0;
}
#root .my-payment-detail .wrapper > .title {
  font-weight: 700;
  font-size: 25px;
}
#root .my-payment-detail .wrapper .sub-title {
  font-weight: 700;
  font-size: 13px;
}
#root .my-payment-detail .wrapper > .card-section {
  margin: 26px 0;
}
#root .my-payment-detail .wrapper > .card-section > .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  width: 100%;
  height: 140px;
  background: #F5F6F8;
  border-radius: 5px;
  -webkit-column-gap: 14px;
     -moz-column-gap: 14px;
          column-gap: 14px;
  padding: 10px 18px 18px 10px;
}
#root .my-payment-detail .wrapper > .card-section > .card > .img-wrapper {
  width: 112px;
  height: 112px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#root .my-payment-detail .wrapper > .card-section > .card > .card-content > .title {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .my-payment-detail .wrapper > .card-section > .card > .card-content > .line {
  width: 168px;
  height: 1px;
  background-color: #DBDBDB;
  margin: 11px 0 8px;
}
#root .my-payment-detail .wrapper > .card-section > .card > .card-content > .sub {
  font-weight: 500;
  font-size: 13px;
  color: #949494;
  margin-bottom: 4px;
}
#root .my-payment-detail .wrapper > .card-section > .card > .card-content > .price {
  font-weight: 700;
  font-size: 15px;
  color: #0062FF;
}
#root .my-payment-detail .wrapper > .payment-info-section > .title {
  font-weight: 700;
  font-size: 13px;
}
#root .my-payment-detail .wrapper > .payment-info-section > .price-wrapper {
  margin: 12px 0 22px;
  display: grid;
  row-gap: 12px;
}
#root .my-payment-detail .wrapper > .payment-info-section > .line {
  width: 100%;
  height: 1px;
  background-color: #DBDBDB;
  margin-bottom: 15px;
}
#root .my-payment-detail .wrapper > .payment-info-section > .day-box {
  padding: 13px 16px 12px 16px;
  width: 100%;
  height: 69px;
  background-color: #e9efff;
  border-radius: 5px;
  margin-top: 20px;
}
#root .my-payment-detail .wrapper > .payment-info-section > .day-box > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .my-payment-detail .wrapper > .payment-info-section > .day-box > .left > .day {
  font-size: 13px;
  color: #696969;
}
#root .my-payment-detail .wrapper > .payment-info-section > .day-box > .left > .text {
  color: #696969;
  font-size: 13px;
}
#root .my-payment-detail .wrapper > .payment-info-section > .day-box > .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .my-payment-detail .wrapper > .payment-info-section > .day-box > .right > .d-day {
  margin-top: 8px;
  color: #0062ff;
  font-weight: bold;
  font-size: 15px;
}
#root .my-payment-detail .wrapper > .payment-info-section > .day-box > .right > .d-day-fail {
  margin-top: 8px;
  color: #ff2b44;
  font-weight: bold;
  font-size: 15px;
}
#root .my-payment-detail .wrapper > .payment-info-section > .day-box > .right > .text2 {
  margin-top: 8px;
  color: #696969;
  font-size: 13px;
}
#root .my-payment-detail .wrapper > .payment-info-section > .card-title {
  font-weight: bold;
  font-size: 13px;
  margin-top: 47px;
  margin-bottom: 10px;
  padding-top: 22px;
  border-top: solid 1px #DBDBDB;
}
#root .my-payment-detail .wrapper > .payment-info-section > .card-box {
  background-color: #0062ff;
  width: 100%;
  height: 90px;
  border-radius: 10px;
  padding: 15px 0px 15px 17px;
}
#root .my-payment-detail .wrapper > .payment-info-section > .card-box > .type {
  color: white;
  font-weight: bold;
  font-size: 15px;
}
#root .my-payment-detail .wrapper > .payment-info-section > .card-box > .number {
  color: white;
  font-weight: bold;
  margin-top: 20px;
  font-size: 15px;
}
#root .my-payment-detail .wrapper > .payment-info-section .tag-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#root .my-payment-detail .wrapper > .payment-info-section .tag-wrapper > .tag {
  font-weight: 400;
  font-size: 13px;
  color: #696969;
}
#root .my-payment-detail .wrapper > .payment-info-section .tag-wrapper > .tag > span {
  color: #0062FF;
  margin-left: 7px;
}
#root .my-payment-detail .wrapper > .payment-info-section .tag-wrapper > .price {
  font-weight: 400;
  font-size: 20px;
  color: #696969;
}
#root .my-payment-detail .wrapper .payment-active-btn {
  cursor: pointer;
  margin-top: 37px;
  width: 100%;
  height: 50px;
  font-weight: 700;
  font-size: 15px;
  color: white;
  background-color: black;
}
#root .my-payment-detail .wrapper .payment-active-btn:hover {
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  #root .my-payment-detail .wrapper {
    padding: 0 20px 40px 20px;
  }
  #root .my-payment-detail .wrapper > .payment-active-btn {
    width: 90%;
    position: fixed;
    bottom: 20px;
  }
}