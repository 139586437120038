.main-header {
  -webkit-transition: background-color 0.2s ease-in;
  transition: background-color 0.2s ease-in;
  width: 100%;
  height: 58px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 500;
}
.main-header > .wrapper {
  padding: 0 20px;
  width: 1366px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.main-header > .wrapper > .logo {
  width: 112px;
  cursor: pointer;
}
.main-header > .wrapper > .pc-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
  font-size: 15px;
  -webkit-column-gap: 45px;
     -moz-column-gap: 45px;
          column-gap: 45px;
}
.main-header > .wrapper > .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 21px;
     -moz-column-gap: 21px;
          column-gap: 21px;
}
.main-header > .wrapper > .right > .login-nav {
  width: 80px;
  height: 28px;
  border-radius: 50px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #000000;
}
.main-header > .wrapper > .right > .my-info-nav {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: grey;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.main-header > .wrapper > .right > .menu {
  cursor: pointer;
  width: 28px;
}
.main-header > .wrapper > .right > .black > path {
  stroke: #898989;
}

.header-parent {
  background-color: transparent;
}

.header-black {
  display: none;
  background-color: black;
}
.header-black > .wrapper {
  color: white;
}
.header-black > .wrapper > .logo > g > path {
  fill: white;
}
.header-black > .wrapper > .logo > g > .blue {
  fill: #0062FF;
}
.header-black > .wrapper > .pc-menu > a {
  color: white;
}
.header-black > .wrapper > .right > .login-nav {
  border: solid 1px white;
}

@media screen and (max-width: 1365px) {
  .main-header > .wrapper {
    width: 100%;
  }
  .main-header > .wrapper > .pc-menu {
    display: none;
  }
  .main-header > .wrapper > .right {
    -webkit-column-gap: 12px;
       -moz-column-gap: 12px;
            column-gap: 12px;
  }
}
@media screen and (max-width: 767px) {
  .room-header > .wrapper {
    width: 100%;
  }
}