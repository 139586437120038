#root .magazineCard {
  -webkit-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in;
}
#root .magazineCard > .img-wrapper {
  width: 100%;
  height: 269.4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  cursor: pointer;
  display: block;
}
#root .magazineCard .magazinecard-content {
  margin-top: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
#root .magazineCard .magazinecard-content > .left {
  cursor: pointer;
  width: 90%;
}
#root .magazineCard .magazinecard-content > .left > .title {
  font-weight: 700;
  font-size: 15px;
  white-space: nowrap;
}
#root .magazineCard .magazinecard-content > .left > .sub {
  margin-top: 3px;
  font-weight: 500;
  font-size: 12px;
  color: #696969;
}
#root .magazineCard .magazinecard-content > svg {
  cursor: pointer;
}
#root .magazineCard .magazinecard-content > svg > path {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#root .magazineCard .magazinecard-content > .scrap-on > path {
  fill: #0047FF;
  fill-opacity: 1;
  stroke: unset;
}
#root .magazineCard:hover {
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  #root .magazineCard > .img-wrapper {
    min-height: 152px;
    height: 60vw;
  }
}