.menu-wrapper {
  -webkit-transition: right 0.3s ease-in;
  transition: right 0.3s ease-in;
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  padding-top: 58px;
  padding-bottom: 45px;
  z-index: 400;
}
.menu-wrapper > .top {
  height: 442px;
  background-color: black;
}
.menu-wrapper > .top > .wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding-top: 23px !important;
}
.menu-wrapper > .top > .wrapper > .nav-mypage {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 68px;
  height: 26px;
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  background-color: transparent;
  font-weight: 700;
  font-size: 12px;
  color: white;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.menu-wrapper > .top > .wrapper > .nav-mypage:active,
.menu-wrapper > .top > .wrapper .nav-mypage:hover {
  background-color: white;
  color: black;
}
.menu-wrapper > .top > .wrapper > .grade-name {
  font-weight: 700;
  font-size: 17px;
  color: #7BB1FF;
}
.menu-wrapper > .top > .wrapper > label {
  cursor: pointer;
  margin: 14px 0 8px 0;
  display: inline-block;
  width: 107px;
  height: 107px;
}
.menu-wrapper > .top > .wrapper > label > .main-img {
  width: 107px;
  height: 107px;
  border-radius: 107px;
  overflow: hidden;
}
.menu-wrapper > .top > .wrapper > .nickname {
  font-weight: 700;
  font-size: 25px;
  white-space: nowrap;
  color: white;
  height: 35px;
}
.menu-wrapper > .top > .wrapper > .email {
  font-weight: 500;
  font-size: 16px;
  color: #949494;
  height: 20px;
}
.menu-wrapper > .top > .wrapper > .grade-wrapper {
  margin-top: 17px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
.menu-wrapper > .top > .wrapper > .grade-wrapper > .box {
  width: 156px;
  height: 78px;
  background-color: #FFFFFF;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 11px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 11px;
}
.menu-wrapper > .top > .wrapper > .grade-wrapper > .box > .title {
  font-weight: 700;
  font-size: 13px;
}
.menu-wrapper > .top > .wrapper > .grade-wrapper > .box > .number {
  font-weight: 700;
  font-size: 20px;
  color: #0062FF;
}
.menu-wrapper > .top > .wrapper .my-teampl-btn {
  width: 100%;
  height: 58px;
  background-color: #0062FF;
  font-weight: 700;
  font-size: 20px;
  color: white;
  margin-top: 23px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.menu-wrapper > .top > .wrapper .my-teampl-btn:hover {
  background-color: white;
  color: #0062FF;
}
.menu-wrapper > .top > .wrapper > .emty-img {
  width: 107px;
}
.menu-wrapper > .top > .wrapper > .title {
  font-weight: 700;
  font-size: 20px;
  color: #949494;
  margin: 13px 0;
}
.menu-wrapper > .leader-top {
  padding-bottom: 26px;
  background-color: #0062FF;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.menu-wrapper > .leader-top .wrapper > .grade-name {
  color: white;
}
.menu-wrapper > .leader-top .wrapper > .email {
  color: white;
}
.menu-wrapper > .leader-top .wrapper > .my-teampl-btn {
  background-color: black;
}
.menu-wrapper > .not-login {
  height: 252px;
}
.menu-wrapper > .not-login > .wrapper {
  padding-top: 25px;
}
.menu-wrapper > .bottom > .wrapper {
  padding-top: 40px !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.menu-wrapper > .bottom > .wrapper > .logout-wrapper {
  margin-top: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #696969;
  font-weight: 500;
  font-size: 15px;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
}
.menu-wrapper > .bottom > .wrapper > .logout-wrapper > button {
  cursor: pointer;
  background-color: unset;
  font-size: 15px;
  -webkit-column-gap: 10px;
     -moz-column-gap: 10px;
          column-gap: 10px;
  color: #696969;
}
.menu-wrapper > .bottom > .wrapper > .block {
  margin-top: 50px;
  margin-bottom: 18px;
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
  width: 360px;
  height: 10px;
  background-color: #F5F6F8;
}
.menu-wrapper > .bottom > .wrapper > .first {
  margin-top: 37px;
}
.menu-wrapper > .bottom > .wrapper > .menu-box {
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  width: 100%;
  height: 30px;
  overflow: hidden;
}
.menu-wrapper > .bottom > .wrapper > .menu-box > .title-wrapper {
  cursor: pointer;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.menu-wrapper > .bottom > .wrapper > .menu-box > .title-wrapper > img {
  width: 15px;
}
.menu-wrapper > .bottom > .wrapper > .menu-box > .title-wrapper > .title {
  font-weight: 700;
  font-size: 18px;
  color: #0062FF;
}
.menu-wrapper > .bottom > .wrapper > .menu-box > .menu-arr {
  margin-top: 11px;
  background-color: #DBDBDB;
  row-gap: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}
.menu-wrapper > .bottom > .wrapper > .menu-box > .menu-arr > .link-box {
  width: 100%;
  height: 51px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 19px;
  background-color: #F5F6F8;
}
.menu-wrapper > .bottom > .wrapper > .menu-box > .menu-arr > .link-box > .title {
  font-weight: 500;
  font-size: 15px;
}
.menu-wrapper > .bottom > .wrapper > .menu-box > .menu-arr > .link-box > img {
  width: 21px;
}
.menu-wrapper > .bottom > .wrapper > .open-box > .title-wrapper > img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.menu-wrapper > .non-bottom {
  padding: 32px 0 74px;
}
.menu-wrapper > .non-bottom > .wrapper {
  row-gap: 45px;
}
.menu-wrapper > div > .on {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.menu-wrapper > div > .off {
  display: none;
}

.menu-open {
  right: 0;
  display: block;
}

@media screen and (max-width: 767px) {
  .menu-wrapper {
    -webkit-transition: right 0.2s ease-in-out;
    transition: right 0.2s ease-in-out;
  }
  .menu-wrapper > .bottom > .wrapper > .block {
    width: 100vw;
  }
}