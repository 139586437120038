.login-input {
  width: 100%;
}
.login-input > .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-weight: 700;
  font-size: 12px;
  color: #949494;
  margin-bottom: 7px;
}
.login-input > .title .sub-title {
  font-size: 11px;
}
.login-input > .title > .error {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  font-weight: 400;
  font-size: 12px;
  color: #FF2B44;
  opacity: 0;
}
.login-input > input {
  width: 100%;
  height: 50px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
}
.login-input > input:focus {
  border: 1px solid #0062FF;
}

.input-error > .title > .error {
  opacity: 1;
}
.input-error > input {
  border-color: #FF2B44;
}