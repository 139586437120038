.payment-section {
  padding: 100px 0;
}
.payment-section > .wrapper {
  width: 729px;
}
.payment-section > .wrapper > div {
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
}
.payment-section > .wrapper > .payment-intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.payment-section > .wrapper > .payment-intro > img {
  width: 72px;
}
.payment-section > .wrapper > .payment-intro > .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  margin: 14px 0 30px;
  text-align: center;
}
.payment-section > .wrapper > .payment-intro > .project-card {
  width: 100%;
}
.payment-section > .wrapper > .payment-intro > .project-card > .title {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 4px;
}
.payment-section > .wrapper > .payment-intro > .project-card > .card {
  width: 100%;
  height: 86px;
  background-color: #F5F6F8;
  border-radius: 5px;
  padding: 15px 17px 13px 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 13px;
     -moz-column-gap: 13px;
          column-gap: 13px;
}
.payment-section > .wrapper > .payment-intro > .project-card > .card > .img-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 54px;
  height: 54px;
  border-radius: 5px;
}
.payment-section > .wrapper > .payment-intro > .project-card > .card > .title {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}
.payment-section > .wrapper > .payment-intro > .area-wrapper {
  margin-top: 30px;
  width: 100%;
  display: grid;
  row-gap: 62px;
}
.payment-section > .wrapper > .payment-intro > .area-wrapper > .payment-area > .number {
  font-weight: 700;
  font-size: 17px;
  color: #0062FF;
}
.payment-section > .wrapper > .payment-intro > .area-wrapper > .payment-area > .title {
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  white-space: pre-wrap;
  margin-top: 3px;
}
.payment-section > .wrapper > .payment-intro > .area-wrapper > .payment-area > .sub {
  font-size: 13px;
  margin-top: 3px;
  margin-bottom: 11px;
}
.payment-section > .wrapper > .payment-intro > .area-wrapper > .payment-area > textarea {
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  width: 100%;
  height: 161px;
  padding: 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
}
.payment-section > .wrapper > .payment-intro > .next-btn {
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  margin-top: 31px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.payment-section > .wrapper > .payment-intro > .next-btn:hover {
  opacity: 0.7;
}
.payment-section > .wrapper > .payment-receipt > .all-policy {
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  margin: 8px 0 17px 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 10px;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
  background-color: #F5F6F8;
  border-radius: 5px;
  width: 100%;
  height: 43px;
}
.payment-section > .wrapper > .payment-receipt > .all-policy > .circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: solid 1px #DBDBDB;
  background-color: white;
}
.payment-section > .wrapper > .payment-receipt > .all-policy > .circle > img {
  display: none;
}
.payment-section > .wrapper > .payment-receipt > .all-policy > .title {
  font-weight: bold;
  font-size: 15px;
  color: #545454;
}
.payment-section > .wrapper > .payment-receipt > .policy-active {
  background-color: #E9EFFF;
}
.payment-section > .wrapper > .payment-receipt > .policy-active > .circle {
  border: unset;
}
.payment-section > .wrapper > .payment-receipt > .policy-active > .circle > img {
  display: block;
}
.payment-section > .wrapper > .payment-receipt > .policy-active > .title {
  color: #0062FF;
}
.payment-section > .wrapper > .payment-receipt > .refund-section {
  padding: 10px 0 59px !important;
}
.payment-section > .wrapper > .payment-receipt .payment-cs-btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
}
.payment-section > .wrapper > .payment-receipt .payment-cs-btn-wrapper > .circle {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid #DBDBDB;
  border-radius: 50%;
  background-color: white;
}
.payment-section > .wrapper > .payment-receipt .payment-cs-btn-wrapper > .circle > img {
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
  opacity: 0;
}
.payment-section > .wrapper > .payment-receipt .payment-cs-btn-wrapper > .agree-cs {
  border: unset;
}
.payment-section > .wrapper > .payment-receipt .payment-cs-btn-wrapper > .agree-cs > img {
  opacity: 1;
}
.payment-section > .wrapper > .payment-receipt .payment-cs-btn-wrapper > .title {
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #545454;
}
.payment-section > .wrapper > .payment-receipt .payment-cs-btn-wrapper > .title > span {
  color: #0062FF;
}
.payment-section > .wrapper > .payment-receipt .payment-cs-btn-wrapper > .title > br {
  display: none;
}
.payment-section > .wrapper > .payment-receipt > .title {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 25px;
}
.payment-section > .wrapper > .payment-receipt .tag {
  font-weight: 700;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.payment-section > .wrapper > .payment-receipt .tag > span {
  color: #0062FF;
}
.payment-section > .wrapper > .payment-receipt .payment-input-section {
  padding: 16px 0 21px;
  border-top: solid 1px #DBDBDB;
  border-bottom: solid 1px #DBDBDB;
}
.payment-section > .wrapper > .payment-receipt .payment-input-section .payment-input-wrapper {
  margin: 17px 0 24px;
  display: grid;
  row-gap: 16px;
}
.payment-section > .wrapper > .payment-receipt > .project-content {
  margin-bottom: 32px;
}
.payment-section > .wrapper > .payment-receipt > .project-content > .content-card {
  margin-top: 10px;
  width: 100%;
  height: 140px;
  background-color: #F5F6F8;
  border-radius: 5px;
  padding: 10px 19px 18px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 14px;
     -moz-column-gap: 14px;
          column-gap: 14px;
}
.payment-section > .wrapper > .payment-receipt > .project-content > .content-card > .img-wrapper {
  width: 112px;
  height: 112px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.payment-section > .wrapper > .payment-receipt > .project-content > .content-card > .right {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.payment-section > .wrapper > .payment-receipt > .project-content > .content-card > .right > .title {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}
.payment-section > .wrapper > .payment-receipt > .project-content > .content-card > .right > .line {
  width: 100%;
  height: 1px;
  background-color: #DBDBDB;
  margin: 11px 0 8px;
}
.payment-section > .wrapper > .payment-receipt > .project-content > .content-card > .right > .grade {
  font-weight: 700;
  font-size: 13px;
  color: #0062FF;
  margin-bottom: 4px;
}
.payment-section > .wrapper > .payment-receipt > .project-content > .content-card > .right > .price {
  font-weight: 700;
  font-size: 15px;
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper {
  width: 100%;
  padding: 16px 0 27px;
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper > .title-wrapper > .sale-price {
  font-weight: 400;
  font-size: 15px;
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper > .coupon-btn {
  margin-top: 21px;
  width: 100%;
  height: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 17px;
  cursor: pointer;
  border-radius: 5px;
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper > .coupon-btn > .title {
  font-weight: 500;
  font-size: 14px;
  color: #696969;
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper > .coupon-btn > img {
  width: 15px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper > .coupon-insert {
  background-color: #E9EFFF;
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper > .coupon-insert > .title {
  color: #0062FF;
  font-weight: 700;
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper > .coupon-emty {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper > .coupon-emty > .title {
  color: #949494;
}
.payment-section > .wrapper > .payment-receipt > .sale-wrapper > .coupon-emty > img {
  display: none;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper {
  padding: 16px 0 45px;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper .info-tag {
  font-weight: 400;
  font-size: 13px;
  color: #696969;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper .info-content-wrapper {
  margin-top: 12px;
  display: grid;
  row-gap: 12px;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper .info-content-wrapper .info-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper .info-content-wrapper .info-line .info-content {
  font-weight: 400;
  font-size: 20px;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper .payment-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper .payment-price > .price {
  font-weight: 700;
  font-size: 20px;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper .payment-notice {
  font-size: 11px;
  color: #0062FF;
  margin: 7px 0 16px;
  font-weight: 700;
  text-align: right;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper .payment-period-card {
  padding: 11px 16px;
  display: grid;
  row-gap: 6px;
  height: 69px;
  width: 100%;
  background-color: #E9EFFF;
  border-radius: 5px;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper .payment-period-card .period-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.payment-section > .wrapper > .payment-receipt > .info-wrapper .payment-period-card .period-line > span {
  font-weight: 700;
  font-size: 15px;
  color: #0062FF;
}
.payment-section > .wrapper > .payment-receipt .payment-card-wrapper {
  padding: 22px 0 53px;
}
.payment-section > .wrapper > .payment-receipt .payment-card-wrapper > .sub {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #696969;
  margin: 12px 0 15px;
}
.payment-section > .wrapper > .payment-receipt .payment-card-wrapper .payment-credit-list {
  display: grid;
  row-gap: 10px;
}
.payment-section > .wrapper > .payment-receipt .payment-card-wrapper .payment-credit-list .payment-insert-card {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  width: 100%;
  height: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 17px;
  border-radius: 5px;
  background-color: #F5F6F8;
}
.payment-section > .wrapper > .payment-receipt .payment-card-wrapper .payment-credit-list .payment-insert-card > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
}
.payment-section > .wrapper > .payment-receipt .payment-card-wrapper .payment-credit-list .payment-insert-card > .left > img {
  width: 22px;
}
.payment-section > .wrapper > .payment-receipt .payment-card-wrapper .payment-credit-list .payment-insert-card > .left > .title {
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}
.payment-section > .wrapper > .payment-receipt .payment-card-wrapper .payment-credit-list .payment-insert-card > .grey-arrow {
  width: 15px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.payment-section > .wrapper > .payment-receipt .payment-card-wrapper .payment-credit-list .payment-insert-card:hover {
  opacity: 0.8;
}
.payment-section > .wrapper > .payment-receipt .payment-finish-btn {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  background-color: #7d7d7d;
  color: white;
  font-weight: 700;
  font-size: 15px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: not-allowed;
}
.payment-section > .wrapper > .payment-receipt > .payment-on {
  cursor: pointer;
  background-color: black;
}
.payment-section > .wrapper .payment-coupon .payment-coupon-card {
  width: 320px;
  margin: 0 auto;
}
.payment-section > .wrapper .payment-coupon .payment-coupon-card > .title {
  font-weight: 700;
  font-size: 25px;
}
.payment-section > .wrapper .payment-coupon .payment-coupon-card > .title > span {
  color: #0062FF;
}
.payment-section > .wrapper .payment-coupon .payment-coupon-card > .sub {
  width: 280px;
  margin: 12px 0 21px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #545454;
}
.payment-section > .wrapper .payment-coupon .payment-coupon-card .payment-coupon-list {
  display: grid;
  row-gap: 12px;
}
.payment-section > .wrapper .payment-coupon .payment-coupon-card .payment-apply-coupon {
  -webkit-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in;
  margin-top: 50px;
  cursor: pointer;
  width: 100%;
  height: 50px;
  color: white;
  background-color: black;
  font-weight: 700;
  font-size: 15px;
}
.payment-section > .wrapper .payment-coupon .payment-coupon-card .payment-apply-coupon:hover {
  opacity: 0.7;
}
.payment-section > .wrapper .payment-finish {
  width: 320px;
  min-height: 90vh;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.payment-section > .wrapper .payment-finish > img {
  width: 190px;
}
.payment-section > .wrapper .payment-finish > .title {
  font-weight: 700;
  font-size: 26px;
  margin: 24.74px 0 6px;
}
.payment-section > .wrapper .payment-finish > .sub {
  font-weight: 400;
  font-size: 13px;
  color: #545454;
}
.payment-section > .wrapper .payment-finish .finish-info-card {
  border-radius: 5px;
  margin: 19px 0 47px;
  width: 100%;
  background-color: #F5F6F8;
  padding: 11px 16px 14px 16px;
}
.payment-section > .wrapper .payment-finish .finish-info-card > .period-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.payment-section > .wrapper .payment-finish .finish-info-card > .period-section > .title {
  font-weight: 700;
  font-size: 13px;
  color: #696969;
}
.payment-section > .wrapper .payment-finish .finish-info-card > .period-section > .period {
  font-weight: 700;
  font-size: 16px;
  color: #0062FF;
}
.payment-section > .wrapper .payment-finish .finish-info-card > .line {
  width: 100%;
  height: 1px;
  border: 1px dashed #DBDBDB;
  margin: 12px 0 14px;
}
.payment-section > .wrapper .payment-finish .finish-info-card > .sub {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #545454;
}
.payment-section > .wrapper .payment-finish .finish-btn-section {
  display: grid;
  width: 100%;
  row-gap: 5px;
}
.payment-section > .wrapper .payment-finish .finish-btn-section > button {
  width: 100%;
  height: 50px;
  cursor: pointer;
}
.payment-section > .wrapper .payment-finish .finish-btn-section .my-payment-btn {
  font-weight: 700;
  font-size: 15px;
  color: white;
  background-color: black;
}
.payment-section > .wrapper .payment-finish .finish-btn-section > .main-btn {
  background-color: transparent;
  font-weight: 500;
  font-size: 15px;
  color: black;
}

@media screen and (max-width: 767px) {
  .payment-section > .wrapper {
    width: 100%;
    padding: 0 20px;
  }
  .payment-section > .wrapper .payment-receipt .tag {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 5px;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
  }
  .payment-section > .wrapper .payment-receipt .payment-cs-btn-wrapper > .title > br {
    display: block;
  }
  .payment-section > .wrapper .payment-coupon .payment-coupon-card {
    width: 100%;
  }
  .payment-section > .wrapper .payment-coupon .payment-coupon-card > .sub {
    width: 100%;
  }
}