.keyword-insert > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 13px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.keyword-insert > .title-wrapper > .title {
  font-weight: 700;
}
.keyword-insert > .title-wrapper > .input-notice {
  font-weight: 400;
  color: #0062FF;
}
.keyword-insert > .input-wrapper {
  margin-top: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 50px;
}
.keyword-insert > .input-wrapper > input {
  width: 254px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  height: 100%;
  padding: 16px;
}
.keyword-insert > .input-wrapper > button {
  cursor: pointer;
  -webkit-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
  background: #E9EFFF;
  border-radius: 5px;
  font-size: 14px;
  color: #0062FF;
  width: 50px;
  height: 100%;
  font-weight: 700;
}
.keyword-insert > .input-wrapper > button:active {
  background-color: #0062FF;
  color: white;
}
.keyword-insert > .line {
  margin: 26px 0 22px;
  width: 100%;
  height: 1px;
  background-color: #DBDBDB;
}
.keyword-insert > .keyword-wrapper {
  margin-bottom: 66px;
  height: 275px;
  display: grid;
  row-gap: 10px;
  grid-template-rows: repeat(5, 47px);
  overflow: hidden;
}
.keyword-insert > .keyword-wrapper > .box {
  width: 100%;
  height: 47px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 15px 17px;
  border-radius: 23.5px;
  -webkit-animation: inside 0.2s ease-in;
          animation: inside 0.2s ease-in;
}
.keyword-insert > .keyword-wrapper > .box > img {
  cursor: pointer;
  width: 15px;
}
.keyword-insert > .keyword-wrapper > .box > .title {
  font-weight: 700;
  font-size: 14px;
  color: white;
}
.keyword-insert > .keyword-wrapper > .box:nth-child(1) {
  background-color: #0062FF;
}
.keyword-insert > .keyword-wrapper > .box:nth-child(2) {
  background-color: #7BB1FF;
}
.keyword-insert > .keyword-wrapper > .box:nth-child(3) {
  background-color: #8741EA;
}
.keyword-insert > .keyword-wrapper > .box:nth-child(4) {
  background-color: #5DC287;
}
.keyword-insert > .keyword-wrapper > .box:nth-child(5) {
  background-color: #BBB0E4;
}