#root .my-page > .profile-comp > .info-wrapper .fix-portfolio {
  overflow-y: scroll;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: -100%;
  background-color: white;
  padding-bottom: 40px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top {
  min-height: 318px;
  padding: 84px 0 10px 0;
  background-color: #F5F6F8;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .title {
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 26px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .step2-drop-down {
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  margin-top: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  height: 15px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .step2-drop-down > .open-btn {
  cursor: pointer;
  background-color: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  font-size: 13px;
  color: #949494;
  -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .step2-drop-down > .open-btn > img {
  width: 15px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .step2-drop-down > .close-btn {
  background-color: unset;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  font-size: 13px;
  color: #949494;
  -webkit-column-gap: 7px;
     -moz-column-gap: 7px;
          column-gap: 7px;
  margin-top: 12px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .step2-drop-down > .close-btn > img {
  width: 15px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .step2-drop-down > .box {
  width: 320px;
  height: 130px;
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 14px;
  text-align: left;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .step2-drop-down > .box > .title {
  font-weight: 700;
  font-size: 13px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .step2-drop-down > .box > .title > span {
  color: #0062FF;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .step2-drop-down > .box > .content {
  margin-top: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .open {
  height: 175px;
  margin-top: 14px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .top > .wrapper > .open > .open-btn {
  display: none;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .bottom {
  padding-top: 30px;
  width: 320px;
  margin: 0 auto;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .bottom > .title {
  font-weight: 700;
  font-size: 15px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .bottom > .sub {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #696969;
  margin: 6px 0 12px 0;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .bottom > input {
  width: 100%;
  height: 50px;
  padding: 15px;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .bottom > img {
  margin: 16px 0 100.7px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-portfolio > .bottom > button {
  cursor: pointer;
  width: 100%;
  height: 50px;
  background-color: #0062FF;
  color: white;
  font-weight: 700;
  font-size: 15px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-keyword {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: -100%;
  background-color: white;
  padding-bottom: 40px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-keyword > .top {
  width: 100%;
  height: 218px;
  background-color: #F5F6F8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 84px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-keyword > .top > .title {
  font-weight: 700;
  font-size: 26px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-keyword > .top > .sub {
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-keyword > .bottom {
  margin: 0 auto;
  width: 320px;
  padding-top: 30px;
}
#root .my-page > .profile-comp > .info-wrapper .fix-keyword > .bottom > button {
  cursor: pointer;
  width: 100%;
  height: 50px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  background-color: #0062FF;
}
#root .my-page > .profile-comp > .info-wrapper > .on {
  right: 0;
}