.alliancedetail {
  padding-top: 74px;
}
.alliancedetail > .wrapper {
  width: 727px;
}
.alliancedetail > .wrapper > .title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 14px;
}
.alliancedetail > .wrapper > .img-wrapper {
  margin: 0 auto;
  width: 112px;
  height: 112px;
  margin-bottom: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}
.alliancedetail > .wrapper > .main > .header {
  width: 728px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 24px;
}
.alliancedetail > .wrapper > .main > .header > .span {
  font-size: 18px;
  color: #0062ff;
  margin-top: 10px;
}
.alliancedetail > .wrapper > .main > .content {
  border-top: 1px solid #dbdbdb;
  padding-top: 24px;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 21px;
}
.alliancedetail > .wrapper > .main > .content > .text {
  font-weight: normal;
  white-space: pre-line;
}
.alliancedetail > .wrapper > .main > .content > .title {
  margin-bottom: 10px;
}

@media screen and (max-width: 1365px) {
  .alliancedetail > .wrapper {
    width: 728px;
    padding: unset;
  }
  .alliancedetail > .wrapper > .main > .header {
    margin-left: 20px;
  }
  .alliancedetail > .wrapper > .main > .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 767px) {
  .alliancedetail > .wrapper {
    width: 100%;
  }
}