#root .magazine-list {
  padding: 58px 0 72px;
}
#root .magazine-list > .wrapper > .title {
  font-weight: 700;
  font-size: 26px;
}
#root .magazine-list > .wrapper > .sub {
  font-weight: 500;
  font-size: 14px;
  margin: 12px 0 18px;
}
#root .magazine-list > .wrapper .magazine-list-container {
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 32px;
     -moz-column-gap: 32px;
          column-gap: 32px;
}
#root .magazine-list > .wrapper .magazine-list-container .magazine-card {
  cursor: pointer;
  width: 320px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
#root .magazine-list > .wrapper .magazine-list-container .magazine-card > .img-wrapper {
  height: 269.4px;
  width: 100%;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}
#root .magazine-list > .wrapper .magazine-list-container .magazine-card > .title {
  font-weight: 700;
  font-size: 15px;
  margin: 12px 0 5px;
  white-space: nowrap;
}
#root .magazine-list > .wrapper .magazine-list-container .magazine-card > .sub {
  font-weight: 500;
  font-size: 12px;
  color: #696969;
}
#root .magazine-list > .wrapper .magazine-list-container .magazine-card:hover {
  opacity: 0.8;
}
#root .magazine-list > .wrapper > .nav-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
#root .magazine-list > .wrapper > .nav-wrapper > .nav-btn {
  cursor: pointer;
  width: 320px;
  height: 50px;
  background-color: #F5F6F8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 12px;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
#root .magazine-list > .wrapper > .nav-wrapper > .nav-btn > .title {
  font-weight: 700;
  font-size: 15px;
  color: black;
}
#root .magazine-list > .wrapper > .nav-wrapper > .nav-btn > svg > .left {
  stroke: black;
}
#root .magazine-list > .wrapper > .nav-wrapper > .nav-btn > svg > .right {
  fill: black;
}
#root .magazine-list > .wrapper > .nav-wrapper > .nav-btn:hover {
  background-color: black;
}
#root .magazine-list > .wrapper > .nav-wrapper > .nav-btn:hover > .title {
  color: white;
}
#root .magazine-list > .wrapper > .nav-wrapper > .nav-btn:hover > svg > .left {
  stroke: white;
}
#root .magazine-list > .wrapper > .nav-wrapper > .nav-btn:hover > svg > .right {
  fill: white;
}

@media screen and (max-width: 1365px) {
  #root .magazine-list > .wrapper {
    width: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .magazine-list > .wrapper > .title, #root .magazine-list > .wrapper > .sub, #root .magazine-list > .wrapper > .nav-wrapper {
    width: 727px;
  }
  #root .magazine-list > .wrapper .magazine-list-container {
    padding: 0 50px;
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  #root .magazine-list > .wrapper .magazine-list-container::-webkit-scrollbar {
    display: none;
  }
  #root .magazine-list > .wrapper > .nav-wrapper {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (max-width: 767px) {
  #root .magazine-list > .wrapper > .title, #root .magazine-list > .wrapper > .sub, #root .magazine-list > .wrapper > .nav-wrapper {
    width: 100%;
    padding: 0 20px;
  }
  #root .magazine-list > .wrapper .magazine-list-container {
    padding: 0;
    width: 100%;
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  #root .magazine-list > .wrapper .magazine-list-container .magazine-card {
    width: 310px;
  }
  #root .magazine-list > .wrapper .magazine-list-container .magazine-card > .img-wrapper {
    height: 259px;
  }
  #root .magazine-list > .wrapper > .nav-wrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}