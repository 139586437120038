.content-write {
  -webkit-animation: inside 0.2s ease-in;
          animation: inside 0.2s ease-in;
  position: fixed;
  top: 58px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.content-write > .wrapper {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.content-write > .wrapper > .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.content-write > .wrapper > textarea {
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  height: 135px;
  width: 100%;
  margin: 13px 0 10px;
  padding: 16px 11px;
}
.content-write > .wrapper > .sub-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.content-write > .wrapper > .sub-wrapper > .sub {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}
.content-write > .wrapper > .sub-wrapper > .text-length {
  font-weight: 400;
  font-size: 11px;
  color: #949494;
}
.content-write > .wrapper > .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-column-gap: 12px;
     -moz-column-gap: 12px;
          column-gap: 12px;
}
.content-write > .wrapper > .btn-wrapper > button {
  width: 154px;
  height: 50px;
  font-weight: 700;
  font-size: 14px;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}
.content-write > .wrapper > .btn-wrapper > .cancel {
  background-color: #F5F6F8;
}
.content-write > .wrapper > .btn-wrapper > .insert {
  background-color: #0062FF;
  color: white;
}
.content-write > .wrapper > .btn-wrapper > button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 767px) {
  .content-write > .wrapper > .btn-wrapper {
    margin-top: 14px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}