#root .about-page {
  height: 605px;
}
#root .about-page > .section4 {
  padding-top: 100px;
  height: 848px;
  background-color: #e9efff;
}
#root .about-page > .section4 > .wrapper {
  width: 1107px;
}
#root .about-page > .section4 > .wrapper > .title-wrapper {
  width: 1024px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .about-page > .section4 > .wrapper > .title-wrapper > .left {
  margin-top: 50px;
}
#root .about-page > .section4 > .wrapper > .title-wrapper > .left > img {
  width: 31px;
  margin-bottom: 19px;
}
#root .about-page > .section4 > .wrapper > .title-wrapper > .left > .title {
  font-size: 40px;
  font-weight: bold;
}
#root .about-page > .section4 > .wrapper > .title-wrapper > .right > img {
  width: 275px;
  height: 244px;
}
#root .about-page > .section4 > .wrapper > .title-mb {
  display: none;
}
#root .about-page > .section4 > .wrapper > .list-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .about-page > .section4 > .wrapper > .list-wrapper > .img-left {
  cursor: pointer;
  width: 32px;
  margin-right: 10px;
}
#root .about-page > .section4 > .wrapper > .list-wrapper > .img-right {
  cursor: pointer;
  width: 32px;
}
#root .about-page > .section4 > .wrapper > .list-wrapper > .list {
  width: 1030px;
  overflow: hidden;
}
#root .about-page > .section4 > .wrapper > .list-wrapper > .list > .box-wrapper {
  display: grid;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
  grid-template-columns: repeat(9, 325px);
  -webkit-column-gap: 24px;
     -moz-column-gap: 24px;
          column-gap: 24px;
}
#root .about-page > .section4 > .wrapper > .list-wrapper > .list > .box-wrapper .magazine-card {
  cursor: pointer;
  width: 320px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
#root .about-page > .section4 > .wrapper > .list-wrapper > .list > .box-wrapper .magazine-card > .img-wrapper {
  height: 269.4px;
  width: 100%;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#root .about-page > .section4 > .wrapper > .list-wrapper > .list > .box-wrapper .magazine-card > .title {
  font-weight: 700;
  font-size: 15px;
  margin: 12px 0 5px;
}
#root .about-page > .section4 > .wrapper > .list-wrapper > .list > .box-wrapper .magazine-card > .sub {
  font-weight: 500;
  font-size: 12px;
  color: #696969;
}
#root .about-page > .section4 > .wrapper > .list-wrapper > .list > .box-wrapper .magazine-card:hover {
  opacity: 0.8;
}
#root .about-page > .section5 {
  height: 739px;
  background-color: white;
}
#root .about-page > .section5 > .wrapper {
  width: 1080px;
}
#root .about-page > .section5 > .wrapper > .title-wrapper {
  width: 1024px;
  margin: 0 auto;
}
#root .about-page > .section5 > .wrapper > .title-wrapper > .title {
  padding-top: 123px;
  font-size: 40px;
  font-weight: bold;
}
#root .about-page > .section5 > .wrapper > .list-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .about-page > .section5 > .wrapper > .list-wrapper > .img-left {
  cursor: pointer;
  width: 32px;
  margin-right: 10px;
}
#root .about-page > .section5 > .wrapper > .list-wrapper > .img-right {
  cursor: pointer;
  width: 32px;
  margin-left: 10px;
}
#root .about-page > .section5 > .wrapper > .list-wrapper > .list {
  margin-top: 41px;
  width: 1030px;
  overflow: hidden;
}
#root .about-page > .section5 > .wrapper > .list-wrapper > .list > .box-wrapper {
  display: grid;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
  grid-template-columns: repeat(9, 328px);
  -webkit-column-gap: 21px;
     -moz-column-gap: 21px;
          column-gap: 21px;
}
#root .about-page > .section5 > .wrapper > .list-wrapper > .list > .box-wrapper > .box {
  cursor: pointer;
  width: 327px;
  height: 393px;
}
#root .about-page > .section5 > .wrapper > .list-wrapper > .list > .box-wrapper > .box > .img {
  width: 100%;
  height: 185px;
  background-color: #949494;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#root .about-page > .section5 > .wrapper > .list-wrapper > .list > .box-wrapper > .box > .bottom-box {
  width: 327px;
  height: 192px;
  background-color: #f5f6f8;
  padding: 27px 33px 20px 27px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .about-page > .section5 > .wrapper > .list-wrapper > .list > .box-wrapper > .box > .bottom-box > .title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
#root .about-page > .section5 > .wrapper > .list-wrapper > .list > .box-wrapper > .box > .bottom-box > .sub {
  font-size: 14px;
  font-weight: 500;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1365px) {
  #root .about-page {
    height: 457px;
  }
  #root .about-page > .section4 {
    height: 648px;
  }
  #root .about-page > .section4 > .wrapper {
    max-width: 888px;
    width: 95vw;
  }
  #root .about-page > .section4 > .wrapper > .title-wrapper {
    width: 100%;
  }
  #root .about-page > .section4 > .wrapper > .title-wrapper > .left {
    margin-top: 0;
  }
  #root .about-page > .section4 > .wrapper > .title-wrapper > .left > img {
    width: 31px;
    margin-bottom: 7px;
  }
  #root .about-page > .section4 > .wrapper > .title-wrapper > .left > .title {
    font-size: 30px;
  }
  #root .about-page > .section4 > .wrapper > .title-wrapper > .right > img {
    width: 140px;
    height: 124px;
  }
  #root .about-page > .section4 > .wrapper > .title-mb {
    display: none;
  }
  #root .about-page > .section4 > .wrapper > .list-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 888px;
    width: 95vw;
  }
  #root .about-page > .section4 > .wrapper > .list-wrapper > .img-left {
    display: none;
  }
  #root .about-page > .section4 > .wrapper > .list-wrapper > .img-right {
    display: none;
  }
  #root .about-page > .section4 > .wrapper > .list-wrapper > .list {
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  #root .about-page > .section4 > .wrapper > .list-wrapper > .list > .box-wrapper {
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
    -webkit-transform: unset !important;
            transform: unset !important;
  }
  #root .about-page > .section4 > .wrapper > .list-wrapper > .list::-webkit-scrollbar {
    display: none;
  }
  #root .about-page > .section4 > .wrapper > .scroll-mb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    margin-top: 43px;
    width: 100%;
    height: 3px;
    background-color: #949494;
  }
  #root .about-page > .section4 > .wrapper > .scroll-mb > .scroll-mb-blue {
    width: 220px;
    height: 3px;
    background-color: #0062ff;
  }
  #root .about-page > .section5 {
    height: 659px;
  }
  #root .about-page > .section5 > .wrapper {
    width: 95vw;
    max-width: 888px;
  }
  #root .about-page > .section5 > .wrapper > .title-wrapper {
    width: 100%;
  }
  #root .about-page > .section5 > .wrapper > .title-wrapper > .title {
    padding-top: 83px;
    font-size: 30px;
    font-weight: bold;
  }
  #root .about-page > .section5 > .wrapper > .scroll-mb {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    margin-top: 43px;
    height: 3px;
    width: 100%;
    background-color: #949494;
  }
  #root .about-page > .section5 > .wrapper > .scroll-mb > .scroll-mb-blue {
    width: 286px;
    height: 3px;
    background-color: #0062ff;
  }
  #root .about-page > .section5 > .wrapper > .list-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 888px;
    width: 95vw;
  }
  #root .about-page > .section5 > .wrapper > .list-wrapper > .img-left {
    display: none;
  }
  #root .about-page > .section5 > .wrapper > .list-wrapper > .img-right {
    display: none;
  }
  #root .about-page > .section5 > .wrapper > .list-wrapper > .list {
    overflow-x: scroll;
    margin-top: 26px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  #root .about-page > .section5 > .wrapper > .list-wrapper > .list > .box-wrapper {
    grid-template-columns: repeat(9, 286px);
    -webkit-column-gap: 15px;
       -moz-column-gap: 15px;
            column-gap: 15px;
  }
  #root .about-page > .section5 > .wrapper > .list-wrapper > .list > .box-wrapper > .box {
    cursor: pointer;
    width: 286px;
    height: 368px;
  }
  #root .about-page > .section5 > .wrapper > .list-wrapper > .list > .box-wrapper > .box > .bottom-box {
    width: 286px;
    height: 183px;
    background-color: #f5f6f8;
    padding: 19px 26px 21px 19px;
  }
  #root .about-page > .section5 > .wrapper > .list-wrapper > .list::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  #root .about-page {
    height: 1075px;
  }
  #root .about-page > .section4 {
    height: 715px;
  }
  #root .about-page > .section4 > .wrapper {
    width: 100%;
  }
  #root .about-page > .section4 > .wrapper > .title-wrapper > .left > .title {
    font-size: 25px;
  }
  #root .about-page > .section4 > .wrapper > .list-wrapper {
    margin-top: 21px;
    width: 100%;
  }
  #root .about-page > .section4 > .wrapper > .list-wrapper > .box-wrapper .magazine-card {
    width: 284px;
  }
  #root .about-page > .section4 > .wrapper > .list-wrapper > .box-wrapper .magazine-card > .img-wrapper {
    height: 239px;
  }
  #root .about-page > .section4 > .wrapper > .scroll-mb {
    width: 100%;
  }
  #root .about-page > .section4 > .wrapper > .scroll-mb > .scroll-mb-blue {
    width: 25%;
  }
  #root .about-page > .section4 > .wrapper > .title-mb {
    display: block;
    font-weight: 700;
    font-size: 25px;
    line-height: 35px;
  }
  #root .about-page > .section5 {
    height: 640px;
  }
  #root .about-page > .section5 > .wrapper {
    width: 100%;
  }
  #root .about-page > .section5 > .wrapper > .title-wrapper {
    width: 100%;
    margin: 0 auto;
  }
  #root .about-page > .section5 > .wrapper > .title-wrapper > .title {
    padding-top: 62px;
    font-size: 25px;
  }
  #root .about-page > .section5 > .wrapper > .list-wrapper {
    width: unset;
  }
  #root .about-page > .section5 > .wrapper > .scroll-mb {
    width: 100%;
  }
  #root .about-page > .section5 > .wrapper > .scroll-mb > .scroll-mb-blue {
    width: 20%;
  }
}