@-webkit-keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes inside {
  from {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes opa {
  from {
    opacity: 0;
    bottom: -20px;
  }
  to {
    opacity: 1;
    right: 20px;
    bottom: 20px;
  }
}
@keyframes opa {
  from {
    opacity: 0;
    bottom: -20px;
  }
  to {
    opacity: 1;
    right: 20px;
    bottom: 20px;
  }
}
@-webkit-keyframes right-side {
  from {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes right-side {
  from {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes left-side {
  from {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes left-side {
  from {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes right-out {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
}
@keyframes right-out {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
    opacity: 0;
  }
}
@-webkit-keyframes left-out {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
}
@keyframes left-out {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    opacity: 0;
  }
}
.inside-ani {
  -webkit-animation: inside 0.3s ease-in-out;
          animation: inside 0.3s ease-in-out;
}

main {
  -webkit-animation: inside 0.3s ease-in;
          animation: inside 0.3s ease-in;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
}

a {
  color: black;
}

#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: white;
}
#root .mb-scroll {
  display: none;
}
#root font {
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
}
#root font[size="1"] {
  font-size: 13px;
}
#root font[size="2"] {
  font-size: 15px;
}
#root font[size="3"] {
  font-size: 17px;
}
#root font[size="4"] {
  font-size: 19px;
}
#root font[size="5"] {
  font-size: 21px;
}
#root font[size="6"] {
  font-size: 23px;
}
#root font[size="7"] {
  font-size: 25px;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  border: none;
  scrollbar-width: none;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "pretendard", sans-serif !important;
}

html,
body {
  background-color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "pretendard", sans-serif;
}

span {
  text-indent: unset !important;
}

body::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  color: black;
}

img {
  width: 100%;
}

input[type=number] {
  -moz-appearance: textfield;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.none {
  display: none !important;
}

input,
p,
textarea {
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: none;
  resize: none;
  font-family: "pretendard", sans-serif;
}
input::-webkit-input-placeholder, p::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::-moz-placeholder, p::-moz-placeholder, textarea::-moz-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input:-ms-input-placeholder, p:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::-ms-input-placeholder, p::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input::placeholder,
p::placeholder,
textarea::placeholder {
  font-family: "pretendard", sans-serif;
  color: #c6c6c6;
}
input:focus,
p:focus,
textarea:focus {
  outline: none;
}

.wrapper {
  width: 320px;
  margin: 0 auto;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 20px;
  }
  #root {
    position: relative;
  }
  #root .mb-scroll {
    display: block;
    position: absolute;
    left: 0;
    width: 1px;
    height: calc(100% + 1px);
  }
}