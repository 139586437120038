#root .templ-emty-list {
  margin: 30px 0 60px;
  -webkit-animation: inside 0.2s ease-in-out;
          animation: inside 0.2s ease-in-out;
  background-color: #F5F6F8;
  width: 100%;
  height: 300px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 30px;
}
#root .templ-emty-list > img {
  width: 200px;
}
#root .templ-emty-list > .emty-title {
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  white-space: pre-line;
  text-align: center;
}