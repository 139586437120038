#root .loading-section {
  position: fixed;
  z-index: 6000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .loading-section .back-black {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}