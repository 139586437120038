#root .main-section > div > .wrapper {
  width: 1024px;
}
#root .main-section > .main-list > .wrapper {
  -webkit-box-sizing: unset;
          box-sizing: unset;
  padding: 80px 0 63px;
}
#root .main-section > .main-list > .wrapper > .title-wrapper > img {
  width: 21px;
}
#root .main-section > .main-list > .wrapper > .title-wrapper > .title {
  margin-top: 14px;
  font-weight: 700;
  font-size: 26px;
}
#root .main-section > .main-list > .wrapper > .list-wrapper {
  margin: 30px 0 60px 0;
  display: grid;
  grid-template-columns: repeat(4, 236px);
  -webkit-column-gap: 26px;
     -moz-column-gap: 26px;
          column-gap: 26px;
}
#root .main-section > .main-list > .wrapper > .list-wrapper > .card {
  cursor: pointer;
  width: 236px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
#root .main-section > .main-list > .wrapper > .list-wrapper > .card > .card-img {
  width: 100%;
  height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  display: block;
  position: relative;
  overflow: hidden;
}
#root .main-section > .main-list > .wrapper > .list-wrapper > .card > .card-img .finish-blur {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 6px;
}
#root .main-section > .main-list > .wrapper > .list-wrapper > .card > .card-img .finish-blur > img {
  width: 21px;
}
#root .main-section > .main-list > .wrapper > .list-wrapper > .card > .card-img .finish-blur > .blur-title {
  font-size: 14px;
  font-weight: bold;
  color: white;
}
#root .main-section > .main-list > .wrapper > .list-wrapper > .card > .title {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;
  margin-top: 14px;
  height: 40px;
}
#root .main-section > .main-list > .wrapper > .list-wrapper > .card > .line {
  background-color: #DBDBDB;
  width: 100%;
  height: 1px;
  margin: 10px 0;
}
#root .main-section > .main-list > .wrapper > .list-wrapper > .card > .period {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  font-weight: 500;
  font-size: 15px;
  color: #696969;
}
#root .main-section > .main-list > .wrapper > .list-wrapper > .card > .period > span {
  font-weight: 700;
  color: #0062FF;
}
#root .main-section > .main-list > .wrapper > .list-wrapper > .card:hover {
  opacity: 0.7;
}
#root .main-section > .main-list > .wrapper > .nav-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
#root .main-section > .main-list > .wrapper > .nav-wrapper > .nav-type-btn {
  width: 320px;
  height: 50px;
  padding: 0 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
  background-color: #F5F6F8;
  -webkit-transition: background-color 0.1s ease-in;
  transition: background-color 0.1s ease-in;
}
#root .main-section > .main-list > .wrapper > .nav-wrapper > .nav-type-btn > svg {
  width: 27px;
}
#root .main-section > .main-list > .wrapper > .nav-wrapper > .nav-type-btn > .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}
#root .main-section > .main-list > .wrapper > .nav-wrapper > .nav-type-btn > .left > img {
  width: 21px;
}
#root .main-section > .main-list > .wrapper > .nav-wrapper > .nav-type-btn > .left > .title {
  font-weight: 700;
  font-size: 15px;
  color: black;
}
#root .main-section > .main-list > .wrapper > .nav-wrapper > .nav-type-btn:hover {
  background-color: #e7e7e7;
}
#root .main-section > .line {
  width: 100%;
  height: 9px;
  background-color: #F5F6F8;
}
#root .main-section > .main-notice {
  height: 807px;
}
#root .main-section > .main-notice > .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .main-section > .main-notice > .wrapper > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 6px;
     -moz-column-gap: 6px;
          column-gap: 6px;
}
#root .main-section > .main-notice > .wrapper > .title-wrapper > img {
  width: 21px;
}
#root .main-section > .main-notice > .wrapper > .title-wrapper > .title {
  font-weight: 700;
  font-size: 26px;
}
#root .main-section > .main-notice > .wrapper > .notice-list {
  height: 440px;
  margin-top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .main-section > .main-notice > .wrapper > .notice-list > .notice-nav-link {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 20px;
  background-color: white;
  height: 83px;
  padding-top: 15px;
  border-top: solid 1px #DBDBDB;
  row-gap: 15px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
#root .main-section > .main-notice > .wrapper > .notice-list > .notice-nav-link > .title {
  width: 100%;
  text-align: left;
  font-weight: 700;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}
#root .main-section > .main-notice > .wrapper > .notice-list > .notice-nav-link > .bottom {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  font-size: 13px;
  color: #949494;
}
#root .main-section > .main-notice > .wrapper > .notice-list > .notice-nav-link > .bottom > .user {
  font-weight: 400;
}
#root .main-section > .main-notice > .wrapper > .notice-list > .notice-nav-link:hover {
  opacity: 0.6;
}
#root .main-section > .main-notice > .wrapper > .notice-list-nav-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
#root .main-section > .main-notice > .wrapper > .notice-list-nav-wrapper > .notice-list-nav {
  -ms-flex-item-align: center;
      align-self: center;
  margin-top: 51px;
  cursor: pointer;
  width: 320px;
  height: 50px;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 13px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
#root .main-section > .main-notice > .wrapper > .notice-list-nav-wrapper > .notice-list-nav > svg {
  width: 27px;
}
#root .main-section > .main-notice > .wrapper > .notice-list-nav-wrapper > .notice-list-nav:hover {
  background-color: #0062FF;
}

@media screen and (max-width: 1365px) {
  #root .main-section > div > .wrapper {
    width: 727px;
  }
  #root .main-section > .main-list > .wrapper {
    padding: 74px 0 113px;
  }
  #root .main-section > .main-list > .wrapper > .list-wrapper {
    margin: 30px 0 40px;
    grid-template-columns: repeat(2, 345px);
    gap: 70px 38px;
  }
  #root .main-section > .main-list > .wrapper > .list-wrapper > .card {
    width: 100%;
  }
  #root .main-section > .main-list > .wrapper > .list-wrapper > .card > .card-img {
    height: 280px;
  }
}
@media screen and (max-width: 767px) {
  #root .main-section > div > .wrapper {
    width: 100%;
    padding: 0 20px;
  }
  #root .main-section > .main-list > .wrapper {
    padding: 91px 20px 113px 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .main-section > .main-list > .wrapper > .list-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 10vw 4vw;
  }
  #root .main-section > .main-list > .wrapper > .list-wrapper > .card > .card-img {
    height: 36.4vw;
  }
  #root .main-section > .main-list > .wrapper > .list-wrapper > .card > .title {
    margin-top: 10px;
    font-size: 13px;
  }
  #root .main-section > .main-list > .wrapper > .nav-wrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .main-section > .main-list > .wrapper > .nav-wrapper > .nav-type-btn {
    width: 100%;
  }
  #root .main-section .main-notice > .wrapper > .notice-list-nav-wrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .main-section .main-notice > .wrapper > .notice-list-nav-wrapper > .notice-list-nav {
    width: 100%;
    min-width: 280px;
  }
}