#root .my-scrap-page {
  padding: 80px 0;
}
#root .my-scrap-page > .wrapper {
  width: 1024px;
}
#root .my-scrap-page > .wrapper > .title {
  font-weight: 700;
  font-size: 25px;
}
#root .my-scrap-page > .nav-wrapper {
  margin-top: 22px;
  width: 100%;
  border-bottom: solid 1px #D9D9D9;
}
#root .my-scrap-page > .nav-wrapper > .button-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 63px);
  -webkit-column-gap: 51px;
     -moz-column-gap: 51px;
          column-gap: 51px;
  position: relative;
}
#root .my-scrap-page > .nav-wrapper > .button-wrapper > button {
  padding-bottom: 9px;
  background-color: white;
  color: black;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
#root .my-scrap-page > .nav-wrapper > .button-wrapper > .active-btn {
  font-weight: 700;
  color: #0062FF;
}
#root .my-scrap-page > .nav-wrapper > .button-wrapper > .nav-line {
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  width: 63px;
  height: 3px;
  background-color: #0062FF;
  position: absolute;
  left: 0;
  bottom: -2px;
}
#root .my-scrap-page .scrap-section {
  margin-top: 27px;
  display: grid;
  grid-template-columns: repeat(3, 320px);
  gap: 73px 32px;
}

@media screen and (max-width: 1365px) {
  #root .my-scrap-page > .wrapper {
    width: 727px;
  }
  #root .my-scrap-page .scrap-section {
    grid-template-columns: repeat(2, 348px);
  }
}
@media screen and (max-width: 767px) {
  #root .my-scrap-page > .wrapper {
    width: 100%;
    padding: 0 20px;
  }
  #root .my-scrap-page .scrap-section {
    grid-template-columns: 100%;
    gap: 59px 0;
  }
}