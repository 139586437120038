.team-leader-section {
  width: 100%;
  height: 580px;
  background-color: #B8FF6C;
}
.team-leader-section > .wrapper {
  width: 1024px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.team-leader-section > .wrapper > .title {
  font-weight: 700;
  font-size: 35px;
}
.team-leader-section > .wrapper > .sub {
  font-weight: 500;
  font-size: 16px;
  margin: 21px 0 39px;
}
.team-leader-section > .wrapper > .card {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  width: 100%;
  height: 287px;
  border-radius: 10px;
  -webkit-column-gap: 36px;
     -moz-column-gap: 36px;
          column-gap: 36px;
  overflow: hidden;
}
.team-leader-section > .wrapper > .card > .img-wrapper {
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 341px;
  height: 100%;
}
.team-leader-section > .wrapper > .card > .content-wrapper {
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
  text-align: left;
}
.team-leader-section > .wrapper > .card > .content-wrapper > .title {
  font-weight: 700;
  font-size: 26px;
  white-space: pre-wrap;
  line-height: 35px;
}
.team-leader-section > .wrapper > .card > .content-wrapper > .grade {
  margin-top: 10px;
  font-weight: 700;
  font-size: 16px;
  color: #949494;
}
.team-leader-section > .wrapper > .card:hover > .img-wrapper {
  opacity: 0.8;
}
.team-leader-section > .wrapper > .card:hover > .content-wrapper {
  opacity: 0.7;
}
.team-leader-section > .wrapper > .nav-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.team-leader-section > .wrapper > .nav-wrapper > .magazine-list-nav-btn {
  margin-top: 51px;
  cursor: pointer;
  width: 320px;
  height: 50px;
  background-color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 13px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  -webkit-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}
.team-leader-section > .wrapper > .nav-wrapper > .magazine-list-nav-btn > svg {
  width: 27px;
}
.team-leader-section > .wrapper > .nav-wrapper > .magazine-list-nav-btn:hover {
  color: black;
  background-color: white;
}
.team-leader-section > .wrapper > .nav-wrapper > .magazine-list-nav-btn:hover > svg > path {
  stroke: black;
}
.team-leader-section > .wrapper > .nav-wrapper > .magazine-list-nav-btn:hover > svg > .right {
  fill: black;
}

.MGN_02-intro {
  background-color: #8741EA;
}
.MGN_02-intro > .wrapper > .title, .MGN_02-intro > .wrapper > .sub {
  color: white;
}

.MGN-intro {
  background-color: #0062FF;
}
.MGN-intro > .wrapper > .title, .MGN-intro > .wrapper > .sub {
  color: white;
}

@media screen and (max-width: 1365px) {
  .team-leader-section {
    height: 860px;
  }
  .team-leader-section > .wrapper {
    width: 620px;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    padding-top: 85px;
  }
  .team-leader-section > .wrapper > .title {
    font-size: 26px;
  }
  .team-leader-section > .wrapper > .sub {
    font-size: 12px;
    margin: 9px 0 16px;
  }
  .team-leader-section > .wrapper > .card {
    height: 595px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
  }
  .team-leader-section > .wrapper > .card > .img-wrapper {
    width: 100%;
    height: 470px;
  }
  .team-leader-section > .wrapper > .card > .content-wrapper {
    padding: 20px;
  }
  .team-leader-section > .wrapper > .card > .content-wrapper > .title {
    font-size: 20px;
    line-height: 26px;
  }
  .team-leader-section > .wrapper > .card > .content-wrapper > .grade {
    font-size: 13px;
  }
  .team-leader-section > .wrapper > .nav-wrapper {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .team-leader-section > .wrapper > .nav-wrapper > .magazine-list-nav-btn {
    margin-top: 26px;
  }
}
@media screen and (max-width: 767px) {
  .team-leader-section {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 597px;
  }
  .team-leader-section > .wrapper {
    width: 100%;
    padding: 85px 20px !important;
  }
  .team-leader-section > .wrapper > .card {
    min-height: 332px;
    height: 89.6vw;
    max-height: 700px;
  }
  .team-leader-section > .wrapper > .card > .img-wrapper {
    width: 100%;
    height: 88vw;
  }
  .team-leader-section > .wrapper > .nav-wrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .team-leader-section > .wrapper > .nav-wrapper > .magazine-list-nav-btn {
    width: 100%;
    min-width: 280px;
  }
}