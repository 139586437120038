.mycard {
  padding-top: 74px;
}
.mycard > .wrapper > .title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 30px;
}
.mycard > .wrapper > .card-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 8px;
}
.mycard > .wrapper > .card-title > .card-text {
  font-size: 13px;
  font-weight: bold;
}
.mycard > .wrapper > .card-title > .card-text2 {
  font-size: 13px;
  color: #0062ff;
}
.mycard > .wrapper > .card-box {
  width: 320px;
  height: 294px;
  text-align: center;
  background-color: #f5f6f8;
  border-radius: 10px;
}
.mycard > .wrapper > .card-box > .img {
  display: inline-block;
  width: 120px;
  height: 91px;
  margin-top: 27px;
  margin-bottom: 16px;
}
.mycard > .wrapper > .card-box > .text {
  font-size: 18px;
  font-weight: bold;
  color: #949494;
  margin-bottom: 9px;
}
.mycard > .wrapper > .card-box > .sub {
  font-size: 13px;
  color: #696969;
  line-height: 19px;
  margin-bottom: 17px;
}
.mycard > .wrapper > .card-box > .button {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #0062ff;
  width: 108px;
  height: 33px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}
.mycard > .wrapper > .line {
  border-bottom: 1px solid #dbdbdb;
  margin-top: 35px;
  width: 100%;
}
.mycard > .wrapper > .notice {
  padding-top: 20px;
  font-size: 12px;
  line-height: 23px;
}
.mycard > .wrapper > .notice > .title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}
.mycard > .wrapper > .notice > .notice-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mycard > .wrapper > .notice > .notice-box > .blue {
  width: 7px;
  height: 7px;
  background-color: #0062ff;
  border-radius: 50px;
  margin-right: 8px;
  margin-top: 7px;
}
.mycard > .wrapper > .notice > .notice-box > .blue2 {
  width: 7px;
  height: 7px;
  background-color: #0062ff;
  border-radius: 50px;
  margin-right: 8px;
  margin-top: 9px;
}
.mycard > .wrapper > .notice > .notice-box > .text {
  line-height: 23px;
  color: #696969;
}
.mycard > .wrapper > .notice > .notice-box > .text > span {
  font-weight: bold;
}

@media screen and (max-width: 1365px) {
  #root .mycard > .wrapper {
    width: 320px;
  }
}
@media screen and (max-width: 767px) {
  #root .mycard {
    padding-bottom: 50px;
  }
  #root .mycard > .wrapper {
    width: 100%;
  }
  #root .mycard > .wrapper > .card-box {
    width: 100%;
  }
  #root .mycard > .wrapper > .selet-card {
    width: 100%;
  }
  #root .mycard > .wrapper > .selet-card > .box {
    width: 100%;
  }
  #root .mycard > .wrapper > .selet-card > .open {
    width: 100%;
  }
  #root .mycard > .wrapper > .selet-card > .open > .card-wrapper > .card-flex {
    width: 100%;
  }
  #root .mycard > .wrapper > .selet-card > .open > .card-wrapper > .card-flex > .delete-open {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}